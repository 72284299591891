import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Tabs } from 'antd'
import UserActions from '../Redux/UserRedux'
import FeesActions from '../Redux/FeesRedux'
import ProfileInfo from '../Components/ProfileInfo'
import FeeTemplates from '../Components/FeeTemplates'
import i18n from '../i18n'

import './ProfilePage.css'

const { TabPane } = Tabs;

function DealerPage(props) {
  const {user, accounts, fees, isAdmin, role, match, isDesktop} = props;

  const {id} = match.params;

  useEffect(() => {
    props.userSetData(null);
    props.getUser(id);
    props.getAccounts(id);
    if (isAdmin) {
      props.getFees(id);
    }

    return function cleanup() {
      if (id) {
        props.userSetData(null);
      }
    }
  }, [0]);

  return (
    <div className="content-page profile">
      {
        isAdmin ?
          <div className="profile-content">
            <Tabs type="card">
              <TabPane tab={i18n.t("general_information")} key="general_information">
                <ProfileInfo
                  user={user}
                  accounts={accounts}
                  isAdmin={isAdmin}
                  role={role}
                  withExtra={true}
                  isDesktop={isDesktop}
                />
              </TabPane>
              <TabPane tab={i18n.t("fees_templates")} key="fees_templates">
                <FeeTemplates
                  data={fees}
                  isAdmin={isAdmin}
                />
              </TabPane>
            </Tabs>
          </div>
        :
          <ProfileInfo
            user={user}
            accounts={accounts}
            isAdmin={isAdmin}
            role={role}
            forDealer={true}
            isDesktop={isDesktop}
          />
      }
    </div>

  )
}

const mapStateToProps = (state) => {
  return {
    user: state.users.userData,
    accounts: state.users.accounts,
    fees: state.fees.list,
    isAdmin: state.auth.isAdmin,
    role: state.auth.role,
    isDesktop: state.nav.isDesktop
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUser: (userId) => dispatch(UserActions.userRequest(userId)),
  userSetData: (data) => dispatch(UserActions.userSetData(data)),
  getAccounts: (userId) => dispatch(UserActions.accountsRequest(userId)),
  getFees: (id) => dispatch(FeesActions.feesRequest(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DealerPage)
