import React, { useState, useEffect } from 'react'
import Gallery from 'react-grid-gallery'

function CardGallery(props) {

  const {images, isShow} = props;

  const [data, setData] = useState([]);

  useEffect(() => {
    if (images && images.length) {
      const data = images.map(image => ({
        src: image.url,
        thumbnail: image.url,
        thumbnailWidth: 320,
        thumbnailHeight: 174,
      }))
      setData(data);
    }
  }, [isShow]);

  return (
    <div style={{
      display: "block",
      maxHeight: "0",
      width: "100%",
      overflow: "hidden"}}>
      <Gallery
        images={data}
        showLightboxThumbnails={true}/>
      </div>
  )
}

export default CardGallery
