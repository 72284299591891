import { call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import TitlesActions from '../Redux/TitlesRedux'
import AuthActions from '../Redux/AuthRedux'
import ErrorsActions from '../Redux/ErrorsRedux'

export function * getTitles(api, authApi, {page, page_size, sort_field, sort_order, search}) {
  try {
    const response = yield call(api.getTitles, page, page_size, sort_field, sort_order, search);
    if (response && response.ok) {
      yield put(TitlesActions.titlesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(TitlesActions.titleFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getTitles(api, authApi, {page, page_size, sort_field, sort_order, search});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(TitlesActions.titleFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getTitleColors(api, authApi, {}) {
  try {
    const response = yield call(api.getTitleColors);
    if (response && response.ok) {
      yield put(TitlesActions.titleColorsSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(TitlesActions.titleFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getTitleColors(api, authApi, {});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(TitlesActions.titleFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getTitleStates(api, authApi, {}) {
  try {
    const response = yield call(api.getTitleStates);
    if (response && response.ok) {
      yield put(TitlesActions.titleStatesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(TitlesActions.titleFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getTitleStates(api, authApi, {});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(TitlesActions.titleFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * editTitleComment(api, authApi, {id, data}) {
  try {
    const response = yield call(api.editTitleComment, id, data);
    if (response && response.ok) {
      yield put(TitlesActions.titleCommentChangeSuccess(response.data))
      yield put(TitlesActions.titleCommentChangeReset())
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(TitlesActions.titleFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * editTitleComment(api, authApi, {id, data});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(TitlesActions.titleFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}
