import React from 'react'
import { Col, Row, Button, Form, Input } from 'antd'
import Images from '../../Images'
import i18n from '../../i18n'

function ChangePasswordPopupForm(props) {

  const { form, match } = props;

  const {getFieldDecorator} = form;

  const {id} = match.params;

  function handleSubmit(e) {

    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        const data = { ...values }
        delete data.confirm;
        props.onSubmit(data);
      }
    });

  }

  function compareToFirstPassword (rule, value, callback) {
    if (value && value !== form.getFieldValue('new_password')) {
      callback(i18n.t("passwords_dont_match"));
    } else {
      callback();
    }
  };

  return (
    <div className="dialog-container settings-popup-dialog invoice-popup-dialog" >
      <div className="dialog-close-container">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={props.onClose}>
          <path d="M1.66663 1.66667L11 11M11 11L20.3333 20.3333M11 11L20.3333 1.66667M11 11L1.66663 20.3333" stroke="#262F56" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
      <div className="popup-title">{i18n.t("change_password")}</div>
      <div className="popup-content">

        {<Form id="change-password-popup-form" onSubmit={handleSubmit} disabled>

          <Row gutter={16}>
            {
              !id &&
                <Col className="gutter-row" xl={8} md={12} xs={24}>
                  <Form.Item label={i18n.t("current_password")}>
                    {getFieldDecorator('old_password', {
                      rules: [{ required: true, message: i18n.t("required_field") }],
                    })(
                      <Input.Password
                        size="large"
                        type="password"
                      />,
                    )}
                  </Form.Item>
                </Col>
            }
            <Col className="gutter-row" xl={{span: 8, offset: id && 4}} md={12} xs={24}>
              <Form.Item label={i18n.t("new_password")}>
                {getFieldDecorator('new_password', {
                  rules: [{ required: true, message: i18n.t("required_field") }],
                })(
                  <Input.Password
                    size="large"
                    type="password"
                  />,
                )}
              </Form.Item>
            </Col>
            <Col className="gutter-row" xl={8} md={12} xs={24}>
              <Form.Item label={i18n.t("new_password_omt")} dependencies={['new_password']}>
                {getFieldDecorator('confirm', {
                  rules: [
                    {
                      required: true,
                      message: i18n.t("required_field"),
                    },
                    {
                      validator: compareToFirstPassword,
                    },
                  ],
                })(
                  <Input.Password
                    size="large"
                    type="password"
                  />,
                )}
              </Form.Item>
            </Col>
          </Row>

        </Form>}

      </div>
      <div className="dialog-footer">
        <Button className="btn-primary gray" type="primary" onClick={props.onClose}>
          <img className="btn-icon icon-space" src={Images.cancel} alt="" />
          {i18n.t("cancel")}
        </Button>
        <div style={{ width: '16px' }} />
        <Button form="user-popup-form" htmlType="submit" onClick={handleSubmit} className="btn-primary" type="primary" >
          <img className="btn-icon icon-space" src={Images.check} alt="" />
          {i18n.t("save")}
        </Button>
      </div>
    </div>
  )
}

const WrappedUserEditForm = Form.create({ name: 'change-password-popup-form' })(ChangePasswordPopupForm);

export default WrappedUserEditForm
