import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  paymentsRequest: ['page', 'page_size', 'sort_field', 'sort_order', 'search', 'filter'],
  paymentsSuccess: ['list'],

  paymentSendFileRequest: ['data'],
  paymentSendFileSuccess: ['uploadFile'],
  paymentSendFileReset: null,

  paymentsReset: null,
  paymentFilterReset: null,
  paymentSearchReset: null,

  paymentFetchingReset: null
})

export const PaymentsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: null,
  fetching: false,

  uploadFile: null,
  uploadFileFetching: false,

  page: 1,
  sort_field: '',
  sort_order: 1,
  search: '',
  filter: {
    vin: '',
    date: [],
    payment_amount_min: '',
    payment_amount_max: ''
  },

})

/* ------------- Selectors ------------- */
export const PaymentsSelectors = {
  getState: (state) => state.payments
}

/* ------------- Reducers ------------- */

export const paymentsRequest = (state: Object, {page, page_size, sort_field, sort_order, search, filter}: Object) => {
  const newState = {
    fetching: true,
  };
  page && (newState.page = page);
  page_size && (newState.page_size = page_size);
  sort_field && (newState.sort_field = sort_field);
  sort_order && (newState.sort_order = sort_order);
  search != null && (newState.search = search);
  filter && (newState.filter = filter);
  return state.merge(newState)
}

export const paymentsSuccess = (state: Object, { list }: Object) => {
  return state.merge({ fetching: false, list })
}

export const paymentSendFileRequest = (state: Object, { data }: Object) => {
  return state.merge({ uploadFileFetching: true })
}

export const paymentSendFileSuccess = (state: Object, { uploadFile }: Object) => {
  return state.merge({ uploadFileFetching: false, uploadFile })
}

export const paymentSendFileReset = (state: Object) =>{
  return state.merge({ uploadFileFetching: false, uploadFile:null })
}

export const paymentsReset = (state: Object) =>{
  return INITIAL_STATE
}

export const paymentFilterReset = (state: Object) => {
  const newState = {
    filter: {
      vin: '',
      date: [],
      payment_amount_min: '',
      payment_amount_max: ''
    }
  };
  return state.merge(newState)
}

export const paymentSearchReset = (state: Object) => {
  return state.merge({ search: '' })
}

export const paymentFetchingReset = (state: Object) => {
  return state.merge({ fetching:false, uploadFileFetching:false })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.PAYMENTS_REQUEST]: paymentsRequest,
  [Types.PAYMENTS_SUCCESS]: paymentsSuccess,

  [Types.PAYMENT_SEND_FILE_REQUEST]: paymentSendFileRequest,
  [Types.PAYMENT_SEND_FILE_SUCCESS]: paymentSendFileSuccess,
  [Types.PAYMENT_SEND_FILE_RESET]: paymentSendFileReset,

  [Types.PAYMENTS_RESET]: paymentsReset,
  [Types.PAYMENT_FILTER_RESET]: paymentFilterReset,
  [Types.PAYMENT_SEARCH_RESET]: paymentSearchReset,

  [Types.PAYMENT_FETCHING_RESET]: paymentFetchingReset,
})
