import React from 'react'
import { connect } from 'react-redux'
import { Layout, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import NavActions from '../../Redux/NavRedux'
import i18n from '../../i18n'
import Images from '../../Images'

const { Header, Footer } = Layout;

function ProfileHeader(props) {
  const {role, tab, isAdmin, nav} = props;

  let history = useHistory();

  function handleNavigate(path) {
    history.push(history.location.pathname + path);
  }

  function logoNavigate(path) {
    if (!isAdmin) {
      history.push(path);
    }
  }

  function handleOpenMobileMenu(e) {
    props.navSetProp('isShowMobileMenu', true)
  }

  return (
    <Header>
      {
        nav.isDesktop ?
          <>
            <div className="header-title-container">{i18n.t("my_profile")}</div>
            <div className="header-buttons-container">
              {
                tab === 'general_information' &&
                <>
                  <Button className="btn-primary" type="primary" style={{ width: 'auto' }} onClick={() => handleNavigate('/change-password')}>
                    <img className="btn-icon icon-space" src={Images.lock} alt="" />
                    {i18n.t("change_password")}
                  </Button>
                  <div style={{ width: '8px' }} />
                  <Button className="btn-primary" type="primary" style={{ width: 'auto' }} onClick={() => handleNavigate('/edit')}>
                    <img className="btn-icon icon-space" src={Images.editWhite} alt="" />
                    {i18n.t("edit")}
                  </Button>
                </>
              }
              {
                role === 0 && tab === 'fees_templates' &&
                  <>
                    <div style={{ width: '8px' }} />
                    <Button className="btn-primary" type="primary" style={{ width: 'auto' }} onClick={() => handleNavigate('/create-fee-template')}>
                      <img className="btn-icon icon-space" src={Images.plus} alt="" />
                      {i18n.t("create_template")}
                    </Button>
                  </>
              }
            </div>
          </>
        :
          <div className="menu-btn">
            <img className="btn-icon" src={Images.logo} alt="" onClick={() => logoNavigate('/home/dashboard')}/>
            <div className="header-title-mobile">{i18n.t("my_profile")}</div>
            <div className="mobile-btn-wraper" onClick={handleOpenMobileMenu}>
              <img className="btn-icon" src={Images.menu} alt="" />
            </div>
          </div>
      }
    </Header>
  )
}

function ProfileFooterComp(props) {
  const {role, tab} = props;

  let history = useHistory();

  function handleNavigate(path) {
    history.push(history.location.pathname + path);
  }

  return (
    <Footer className="no-table-page">
      <div className="footer-buttons-container">
        {
          tab === 'general_information' &&
          <>
            <Button className="btn-primary" type="primary" style={{ width: '40px' }} onClick={() => handleNavigate('/change-password')}>
              <img className="btn-icon" src={Images.lock} alt="" />
            </Button>
            <div style={{ width: '8px' }} />
            <Button className="btn-primary" type="primary" style={{ width: '40px' }} onClick={() => handleNavigate('/edit')}>
              <img className="btn-icon" src={Images.editWhite} alt="" />
            </Button>
          </>
        }
        {
          role === 0 && tab === 'fees_templates' &&
            <>
              <div style={{ width: '8px' }} />
              <Button className="btn-primary" type="primary" style={{ width: '40px' }} onClick={() => handleNavigate('/create-fee-template')}>
                <img className="btn-icon" src={Images.plus} alt="" />
              </Button>
            </>
        }
      </div>
    </Footer>
  )
}

const mapStateToProps = (state) => {
  return {
    role: state.auth.role,
    tab: state.profile.activeTab,
    isAdmin: state.auth.isAdmin,
    nav: state.nav,
  }
}

const mapDispatchToProps = (dispatch) => ({
  navSetProp: (key, value) => dispatch(NavActions.navSetProp(key, value)),
})

export const ProfileFooter = connect(mapStateToProps, mapDispatchToProps)(ProfileFooterComp);
export default connect(mapStateToProps, mapDispatchToProps)(ProfileHeader)
