import React, { useState, useEffect } from 'react'
import { Table, Popconfirm } from 'antd'
import moment from 'moment'
import Images from '../../Images'
import PaginationItemRender from '../PaginationItemRender'
import { titleWithSortFilters } from '../titleWithSortFilters'
import i18n from '../../i18n'
import CustomIcon from '../../Icon'
import utils from '../../Utils/utils'

import './Table.css'

function InvoicesTable(props){
  const {
    page,
    pageSize,
    scale,
    data,
    fetching
  } = props;

  const [dataSource, setDataSource] = useState(null);

  useEffect(() => {
    setDataSource(data && data.results ? data.results.asMutable({deep:true}) : []);
  }, [data]);

  function getColumns() {
    return [
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("date"), true, false, stateData, 'date'),
        dataIndex: 'date',
        key: 'date',
        sorter: true,
        render: (text, record) => {
          return (
              <span>{text && moment(text).format('DD.MM.YYYY')}</span>
          )
        },
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("auto"), true, false, stateData, 'car'),
        dataIndex: 'car',
        key: 'car',
        sorter: true
      },
      {
        title: (stateData)=>titleWithSortFilters("VIN", true, false, stateData, 'vin'),
        dataIndex: 'vin',
        key: 'vin',
        sorter: true
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("lot_number"), true, false, stateData, 'lot_number'),
        dataIndex: 'lot_number',
        key: 'lot_number',
        sorter: true
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("amount"), true, false, stateData, 'vehicle_sales'),
        dataIndex: 'vehicle_sales',
        key: 'vehicle_sales',
        sorter: true,
        render: text => <span>{utils.round10Value(text)}</span>
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("document"), true, false, stateData, 'file_name'),
        dataIndex: 'file_name',
        key: 'file_name',
        sorter: true,
        render: (text, record) => {
          return (
            text &&
              <a href={record.file} target="_blank" rel="noopener noreferrer">{text.replace(/\..+$/, '')}</a>
          )
        },
      },
      {
        title: (""),
        dataIndex: 'id',
        key: 'id',
        render: (text, record) => {
          return (
            <Popconfirm
              title={i18n.t("invoice_delete_confirm")}
              onConfirm={() => props.onDelete(text)}
              okText={i18n.t("yes")}
              cancelText={i18n.t("no")}
            >
              <span className="download-icon"><img src={Images.trash} alt=""/></span>
            </Popconfirm>
          )
        },
      }
    ];
  }

  function handleTableChange(pagination, filters, sorter){

    var sort_field = null;
    var sort_order = null;

    if(sorter && sorter.field && sorter.order){
      sort_field = sorter.field;
      sort_order = sorter.order //=== "ascend" ? 1 : 0;
    }

    props.onChangePage(pagination.current, pagination.pageSize, sort_field, sort_order)

  }

  const totalCount = data ? data.count : 0;

  return (
    <Table
      loading={{spinning:fetching, indicator:<div className="loader"><CustomIcon type="loader" /></div>}}
      pagination= {{itemRender:PaginationItemRender, current:page, pageSize:pageSize, total:totalCount}}
      className="main-table"
      style={{fontSize: (scale * 14 / 100) + 'px'}}
      rowKey={row => row.id}
      rowClassName="process-table-row"
      dataSource={dataSource}
      columns={getColumns()}
      scrollToFirstRowOnChange
      onChange={handleTableChange}
      sortDirections={['descend', 'ascend']}
    />
   )


}

export default InvoicesTable
