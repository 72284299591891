import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'antd'
import moment from 'moment'
import PaginationItemRender from '../PaginationItemRender'
import { titleWithSortFilters } from '../titleWithSortFilters'
import i18n from '../../i18n'
import CustomIcon from '../../Icon'

import './Table.css'

function BrokerTable(props){
  const {
    page,
    pageSize,
    scale,
    data,
    fetching
  } = props;

  const [dataSource, setDataSource] = useState(null);

  useEffect(() => {
    setDataSource(data && data.results ? data.results.asMutable({deep:true}) : []);
  }, [data]);

  function getColumns() {
    return [
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("purchase_date"), true, false, stateData, 'purchase_date'),
        dataIndex: 'purchase_date',
        key: 'purchase_date',
        sorter: true,
        render: (text, record) => {
          return (
              <span>{text && moment(text).format('DD.MM.YYYY')}</span>
          )
        },
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("mark"), true, false, stateData, 'description'),
        dataIndex: 'description',
        key: 'description',
        sorter: true
      },
      {
        title: (stateData)=>titleWithSortFilters("VIN", true, false, stateData, 'vin'),
        dataIndex: 'vin',
        key: 'vin',
        sorter: true,
        render: (text, record) => {
          return (
            <Link to={'/home/car/' + text}>{text}</Link>
          )
        },
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("carrier"), true, false, stateData, 'carrier'),
        dataIndex: 'carrier',
        key: 'carrier',
        sorter: true
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("booking"), true, false, stateData, 'booking_no'),
        dataIndex: 'booking_no',
        key: 'booking_no',
        sorter: true
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("container"), true, false, stateData, 'container_no'),
        dataIndex: 'container_no',
        key: 'container_no',
        sorter: true
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("approximate_arrival"), true, false, stateData, 'arrival_date'),
        dataIndex: 'arrival_date',
        key: 'arrival_date',
        sorter: true
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("port_of_arrival"), true, false, stateData, 'place_of_delivery'),
        dataIndex: 'place_of_delivery',
        key: 'place_of_delivery',
        sorter: true,
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("line_company"), true, false, stateData, 'shipping_line'),
        dataIndex: 'shipping_line',
        key: 'shipping_line',
        sorter: true,
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("consignee"), true, false, stateData, 'grusotrade'),
        dataIndex: 'grusotrade',
        key: 'grusotrade',
        sorter: true
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("eksbro_invoice"), true, false, stateData, 'eksbro_invoice'),
        dataIndex: 'eksbro_invoice',
        key: 'eksbro_invoice',
        sorter: true,
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("parking_invoice"), true, false, stateData, 'parking_invoice'),
        dataIndex: 'parking_invoice',
        key: 'parking_invoice',
        sorter: true,
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("eksbro_paid"), true, false, stateData, 'eksbro_paid'),
        dataIndex: 'eksbro_paid',
        key: 'eksbro_paid',
        sorter: true
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("parking_paid"), true, false, stateData, 'parking_paid'),
        dataIndex: 'parking_paid',
        key: 'parking_paid',
        sorter: true
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("balance"), true, false, stateData, 'balance'),
        dataIndex: 'balance',
        key: 'balance',
        sorter: true
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("client_from_list"), true, false, stateData, 'cti_manager'),
        dataIndex: 'cti_manager',
        key: 'cti_manager',
        sorter: true
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("comments"), true, false, stateData, 'note'),
        dataIndex: 'note',
        key: 'note',
        sorter: true
      },
    ];
  }

  function handleTableChange(pagination, filters, sorter){

    var sort_field = null;
    var sort_order = null;

    if(sorter && sorter.field && sorter.order){
      sort_field = sorter.field;
      sort_order = sorter.order //=== "ascend" ? 1 : 0;
    }

    props.onChangePage(pagination.current, pagination.pageSize, sort_field, sort_order)

  }

  const totalCount = data ? data.count : 0;

  return (
    <Table
      loading={{spinning:fetching, indicator:<div className="loader"><CustomIcon type="loader" /></div>}}
      pagination= {{itemRender:PaginationItemRender, current:page, pageSize:pageSize, total:totalCount}}
      className="main-table"
      style={{fontSize: (scale * 14 / 100) + 'px'}}
      rowKey={row => row.id}
      rowClassName="process-table-row"
      dataSource={dataSource}
      columns={getColumns()}
      scrollToFirstRowOnChange
      onChange={handleTableChange}
      sortDirections={['descend', 'ascend']}
    />
   )


}

export default BrokerTable
