import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  tableScaleChange: ['tableScale'],
  tablePageSizeChange: ['page_size'],
  tableColumnsToggle: ['isHide'],

})

export const SettingsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  tableScale: 100,
  page_size: 25,
  isHide: false,

})

/* ------------- Selectors ------------- */
export const SettingsSelectors = {
}

/* ------------- Reducers ------------- */

export const tableScaleChange = (state: Object, { tableScale }) =>{
  return state.merge({ tableScale })
}

export const tablePageSizeChange = (state: Object, { page_size }) =>{
  return state.merge({ page_size })
}

export const tableColumnsToggle = (state: Object, { isHide }) =>{
  return state.merge({ isHide })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.TABLE_SCALE_CHANGE]: tableScaleChange,
  [Types.TABLE_PAGE_SIZE_CHANGE]: tablePageSizeChange,
  [Types.TABLE_COLUMNS_TOGGLE]: tableColumnsToggle,

})
