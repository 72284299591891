import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {useHistory} from 'react-router-dom'
import TitlesActions from '../Redux/TitlesRedux'
import TitlesTable from '../Components/Tables/TitlesTable'

function TitlesPage(props) {
  const { titles, settings, isDesktop, isAdmin } = props;

  const [start, setStart] = useState(false);

  const compHeight = 'calc(100Vh - 128px)';

  let history = useHistory();

  useEffect(() => {
    props.resetSearch();
    props.getTitles(1, settings.page_size);
    props.getTitleColors();
    setStart(true);

    return function cleanup() {
      props.titlesReset();
      setStart(false);
    }
  }, [0]);

  useEffect(() => {
    if (start) {
      props.getTitles(1, settings.page_size, titles.sort_field, titles.sort_order, titles.search);
    }
  }, [settings.page_size])

  function handleChangePage(page, page_size, sort_field, sort_order){
    props.getTitles(page, page_size, sort_field, sort_order, titles.search);
  }

  return (
    <div className="content-page" style={{height: !isDesktop && compHeight}}>
      <TitlesTable
        data={titles.list}
        page={titles.page}
        fetching={titles.fetching}
        pageSize={settings.page_size}
        scale={settings.tableScale}
        isHide={settings.isHide}
        colors={titles.colorList}
        history={history}
        isAdmin={isAdmin}
        onChangePage={handleChangePage}
        setFocus={props.titleSetFocusRow}
      />
    </div>

  )
}

const mapStateToProps = (state) => {
  return {
    titles: state.titles,
    settings: state.settings,
    isDesktop: state.nav.isDesktop,
    isAdmin: state.auth.isAdmin,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getTitles: (page, page_size, sort_field, sort_order, search) => dispatch(TitlesActions.titlesRequest(page, page_size, sort_field, sort_order, search)),
  getTitleColors: () => dispatch(TitlesActions.titleColorsRequest()),
  titlesReset: () => dispatch(TitlesActions.titlesReset()),
  resetSearch: () => dispatch(TitlesActions.titleSearchReset()),
  deleteTitle: (id) => dispatch(TitlesActions.titleDeleteRequest(id)),
  titleSetFocusRow: (row) => dispatch(TitlesActions.titleSetFocusRow(row)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TitlesPage)
