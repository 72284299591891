import React from 'react';

const Wallet = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity={props.opacity ? props.opacity : '0.4'}>
      <path fillRule="evenodd" clipRule="evenodd" d="M21 7C22.1046 7 23 7.89543 23 9V11H19C17.3431 11 16 12.3431 16 14C16 15.6569 17.3431 17 19 17H23V19C23 20.1046 22.1046 21 21 21H3C1.89543 21 1 20.1046 1 19V5.5C1 4.39543 1.89543 3.5 3 3.5H17C18.1046 3.5 19 4.39543 19 5.5H4.25C3.83579 5.5 3.5 5.83579 3.5 6.25C3.5 6.66421 3.83579 7 4.25 7H21ZM19 12.5C18.1716 12.5 17.5 13.1716 17.5 14C17.5 14.8284 18.1716 15.5 19 15.5H23V12.5H19Z" fill={props.color ? props.color : "#262F56"} />
    </g>
  </svg>

);

export default Wallet;
