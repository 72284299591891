import React from 'react'
import { Row, Col } from 'antd'
import i18n from '../i18n'
import utils from '../Utils/utils'

function AutoFinance(props) {
  const { data } = props;

  return (
    data &&
    <div className="auto-info-section">
      <Row gutter={[16, 14]}>
        <Col className="gutter-row" lg={8} xs={24}>
          <span className="auto-info-header">{i18n.t("client_invoices")}</span>
        </Col>
      </Row>
      <Row gutter={[16, 14]}>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("auto_sbor")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.c_price_sbor && `$${utils.formatValue(data.c_price_sbor)}`}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("parking")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.c_parking && `$${utils.formatValue(data.c_parking)}`}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("add_payment")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.c_add_payment && `$${utils.formatValue(data.c_add_payment)}`}
          </Col>
        </Col>
      </Row>
      <Row gutter={[16, 14]}>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("local_transp")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.c_local_transp && `$${utils.formatValue(data.c_local_transp)}`}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>Sales_tax</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.c_fine_memo && `$${utils.formatValue(data.c_fine_memo)}`}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("insurance")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.c_insurance && `$${utils.formatValue(data.c_insurance)}`}
          </Col>
        </Col>
      </Row>
      <Row gutter={[16, 14]}>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("ocean_transp")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.c_ocean_transp && `$${utils.formatValue(data.c_ocean_transp)}`}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("doc_tranfered")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.c_doc_tranfered && `$${utils.formatValue(data.c_doc_tranfered)}`}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>Copart services</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.copart_service  && `$${utils.formatValue(data.copart_service )}`}
          </Col>
        </Col>
      </Row>
      <Row gutter={[16, 14]}>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("office_fee")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.c_office_fee && `$${utils.formatValue(data.c_office_fee)}`}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("storage")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.c_storage && `$${utils.formatValue(data.c_storage)}`}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title bold" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("total_cost")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.c_total_cost >= 0 ? `$${utils.formatValue(data.c_total_cost)}` : `- $${utils.formatValue(Math.abs(data.c_total_cost))}`}
          </Col>
        </Col>
      </Row>
      <div className="divider"></div>
      <Row gutter={[16, 14]}>
        <Col className="gutter-row" lg={8} xs={24}>
          <span className="auto-info-header">{i18n.t("client_payments")}</span>
        </Col>
      </Row>
      <Row gutter={[16, 14]}>
        <Col className="gutter-row" lg={8} xs={0}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("payment_to_auction")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.payment_auction && `$${utils.formatValue(data.payment_auction)}`}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("cabinet")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.total_payments_inter && `$${utils.formatValue(data.total_payments_inter)}`}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("client_paid_calc")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.client_paid_calc && `$${utils.formatValue(data.client_paid_calc)}`}
          </Col>
        </Col>
      </Row>
      <Row gutter={[16, 14]}>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("pc")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.a_rc && `$${utils.formatValue(data.a_rc)}`}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("cabinet_2")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.a_cabinet_cash && `$${utils.formatValue(data.a_cabinet_cash)}`}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("balance")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.plus_minus_calc >= 0 ? `$${utils.formatValue(data.plus_minus_calc)}` : `- $${utils.formatValue(Math.abs(data.plus_minus_calc))}`}
          </Col>
        </Col>
      </Row>
    </div>
  )
}

export default AutoFinance
