import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import configureStore from './CreateStore'
import rootSaga from '../Sagas/'
import { createBrowserHistory  } from "history";
import ReduxPersist from '../Config/ReduxPersist'
import { connectRouter } from 'connected-react-router'
export const history = createBrowserHistory()

/* ------------- Assemble The Reducers ------------- */

export const reducers = combineReducers({
  auth: require('./AuthRedux').reducer,
  startup: require('./StartupRedux').reducer,
  nav: require('./NavRedux').reducer,
  router: connectRouter(history),
  users: require('./UserRedux').reducer,
  cars: require('./CarsRedux').reducer,
  invoices: require('./InvoicesRedux').reducer,
  dealers: require('./DealersRedux').reducer,
  settings: require('./SettingsRedux').reducer,
  lang: require('./LangRedux').reducer,
  payments: require('./PaymentsRedux').reducer,
  calc: require('./CalcRedux').reducer,
  titles: require('./TitlesRedux').reducer,
  dashboard: require('./DashboardRedux').reducer,
  broker: require('./BrokerRedux').reducer,
  rates: require('./RatesRedux').reducer,
  fees: require('./FeesRedux').reducer,
  profile: require('./ProfileRedux').reducer,
  errors: require('./ErrorsRedux').reducer
})

export default (preloadedState) => {
  let finalReducers = reducers

  if (ReduxPersist.active) {
    const persistConfig = ReduxPersist.storeConfig
    finalReducers = persistReducer(persistConfig, reducers)
  }

  let { store, sagasManager, sagaMiddleware } = configureStore(finalReducers, rootSaga, history, preloadedState)

  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require('./').reducers
      store.replaceReducer(nextRootReducer)

      const newYieldedSagas = require('../Sagas').default
      sagasManager.cancel()
      sagasManager.done.then(() => {
        sagasManager = sagaMiddleware.run(newYieldedSagas)
      })
    })
  }

  return store
}
