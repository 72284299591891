import React from 'react';

const Car = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity={props.opacity ? props.opacity : '0.4'}>
      <path d="M19.6667 4.22223C19.4444 3.55556 18.7778 3.11111 18.1111 3.11111H5.88889C5.11111 3.11111 4.55556 3.55556 4.33333 4.22223L2 10.8889V19.7778C2 20.4444 2.44444 20.8889 3.11111 20.8889H4.22222C4.88889 20.8889 5.33333 20.4444 5.33333 19.7778V18.6667H18.6667V19.7778C18.6667 20.4444 19.1111 20.8889 19.7778 20.8889H20.8889C21.5556 20.8889 22 20.4444 22 19.7778V10.8889L19.6667 4.22223ZM5.88889 15.3333C5 15.3333 4.22222 14.5556 4.22222 13.6667C4.22222 12.7778 5 12 5.88889 12C6.77778 12 7.55556 12.7778 7.55556 13.6667C7.55556 14.5556 6.77778 15.3333 5.88889 15.3333ZM18.1111 15.3333C17.2222 15.3333 16.4444 14.5556 16.4444 13.6667C16.4444 12.7778 17.2222 12 18.1111 12C19 12 19.7778 12.7778 19.7778 13.6667C19.7778 14.5556 19 15.3333 18.1111 15.3333ZM4.22222 9.77778L5.88889 4.77778H18.1111L19.7778 9.77778H4.22222Z" fill={props.color ? props.color : "#262F56"} />
    </g>
  </svg>

);

export default Car;
