import React, { useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { Button, Slider } from 'antd'
import SettingsActions from '../../Redux/SettingsRedux'
import i18n from '../../i18n'
import utils from '../../Utils/utils'

import './PopupPage.css'

import {useHistory} from 'react-router-dom'

function SettingsPopupPage (props) {

  const [show, setShow] = useState(false);

  let history = useHistory();
  function handleClose(e) {
    let path = utils.cutUrlToPrevious(history.location.pathname);
    history.push(path);
  }

  function handleChangeScale(val) {
    props.changeScale(val);
  }

  function handlePageSize(size) {
    props.changePageSize(size);
  }

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 500)
  }, [0]);

  return (
      <div className="popup-dialog">
        <div className="dialog-container settings-popup-dialog">
          <div className="dialog-close-container">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClose}>
              <path d="M1.66663 1.66667L11 11M11 11L20.3333 20.3333M11 11L20.3333 1.66667M11 11L1.66663 20.3333" stroke="#262F56" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <div className="popup-title">{i18n.t("settings")}</div>
          <div className="popup-content">
            <div className="table-row-number">
              <div className="item-title">{i18n.t("records_per_page")}</div>
              <div className="btn-block">
                <Button className={props.page_size === 10 ? 'btn-primary secondary active' : 'btn-primary secondary'} type="primary" onClick={() => handlePageSize(10)}>
                  10
                </Button>
                <Button className={props.page_size === 25 ? 'btn-primary secondary active' : 'btn-primary secondary'} type="primary" onClick={() => handlePageSize(25)}>
                  25
                </Button>
                <Button className={props.page_size === 50 ? 'btn-primary secondary active' : 'btn-primary secondary'} type="primary" onClick={() => handlePageSize(50)}>
                  50
                </Button>
                <Button className={props.page_size === 100 ? 'btn-primary secondary active' : 'btn-primary secondary'} type="primary" onClick={() => handlePageSize(100)}>
                  100
                </Button>
              </div>
            </div>

            <div className="scale-section">
              <div className="item-title">{i18n.t("scale")}</div>
              <div className="scale-block">
                <Slider
                  className="scale-slider"
                  tooltipVisible={show}
                  tooltipPlacement="bottom"
                  defaultValue={props.tableScale}
                  min={50}
                  max={200}
                  step={10}
                  onAfterChange={handleChangeScale}
                />
              </div>
            </div>

            <div className="add-block">
            <Button className="btn-primary gray" type="primary" onClick={() => props.toggleColumns(!props.isHide)}>
              {props.isHide ? i18n.t("show_details") : i18n.t("hide_details")}
            </Button>
            </div>
          </div>
        </div>
      </div>
    )
}

const mapStateToProps = (state) => {
  return {
    tableScale: state.settings.tableScale,
    page_size: state.settings.page_size,
    isHide: state.settings.isHide,
  }
}

// wraps dispatch to create nicer functions to call within our component
const mapDispatchToProps = (dispatch) => ({
  changeScale: (scale) => dispatch(SettingsActions.tableScaleChange(scale)),
  changePageSize: (size) => dispatch(SettingsActions.tablePageSizeChange(size)),
  toggleColumns: (isHide) => dispatch(SettingsActions.tableColumnsToggle(isHide)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPopupPage);
