import React from 'react'
import { Row, Col } from 'antd'
import moment from 'moment'
import i18n from '../i18n'
import utils from '../Utils/utils'

function AutoInfo(props) {
  const { data, role } = props;

  return (
    data &&
    <div className="auto-info-section">
      <Row gutter={[16, 14]}>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>Vin</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.vin}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("purchase_date")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.purchase_date && moment(data.purchase_date).format('DD.MM.YYYY')}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("carrier")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.carrier}
          </Col>
        </Col>
      </Row>
      <Row gutter={[16, 14]}>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("engine")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.vin_engine}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("paid_before")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.paidbefore && moment(data.paidbefore).format('DD.MM.YYYY')}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("auction_location")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.auction_location}
          </Col>
        </Col>
      </Row>
      <Row gutter={[16, 14]}>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("weight")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.vin_curb_weight && utils.formatValue(data.vin_curb_weight) + 'kg'}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("auction")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.auction}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("comments")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.comments}
          </Col>
        </Col>
      </Row>
      {
        role !== 2 &&
        <Row gutter={[16, 14]}>
          <Col className="gutter-row" lg={8} xs={24}>
            <Col className="gutter-row auto-info-title" lg={10} xs={12}>
              <div className="flex-block">
                <span>{i18n.t("dealer")}</span>
                <span className="dots"></span>
              </div>
            </Col>
            <Col className="gutter-row auto-info-value" lg={14} xs={12}>
              {data.cti_manager}
            </Col>
          </Col>
          {
            role === 1 &&
            <Col className="gutter-row" lg={8} xs={24}>
              <Col className="gutter-row auto-info-title" lg={10} xs={12}>
                <div className="flex-block">
                  <span>{i18n.t("manager")}</span>
                  <span className="dots"></span>
                </div>
              </Col>
              <Col className="gutter-row auto-info-value" lg={14} xs={12}>
                {data.cti_cfo}
              </Col>
            </Col>
          }
        </Row>
      }
      <Row gutter={[16, 14]}>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("lot")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.lot_number}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("status")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.status}
          </Col>
        </Col>
      </Row>
    </div>
  )
}

export default AutoInfo
