import React, { useState, useEffect } from 'react'
import { Col, Row, Button, Form, Input, InputNumber, Select, DatePicker, Spin } from 'antd'
import moment from 'moment'
import _ from 'lodash'

import Images from '../../Images'
import IconCustom from '../../Icon'
import i18n from '../../i18n'

const { Option } = Select;

const bankCheckList = ['bmo harris', 'wells fargo'];

export const invoiceFormFields = {
  date: moment(),
  name: '',
  address: '',
  bank: '',
  company: '',
  car_model: '',
  car: '',
  vin: '',
  auction: '',
  lot_number: '',
  vehicle_sales: 0,
  auction_brocker: 0,
  auction_late: 0,
  insurance: 0,
  transportation: 0,
}

function InvoicePopupForm(props) {

  const [backup, setBackup] = useState(null);
  const [bank, setBank] = useState(null);
  const [companyActive, setCompanyActive] = useState(true);
  const [vinList, setVinList] = useState([]);
  const [carList, setCarList] = useState([]);

  const { form, cars, carSearch, auctions, banks, companies, isAdmin, fetching } = props;

  const {
    getFieldDecorator,
    resetFields,
    getFieldsValue,
    setFieldsValue,
  } = form;

  const auctionList = [],
    bankList = [],
    companyList = [];
  if (auctions) {
    for (let i = 0; i < auctions.length; i++) {
      auctionList.push(<Option key={auctions[i]}>{auctions[i]}</Option>);
    }
  }
  if (banks) {
    for (let i = 0; i < banks.length; i++) {
      bankList.push(<Option key={banks[i].id}>{banks[i].bank_name}</Option>);
    }
  }
  if (companies) {
    for (let i = 0; i < companies.length; i++) {
      companyList.push(<Option key={companies[i]}>{companies[i]}</Option>);
    }
  }

  useEffect(() => {
    let initialData = { ...invoiceFormFields };
    if (!backup) {
      setBackup({ ...initialData });
    }
    setFieldsValue(initialData);
  }, [0])

  useEffect(() => {
    if (cars && cars.results) {
      const res = cars.results.map(car =>car.vin);
      if (carSearch) {
        setVinList(_.uniq(res));
        setCarList(_.uniqBy(cars.results), 'vin');
      } else {
        setVinList(_.uniq(vinList.concat(res)));
        setCarList(_.uniqBy(carList.concat(cars.results), 'vin'));
      }
    }
  }, [cars])

  useEffect(() => {
    if (bank && isAdmin) {
      let item = banks.find(el => el.id === bank);
      let bankName = item?.bank_name?.toLowerCase() || '';
      if (bankCheckList.includes(bankName)) {
        setCompanyActive(false);
        resetFields(['company']);
      } else {
        setCompanyActive(true);
      }
    }
  }, [bank])

  let non_field_errors = null;

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const data = { ...values }
        if (data.date) {
          data.date = data.date.format('YYYY-MM-DD');
        }
        props.onSubmit(data);
      }
    });

  }

  function resetData() {
    setFieldsValue(backup);
    props.onClose();
  }

  function handleCarSelect(vin) {
    let data = getFieldsValue();
    if (vin && vin !== 'null') {
      const item = carList?.find(car => car.vin === vin);
      if (item) {
        data.car = item.description;
        data.vin = item.vin;
        data.lot_number = item.lot_number;
        data.auction = item.auction;
        setFieldsValue(data);
      }
    } else {
      resetFields(['car', 'vin', 'lot_number', 'auction']);
    }
  }

  function onSearch(val) {
    props.searchCar(val);
    if (val) {
      resetFields(['car', 'vin', 'lot_number', 'auction']);
      let data = getFieldsValue();
      data.vin = val;
      setFieldsValue(data);
    }
  }

  return (
    <div className="dialog-container settings-popup-dialog invoice-popup-dialog">
      <div className="dialog-close-container">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={props.onClose}>
          <path d="M1.66663 1.66667L11 11M11 11L20.3333 20.3333M11 11L20.3333 1.66667M11 11L1.66663 20.3333" stroke="#262F56" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
      <Spin size="large" spinning={fetching}>
        <div className="popup-title">{i18n.t("invoice_making")}</div>
        <div className="popup-content">

          {<Form id="invoice-popup-form" onSubmit={handleSubmit} disabled>

            {non_field_errors}
            <Row gutter={16} className="flex-row">
              <Col className="gutter-row" xl={8} md={12} xs={24}>
                <Form.Item label={i18n.t("date")}>
                  {getFieldDecorator('date')(
                    <DatePicker
                      placeholder={i18n.t("select_date")}
                      format="DD.MM.YYYY"
                      suffixIcon={<IconCustom type="calendar" />}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col className="gutter-row" xl={8} md={12} xs={24}>
                <Form.Item label={i18n.t("fullname_as_inter_passport") + '*'}>
                  {getFieldDecorator('name', {
                    rules: [{ required: true, message: i18n.t("required_field") }],
                  })(
                    <Input />
                  )}
                </Form.Item>
              </Col>
              <Col className="gutter-row" xl={8} md={12} xs={24}>
                <Form.Item label={i18n.t("residence_address")}>
                  {getFieldDecorator('address')(
                    <Input />
                  )}
                </Form.Item>
              </Col>
              <Col className="gutter-row" xl={8} md={12} xs={24}>
                <Form.Item label={i18n.t("bank_for_payment") + '*'}>
                  {getFieldDecorator('bank', {
                    rules: [{ required: true, message: i18n.t("required_field") }],
                  })(
                    <Select
                      size="large"
                      showArrow={true}
                      suffixIcon={<IconCustom type="select" />}
                      onChange={setBank}
                    >
                      {bankList}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              {
                isAdmin &&
                <Col className="gutter-row" xl={8} md={12} xs={24}>
                  <Form.Item label={i18n.t("company_name")}>
                    {getFieldDecorator('company', {
                      rules: [{ required: companyActive, message: i18n.t("required_field") }],
                    })(
                      <Select
                        size="large"
                        showArrow={true}
                        suffixIcon={<IconCustom type="select" />}
                        disabled={!companyActive}
                      >
                        {companyList}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              }
            </Row>

            <Row gutter={16} className="flex-row">
              <Col className="gutter-row" xl={8} md={12} xs={24}>
                <Form.Item label="VIN*">
                  {getFieldDecorator('vin', {
                    rules: [{ required: true, message: i18n.t("required_field") }],
                  })(
                    <Select
                      size="large"
                      showArrow={true}
                      showSearch={true}
                      dropdownClassName="auction-location"
                      //optionFilterProp="children"
                      suffixIcon={<IconCustom type="select" />}
                      onChange={handleCarSelect}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onPopupScroll={e => {
                        if (!carSearch) {
                          const { target } = e;
                          if (target.scrollTop + target.clientHeight === target.scrollHeight) {
                            props.paginateCar();
                          }
                        }
                      }}
                    >
                      {vinList?.map(vin => (
                        <Option key={vin}>{vin}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col className="gutter-row" xl={8} md={12} xs={24}>
                <Form.Item label={i18n.t("model_brand_year") + '*'}>
                  {getFieldDecorator('car', {
                    rules: [{ required: true, message: i18n.t("required_field") }],
                  })(
                    <Input />
                  )}
                </Form.Item>
              </Col>
              <Col className="gutter-row" xl={8} md={12} xs={24}>
                <Form.Item label={i18n.t("auction") + '*'}>
                  {getFieldDecorator('auction')(
                    <Select
                      size="large"
                      showArrow={true}
                      suffixIcon={<IconCustom type="select" />}
                    >
                      {auctionList}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col className="gutter-row" xl={8} md={12} xs={24}>
                <Form.Item label={i18n.t("lot") + '*'}>
                  {getFieldDecorator('lot_number')(
                    <Input />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} className="flex-row">
              <Col className="gutter-row" xl={8} md={12} xs={24}>
                <Form.Item label={i18n.t("vehicle_sales")}>
                  {getFieldDecorator('vehicle_sales')(
                    <InputNumber min={0} />
                  )}
                </Form.Item>
              </Col>
              <Col className="gutter-row" xl={8} md={12} xs={24}>
                <Form.Item label={i18n.t("auction_broker_fee")}>
                  {getFieldDecorator('auction_brocker')(
                    <InputNumber min={0} />
                  )}
                </Form.Item>
              </Col>
              <Col className="gutter-row" xl={8} md={12} xs={24}>
                <Form.Item label={i18n.t("auction_late_and_storage_fees")}>
                  {getFieldDecorator('auction_late')(
                    <InputNumber min={0} />
                  )}
                </Form.Item>
              </Col>

              <Col className="gutter-row" xl={8} md={12} xs={24}>
                <Form.Item label={i18n.t("insurance")}>
                  {getFieldDecorator('insurance')(
                    <InputNumber min={0} />
                  )}
                </Form.Item>
              </Col>
              <Col className="gutter-row" xl={8} md={12} xs={24}>
                <Form.Item label={i18n.t("transportation_ocean_domestic")}>
                  {getFieldDecorator('transportation')(
                    <InputNumber min={0} />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>}

        </div>
        <div className="dialog-footer">
          <Button className="btn-primary gray" type="primary" onClick={resetData}>
            <img className="btn-icon icon-space" src={Images.cancel} alt="" />
            {i18n.t("cancel")}
          </Button>
          <div style={{ width: '16px' }} />
          <Button form="user-popup-form" htmlType="submit" onClick={handleSubmit} className="btn-primary" type="primary" >
            <img className="btn-icon icon-space" src={Images.check} alt="" />
            {i18n.t("create_invoice")}
          </Button>
        </div>
      </Spin>
    </div>
  )
}

const WrappedUserEditForm = Form.create({ name: 'invoice-popup-form' })(InvoicePopupForm);

export default WrappedUserEditForm
