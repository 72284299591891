import React from 'react'
import { Button, Drawer } from 'antd'

import i18n from '../i18n'
import IconCustom from '../Icon'
import Images from '../Images'

function SidebarMobile({ path, lang, isAdmin, role, nav, onChangeLang, onNavigate, onClose, logout }) {
  const selected = '1';

  function signOut() {
    logout();
    onNavigate && onNavigate('/login');
  }

  return (
    <Drawer
      width={325}
      title=""
      placement="right"
      closable={false}
      onClose={onClose}
      visible={nav.isShowMobileMenu}
    >
      <div className="mobile-menu-header">
        <IconCustom
          onClick={() => onClose()}
          role="button"
          btnType="default"
          type="close"
        />
      </div>
      <div className="mobile-menu-content" style={{ justifyContent: isAdmin && 'center' }}>

        {/* {
            !isAdmin &&
              <div
                onClick={()=>onNavigate && onNavigate('/home/dashboard')}
                className={"mobile-menu-item "+(path && path.indexOf('/home/dashboard')>=0 ? 'active' : '')}
              >
                <img src={Images.logo} alt="" />
                Dashboard
              </div>
          } */}
        <div
          onClick={() => onNavigate && onNavigate('/home/cars')}
          className={"mobile-menu-item " + (path && path.indexOf('/home/car') >= 0 ? 'active' : '')}
          style={{ marginBottom: isAdmin && 40 }}
        >
          <IconCustom
            className="mobile-menu-icon"
            type="car"
            opacity={path && path.indexOf('/home/car') >= 0 && selected}
          />
          {i18n.t('auto')}
        </div>

        <div
          onClick={() => onNavigate && onNavigate('/home/payments')}
          className={"mobile-menu-item " + (path && path.indexOf('/home/payments') >= 0 ? 'active' : '')}
          style={{ marginBottom: isAdmin && 40 }}
        >
          <IconCustom
            className="mobile-menu-icon"
            type="wallet"
            opacity={path && path.indexOf('/home/payments') >= 0 && selected}
          />
          {i18n.t('payments')}
        </div>

        {
          !isAdmin ?
            <>
              {/* <div
                    onClick={()=>onNavigate && onNavigate('/home/broker')}
                    className={"mobile-menu-item "+(path && path.indexOf('/home/broker')>=0 ? 'active' : '')}
                  >
                    <IconCustom
                      className="mobile-menu-icon"
                      type="broker"
                      opacity={path && path.indexOf('/home/broker')>=0 && selected}
                    />
                    {i18n.t('expeditor_broker')}
                  </div> */}
              {/* <div
                    onClick={()=>onNavigate && onNavigate('/home/titles')}
                    className={"mobile-menu-item "+(path && path.indexOf('/home/titles')>=0 ? 'active' : '')}
                  >
                    <IconCustom
                      className="mobile-menu-icon"
                      type="title"
                      opacity={path && path.indexOf('/home/titles')>=0 && selected}
                    />
                    {i18n.t('titles')}
                  </div> */}
              <div
                onClick={() => onNavigate && onNavigate('/home/invoices')}
                className={"mobile-menu-item " + (path && path.indexOf('/home/invoices') >= 0 ? 'active' : '')}
              >
                <IconCustom
                  className="mobile-menu-icon"
                  type="invoice"
                  opacity={path && path.indexOf('/home/invoices') >= 0 && selected}
                />
                {i18n.t('invoices')}
              </div>

              {
                role === 0 &&
                <>
                  <div
                    onClick={() => onNavigate && onNavigate('/home/rates/local')}
                    className={"mobile-menu-item " + (path && path.indexOf('/home/rates') >= 0 ? 'active' : '')}
                  >
                    <IconCustom
                      className="mobile-menu-icon"
                      type="rate"
                      opacity={path && path.indexOf('/home/rates') >= 0 && selected}
                    />
                    {i18n.t('my_rates')}
                  </div>
                  <div
                    onClick={() => onNavigate && onNavigate('/home/calculator')}
                    className={"mobile-menu-item " + (path && path.indexOf('/home/calculator') >= 0 ? 'active' : '')}
                  >
                    <IconCustom
                      className="mobile-menu-icon"
                      type="calculator"
                      opacity={path && path.indexOf('/home/calculator') >= 0 && selected}
                    />
                    {i18n.t('calculator')}
                  </div>
                </>
              }

            </>
            :
            <>
              <div
                onClick={() => onNavigate && onNavigate('/home/titles')}
                className={"mobile-menu-item " + (path && path.indexOf('/home/titles') >= 0 ? 'active' : '')}
                style={{ marginBottom: 40 }}
              >
                <IconCustom
                  className="mobile-menu-icon"
                  type="title"
                  opacity={path && path.indexOf('/home/titles') >= 0 && selected}
                />
                {i18n.t('titles')}
              </div>
              <div
                onClick={() => onNavigate && onNavigate('/home/invoices')}
                className={"mobile-menu-item " + (path && path.indexOf('/home/invoices') >= 0 ? 'active' : '')}
                style={{ marginBottom: 40 }}
              >
                <IconCustom
                  className="mobile-menu-icon"
                  type="invoice"
                  opacity={path && path.indexOf('/home/invoices') >= 0 && selected}
                />
                {i18n.t('invoices')}
              </div>
              <div
                onClick={() => onNavigate && onNavigate('/home/calculator')}
                className={"mobile-menu-item " + (path && path.indexOf('/home/calculator') >= 0 ? 'active' : '')}
                style={{ marginBottom: 40 }}
              >
                <IconCustom
                  className="mobile-menu-icon"
                  type="calculator"
                  opacity={path && path.indexOf('/home/calculator') >= 0 && selected}
                />
                {i18n.t('calculator')}
              </div>
              <div
                onClick={() => onNavigate && onNavigate('/home/dealers')}
                className={"mobile-menu-item " + (path && path.indexOf('/home/dealer') >= 0 ? 'active' : '')}
                style={{ marginBottom: 40 }}
              >
                <IconCustom
                  className="mobile-menu-icon"
                  type="users"
                  opacity={path && path.indexOf('/home/dealer') >= 0 && selected}
                />
                {i18n.t('dealers')}
              </div>
            </>
        }
        {
          role === 0 &&
          <div
            onClick={() => onNavigate && onNavigate('/home/subdealers')}
            className={"mobile-menu-item " + (path && path.indexOf('/home/subdealer') >= 0 ? 'active' : '')}
          >
            <IconCustom
              className="mobile-menu-icon"
              type="users"
              opacity={path && path.indexOf('/home/subdealer') >= 0 && selected}
            />
            {i18n.t('subdealers')}
          </div>
        }

        <div className="language-block" style={{ marginBottom: isAdmin && 40 }}>
          <Button className={lang.lang === 'en' ? 'active' : ''} type="primary" onClick={() => onChangeLang('en')}>
            en
          </Button>
          <Button className={lang.lang === 'ru' ? 'active' : ''} type="primary" onClick={() => onChangeLang('ru')}>
            ru
          </Button>
          <Button className={lang.lang === 'pl' ? 'active' : ''} type="primary" onClick={() => onChangeLang('pl')}>
            pl
          </Button>
        </div>

        <div
          onClick={() => onNavigate && onNavigate('/home/profile')}
          className={"mobile-menu-item " + (path && path.indexOf('/home/profile') >= 0 ? 'active' : '')}
          style={{ marginBottom: isAdmin && 40 }}
        >
          <IconCustom
            className="mobile-menu-icon"
            type="user"
            opacity={path && path.indexOf('/home/profile') >= 0 && selected}
          />
          {i18n.t('my_profile')}
        </div>
        <div
          onClick={signOut}
          className="mobile-menu-item"
        >
          <IconCustom
            className="mobile-menu-icon"
            type="logout"
          />
          {i18n.t('logout')}
        </div>

      </div>
    </Drawer>
  )
}

export default SidebarMobile
