import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, notification, Spin } from 'antd'
import CalcActions from '../Redux/CalcRedux'
import CalculatorForm from '../Components/Forms/CalculatorForm'
import Images from '../Images'
import i18n from '../i18n'
import utils from '../Utils/utils'

import './CalculatorPage.css'

function CalculatorPage(props) {
  const {calc} = props;

  const [price, setPrice] = useState(null);
  const [total, setTotal] = useState(null);
  const [addCosts, setAddCosts] = useState(null);

  useEffect(() => {
    props.getCalcEngines();
    props.getCalcAuctions();
    props.getCalcDelivery();

    return function cleanup() {
      props.calcReset();
    }
  }, [0]);


  useEffect(() => {
    if (calc.deliveryCalcResult && calc.comissionCalcResult && calc.customCalcResult && price) {
      setTotal(price + calc.deliveryCalcResult + calc.comissionCalcResult + calc.customCalcResult)
    } else {
      setTotal(null)
    }
  }, [calc.deliveryCalcResult, calc.comissionCalcResult, calc.customCalcResult]);

  useEffect(() => {
    if (calc.exceptions && calc.exceptions.length) {
      notification['info']({
        message: i18n.t("exception"),
        description: calc.exceptions
      })
    }
  }, [calc.exceptions]);

  function changePrice(val) {
    setPrice(val);
    if (!val) {
      setTotal(null);
      props.resetCalcValue();
    }
  }

  function changeAddCosts(val) {
    if (val) {
      setAddCosts(val);
    } else {
      setAddCosts(null);
    }
  }

  return (
    <Spin size="large" spinning={calc.deliveryCalcFetching || calc.comissionCalcFetching || calc.customCalcFetching}>
      <div className="content-page no-table">
        <div className="main-content">
          <CalculatorForm
            data={calc}
            calcDelivery={props.calcDelivery}
            calcComission={props.calcComission}
            calcCustom={props.calcCustom}
            calcCustomReset={props.calcCustomReset}
            onPriceChange={changePrice}
            changeAddCosts={changeAddCosts}
          />
          <div className="total-block">
            <div className="total-item">
              <div className="total-title">{i18n.t("total_car_cost")}</div>
              <div className="total_price">{total ? utils.formatValue(total + addCosts) : '-'}</div>
            </div>
            <div className="total-item">
              <div className="total-title">{i18n.t("pensionary")}</div>
              <div className="pension-fund">{calc.pension_fund && price ? utils.formatValue(calc.pension_fund) : '-'}</div>
            </div>
            <div className="info-block">
              <div className="info-text">{i18n.t("info_text")}</div>
              <div className="info-icons">
                <Button className="btn-primary violet" type="primary" href="viber://chat?number=+380672356158">
                  <img className="btn-icon" src={Images.viber} alt="" />
                </Button>
                <div style={{ width: '16px' }} />
                <Button className="btn-primary blue" type="primary" href="tg://resolve?domain=irinacolumb">
                  <img className="btn-icon" src={Images.telegram} alt="" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  )
}

const mapStateToProps = (state) => {
  return {
    calc: state.calc
  }
}

const mapDispatchToProps = (dispatch) => ({
  getCalcEngines: () => dispatch(CalcActions.engineRequest()),
  getCalcAuctions: () => dispatch(CalcActions.auctionRequest()),
  getCalcDelivery: () => dispatch(CalcActions.deliveryRequest()),

  calcDelivery: (data) => dispatch(CalcActions.deliveryCalcRequest(data)),
  calcComission: (data) => dispatch(CalcActions.comissionCalcRequest(data)),
  calcCustom: (data) => dispatch(CalcActions.customCalcRequest(data)),

  calcDeliveryReset: () => dispatch(CalcActions.deliveryCalcReset()),
  calcComissionReset: () => dispatch(CalcActions.comissionCalcReset()),
  calcCustomReset: () => dispatch(CalcActions.customCalcReset()),

  calcReset: () => dispatch(CalcActions.calcReset()),
  resetCalcValue: () => dispatch(CalcActions.calcValueReset()),

})

export default connect(mapStateToProps, mapDispatchToProps)(CalculatorPage)
