import { call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import FeesActions from '../Redux/FeesRedux'
import AuthActions from '../Redux/AuthRedux'
import ErrorsActions from '../Redux/ErrorsRedux'

export function * getFees(api, authApi, {id}) {
  try {
    const response = yield call(api.getFees, id);
    if (response && response.ok) {
      yield put(FeesActions.feesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(FeesActions.feeFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getFees(api, authApi, {id});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(FeesActions.feeFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * deleteFee(api, authApi, { id }:Object) {
  try {
    const response = yield call(api.deleteFee, id);
    if (response && response.ok) {
      yield put(FeesActions.feeDeleteSuccess(response.data ? response.data : true))
      yield put(FeesActions.feesRequest());

    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(FeesActions.feeFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * deleteFee(api, authApi, { id });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(FeesActions.feeFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * saveFee(api, authApi, { data }:Object) {
  try {
    const saveFunc = data.id ? api.updateFee : api.addFee;
    const response = yield call(saveFunc, data);
    if (response && response.ok) {
      yield put(FeesActions.feeSaveSuccess(response.data ? response.data : true))
      yield put(FeesActions.feesRequest());

    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(FeesActions.feeFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * saveFee(api, authApi, { data });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(FeesActions.feeFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getFeeNames(api, authApi) {
  try {
    const response = yield call(api.getFeeNames);
    if (response && response.ok) {
      yield put(FeesActions.feeNamesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(FeesActions.feeFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getFeeNames(api, authApi);
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(FeesActions.feeFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getCustomFee(api, authApi, {vin}) {
  try {
    const response = yield call(api.getCustomFee, vin);
    if (response && response.ok) {
      yield put(FeesActions.feeCustomSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(FeesActions.feeFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getCustomFee(api, authApi, {vin});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(FeesActions.feeFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * deleteCustomFee(api, authApi, { id }:Object) {
  try {
    const response = yield call(api.deleteCustomFee, id);
    if (response && response.ok) {
      yield put(FeesActions.feeCustomDeleteSuccess(response.data ? response.data : true))

    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(FeesActions.feeFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * deleteCustomFee(api, authApi, { id });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(FeesActions.feeFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * saveCustomFee(api, authApi, { data }:Object) {
  try {
    const saveFunc = data.id ? api.updateCustomFee : api.addCustomFee;
    const response = yield call(saveFunc, data);
    if (response && response.ok) {
      yield put(FeesActions.feeCustomSaveSuccess(response.data ? response.data : true))

    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(FeesActions.feeFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * saveCustomFee(api, authApi, { data });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(FeesActions.feeFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}
