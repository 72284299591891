import { call, put, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import UserActions, {UserSelectors} from '../Redux/UserRedux'
import AuthActions from '../Redux/AuthRedux'
import ErrorsActions from '../Redux/ErrorsRedux'

export function * getUsers(api, authApi, { page, page_size, sort_field, sort_order, role, search }) {
  try {
    const response = yield call(api.getUsers, page, page_size, sort_field, sort_order, role, search);
    if (response && response.ok) {
      yield put(UserActions.usersSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(UserActions.userFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getUsers(api, authApi, { page, page_size, sort_field, sort_order, role, search });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(UserActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getUser(api, authApi, { userId }:Object) {
  try {
    const response = yield call(api.getUser, userId);
    if (response && response.ok) {
      yield put(UserActions.userSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(UserActions.userFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getUser(api, authApi, { userId });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(UserActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * deleteUser(api, authApi, { id }:Object) {
  try {
    const response = yield call(api.deleteUser, id);
    if (response && response.ok) {
      yield put(UserActions.userDeleteSuccess(response.data ? response.data : true));
      const {page_size} = yield select(UserSelectors.getState);
      yield put(UserActions.usersRequest(1, page_size, null, null, 2));
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(UserActions.userFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * deleteUser(api, authApi, { id });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(UserActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * saveUser(api, authApi, { data }: Object) {
  try {
    const saveFunc = data.id ? api.updateUser : api.addUser;
    const response = yield call(saveFunc, data);
    if (response && response.ok) {
      yield put(UserActions.userSaveSuccess(response.data))

    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(UserActions.userFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * saveUser(api, authApi, { data });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(UserActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getSubdealers(api, authApi) {
  try {
    const response = yield call(api.getSubdealers);
    if (response && response.ok) {
      yield put(UserActions.subdealersSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(UserActions.userFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getSubdealers(api, authApi);
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(UserActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getAccounts(api, authApi, { userId }: Object) {
  try {
    const response = yield call(api.getAccounts, userId);
    if (response && response.ok) {
      yield put(UserActions.accountsSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(UserActions.userFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getAccounts(api, authApi, {userId});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(UserActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * deleteAccount(api, authApi, { accountId, userId }:Object) {
  try {
    const response = yield call(api.deleteAccount, accountId);
    if (response && response.ok) {
      yield put(UserActions.accountDeleteSuccess(response.data ? response.data : true))
      yield put(UserActions.accountsRequest(userId));
      yield put(UserActions.accountDeleteReset());

    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(UserActions.userFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * deleteAccount(api, authApi, { accountId, userId });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(UserActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * saveAccount(api, authApi, { data, userId }: Object) {
  try {
    const saveFunc = data.id ? api.updateAccount : api.addAccount;
    const response = yield call(saveFunc, data);
    if (response && response.ok) {
      yield put(UserActions.accountSaveSuccess(response.data))
      yield put(UserActions.accountsRequest(userId));
      yield put(UserActions.accountSaveReset());

    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(UserActions.userFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * saveAccount(api, authApi, { data, userId });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(UserActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * changeProfilePassword(api, authApi, { data }: Object) {
  try {
    const response = yield call(api.changeProfilePassword, data);
    if (response && response.ok) {
      yield put(UserActions.profileChangePasswordSuccess(response.data))

    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(UserActions.userFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * changeProfilePassword(api, authApi, { data });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(UserActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * changeUserPassword(api, authApi, { data }: Object) {
  try {
    const response = yield call(api.changeUserPassword, data);
    if (response && response.ok) {
      yield put(UserActions.userChangePasswordSuccess(response.data))

    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(UserActions.userFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * changeUserPassword(api, authApi, { data });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(UserActions.userFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}
