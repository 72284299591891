import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  changeActiveTab: ['activeTab'],
})

export const ProfileTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  activeTab: 'general_information',
})

/* ------------- Reducers ------------- */

export const changeActiveTab = (state: Object, { activeTab }: Object) => {
  return state.merge({ activeTab })
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CHANGE_ACTIVE_TAB]: changeActiveTab
})
