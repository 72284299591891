// a library to wrap and simplify api calls
import apisauce from 'apisauce'

const axios = require('axios');

// our 'constructor'
const create = (baseURL = 'https://wiacargo.dataforest.tech/api') => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  const accessData = {accessToken:null};

  const api = apisauce.create({
    // base URL is read from the 'constructor'
    baseURL,
    // here are some default headers
    headers: {
      'Content-Type': 'application/json',

    },
    // 10 second timeout...
    timeout: 10000
  })

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than 'get', 'post' and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //

  const serialize = (obj, prefix) => {
    var str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "." + p  : p,
          v = obj[p];
        str.push((v !== null && typeof v === "object") ?
          serialize(v, k) :
          encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
    return str.join("&");
  }

  const updateAccessToken = (accessToken, refreshToken) => {
    accessData.accessToken = accessToken;
    accessData.refreshToken = refreshToken;

    if(accessToken){
      api.setHeader('Authorization', 'JWT ' + accessToken);
    } else{
      api.setHeader('Authorization', null);
    }
  }

  const getCars = (page, page_size, sort_field, sort_order, search, filter) => {
    const query = {};
    if (page) {
      query.page = page;
    }

    if (page_size) {
      query.page_size = page_size;
    }

    if (sort_field) {
      query.ordering = ( sort_order === "descend" ? "-" : "" ) + sort_field;
    }

    if (search) {
      query.search = search;
    }

    if (filter) {
      if (filter.vin) {
        query.vin__iexact = filter.vin;
      }
      if (filter.btrim) {
        query.btrim__iexact = filter.btrim;
      }
      if (filter.purchase_date && filter.purchase_date.length) {
        query.purchase_date_after = filter.purchase_date[0];
        query.purchase_date_before = filter.purchase_date[1];
      }
      if (filter.lot_number) {
        query.lot_number__iexact = filter.lot_number;
      }
      if (filter.port_of_arrival) {
        query.port_of_arrival__iexact = filter.port_of_arrival;
      }
      if (filter.sub_dealer) {
        query.sub_dealer = filter.sub_dealer;
      }
    }
    return api.get('/car/car/?' + serialize(query))
  }

  const sendCarFile = (data) => {
    return api.post('/car/car/from_file/', data)
  }

  const getCarPorts = () => {
    return api.get('/car/car/ports/')
  }

  const getCarInfo = vin => {
    return api.get(`/car/car/${vin}/`)
  }

  const updateCarInfo = (vin, data) => {
    return api.put(`/car/car/${vin}/`, data)
  }

  const getCarFinancesInfo = () => {
    return api.get(`/car/car/finances/`)
  }

  const getCarImages = (vin) => {
    const query = {};
    query.car = vin;
    query.page_size = 200;
    return api.get('/car/image/?' + serialize(query))
  }

  const getCarsFile = (sort_field, sort_order, filter) => {
    const headers = {
      'Accept': 'application/vnd.ms-excel',
    };
    const query = {};
    //query.format = 'xls'
    if (sort_field) {
      query.ordering = ( sort_order === "descend" ? "-" : "" ) + sort_field;
    }

    if (filter) {
      if (filter.vin) {
        query.vin__iexact = filter.vin;
      }
      if (filter.btrim) {
        query.btrim__iexact = filter.btrim;
      }
      if (filter.purchase_date && filter.purchase_date.length) {
        query.purchase_date_after = filter.purchase_date[0];
        query.purchase_date_before = filter.purchase_date[1];
      }
      if (filter.lot_number) {
        query.lot_number__iexact = filter.lot_number;
      }
      if (filter.port_of_arrival) {
        query.port_of_arrival__iexact = filter.port_of_arrival;
      }
      if (filter.sub_dealer) {
        query.sub_dealer = filter.sub_dealer;
      }
    }

    return api.get('/car/export/?' + serialize(query), null, {
      headers,
      responseType: 'blob',
      timeout: 60000
    })
  }

  const getCarPhotoFile = data => {
    const config = {
      responseType: 'blob',
      timeout: 30000
    };

    return api.post('/car/image/download/', data, config)
  }

  const getCarChoices = (page, page_size, search) => {
    const query = {};
    if (page) {
      query.page = page;
    }
    if (page_size) {
      query.page_size = page_size;
    }
    if (search) {
      query.search = search;
    }
    return api.get('/car/choices/?' + serialize(query))
  }

  const getPayments = (page, page_size, sort_field, sort_order, search, filter) => {
    const query = {};
    if (page) {
      query.page = page;
    }

    if (page_size) {
      query.page_size = page_size;
    }

    if (sort_field) {
      query.ordering = ( sort_order === "descend" ? "-" : "" ) + sort_field;
    }

    if (search) {
      query.search = search;
    }

    if (filter) {
      if (filter.vin) {
        query.vin__iexact = filter.vin;
      }
      if (filter.date && filter.date.length) {
        query.date_after = filter.date[0];
        query.date_before = filter.date[1];
      }
      if (filter.payment_amount_min) {
        query.payment_amount_min = filter.payment_amount_min;
      }
      if (filter.payment_amount_max) {
        query.payment_amount_max = filter.payment_amount_max;
      }
    }
    return api.get('/payment/payment/?' + serialize(query))
  }

  const sendPaymentFile = (data) => {
    return api.post('/payment/payment/from_file/', data)
  }

  const getInvoices = (page, page_size, sort_field, sort_order, search) => {
    const query = {};
    if (page) {
      query.page = page;
    }

    if (page_size) {
      query.page_size = page_size;
    }

    if (sort_field) {
      query.ordering = ( sort_order === "descend" ? "-" : "" ) + sort_field;
    }

    if (search) {
      query.search = search;
    }

    return api.get('/invoice/invoice/?' + serialize(query))
  }

  const addInvoice = (data) => {
    return api.post(`/invoice/invoice/`, data)
  }

  const updateInvoice = (data) => {
    const newData = {...data};
    delete newData.id;
    return api.put(`/invoice/invoice/${data.id}/`, newData)
  }

  const deleteInvoice = (invoicerId) => {
    return api.delete(`/invoice/invoice/${invoicerId}/`)
  }

  const getInvoiceChoices = () => {
    return api.get('/invoice/invoice/choices/')
  }

  const getUserColumbs = () => {
    return api.get('/user/user/columbs/')
  }

  const getUsers = (page, page_size, sort_field, sort_order, role, search) => {
    const query = {};
    if (page) {
      query.page = page;
    }

    if (page_size) {
      query.page_size = page_size;
    }

    if (sort_field) {
      query.ordering = ( sort_order === "descend" ? "-" : "" ) + sort_field;
    }

    if (search && search.trim()!=="") {
      query.search = search;
    }

    if (!isNaN(role)) {
      query.role = role;
    }

    return api.get('/user/user/?' + serialize(query))
  }

  const getUser = userId => {
    return api.get(`/user/user/${userId}/`)
  }

  //create user
  const addUser = (data) => {
    return api.post(`/user/user/`, data)
  }
  //update user
  const updateUser = (data) => {
    const newData = {...data};
    delete newData.id;
    return api.put(`/user/user/${data.id}/`, newData)
  }
  //delete user
  const deleteUser = userId => {
    return api.delete(`/user/user/${userId}/`)
  }
  //get subdealer's list
  const getSubdealers = () => {
    return api.get(`/user/user/sub_dealers/`)
  }
  //get account list
  const getAccounts = (userId) => {
    const query = {};
    if (userId) {
      query.owner = userId;
    }

    return api.get('/user/account/?' + serialize(query))
  }
  //create account
  const addAccount = (data) => {
    return api.post(`/user/account/`, data)
  }
  //update account
  const updateAccount = (data) => {
    const newData = {...data};
    delete newData.id;
    return api.put(`/user/account/${data.id}/`, newData)
  }
  //delete account
  const deleteAccount = accountId => {
    return api.delete(`/user/account/${accountId}/`)
  }

  //change profile password
  const changeProfilePassword = (data) => {
    return api.put(`/user/user/change_password/`, data)
  }

  //change user password
  const changeUserPassword = (data) => {
    return api.post(`/user/user/reset_password/`, data)
  }

  //get language list
  const getLanguages = () => {
    return api.get('/template/template/languages/')
  }

  const getCalcEngines = () => {
    return api.get('/calculator/clearance/')
  }

  const getCalcAuctions = () => {
    return api.get('/calculator/auction/')
  }

  const getCalcDelivery = () => {
    return api.get('/calculator/transport/')
  }

  const calcDelivery = (data) => {
    return api.post('/calculator/transport/', data)
  }

  const calcComission = (data) => {
    return api.post('/calculator/auction/', data)
  }

  const calcCustom = (data) => {
    return api.post('/calculator/clearance/', data)
  }

  const getTitles = (page, page_size, sort_field, sort_order, search) => {
    const query = {};
    if (page) {
      query.page = page;
    }

    if (page_size) {
      query.page_size = page_size;
    }

    if (sort_field) {
      query.ordering = ( sort_order === "descend" ? "-" : "" ) + sort_field;
    }

    if (search) {
      query.search = search;
    }

    return api.get('/title/title/?' + serialize(query))
  }

  const getTitleColors = () => {
    return api.get('/title/title/colors/')
  }

  const getTitleStates = () => {
    return api.get('/title/title/states/')
  }

  const editTitleComment = (id, data) => {
    return api.patch(`/title/title/${id}/`, data)
  }

  const getDashboardInfo = () => {
    return api.get('/dashboard/dashboard/')
  }

  const getBrokerData = (page, page_size, sort_field, sort_order, search, filter) => {
    const query = {};
    if (page) {
      query.page = page;
    }

    if (page_size) {
      query.page_size = page_size;
    }

    if (sort_field) {
      query.ordering = ( sort_order === "descend" ? "-" : "" ) + sort_field;
    }

    if (search) {
      query.search = search;
    }

    if (filter) {
      if (filter.vin) {
        query.vin__iexact = filter.vin;
      }
      if (filter.purchase_date && filter.purchase_date.length) {
        query.purchase_date_after = filter.purchase_date[0];
        query.purchase_date_before = filter.purchase_date[1];
      }
      if (filter.arrival_date && filter.arrival_date.length) {
        query.arrival_date_after = filter.arrival_date[0];
        query.arrival_date_before = filter.arrival_date[1];
      }
      if (filter.port_of_arrival) {
        query.place_of_delivery__iexact = filter.port_of_arrival;
      }
    }
    return api.get('/broker/broker/?' + serialize(query))
  }

  const sendBrokerFile = (data) => {
    return api.post('/broker/broker/from_file/', data)
  }

  const getLocalRates = (page, page_size, sort_field, sort_order, search, filter) => {
    const query = {};
    if (page) {
      query.page = page;
    }

    if (page_size) {
      query.page_size = page_size;
    }

    if (sort_field) {
      query.ordering = ( sort_order === "descend" ? "-" : "" ) + sort_field;
    }

    if (search) {
      query.search = search;
    }

    if (filter) {
      if (filter.port) {
        query.port = filter.port;
      }
      if (filter.state) {
        query.state = filter.state;
      }
      if (filter.auction) {
        query.auction = filter.auction;
      }
    }
    return api.get('/rate/local/?' + serialize(query))
  }

  const getOceanRates = (filter) => {
    const query = {};

    if (filter) {
      if (filter.port_arrival) {
        query.port_arrival = filter.port_arrival;
      }
      if (filter.per) {
        query.per = filter.per;
      }
      if (filter.container_size) {
        query.container_size = filter.container_size;
      }
      if (filter.price_per_container_min) {
        query.price_per_container_min = filter.price_per_container_min;
      }
      if (filter.price_per_container_max) {
        query.price_per_container_max = filter.price_per_container_max;
      }
    }
    return api.get('/rate/ocean/?' + serialize(query))
  }

  const getLocalRateChoices = () => {
    return api.get('/rate/local/choices/')
  }

  const getOceanRateChoices = () => {
    return api.get('/rate/ocean/choices/')
  }

  const getCarBySearch = search => {
    return api.post('/car/search/', { search })
  }

  const getFees = dealerId => {
    const query = {};
    if (dealerId) {
      query.dealer = dealerId;
    }

    return api.get('/dealer-fee/standard/?' + serialize(query))
  }

  const addFee = data => {
    return api.post(`/dealer-fee/standard/`, data)
  }

  const updateFee = data => {
    const newData = {...data};
    delete newData.id;
    return api.put(`/dealer-fee/standard/${data.id}/`, newData)
  }

  const deleteFee = id => {
    return api.delete(`/dealer-fee/standard/${id}/`)
  }

  const getFeeNames = () => {
    return api.get('/dealer-fee/standard/names/')
  }

  const getCustomFee = vin => {
    const query = {};
    if (vin) {
      query.car = vin;
    }

    return api.get('/dealer-fee/custom/?' + serialize(query))
  }

  const addCustomFee = data => {
    return api.post(`/dealer-fee/custom/`, data)
  }

  const updateCustomFee = data => {
    const newData = {...data};
    delete newData.id;
    return api.put(`/dealer-fee/custom/${data.id}/`, newData)
  }

  const deleteCustomFee = id => {
    return api.delete(`/dealer-fee/custom/${id}/`)
  }

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    // a list of the API functions from step 2
    updateAccessToken,
    getCars,
    sendCarFile,

    getCarPorts,

    getCarInfo,
    updateCarInfo,
    getCarFinancesInfo,
    getCarImages,
    getCarsFile,
    getCarPhotoFile,
    getCarChoices,

    getPayments,
    sendPaymentFile,

    getInvoices,
    addInvoice,
    updateInvoice,
    deleteInvoice,
    getInvoiceChoices,

    getUserColumbs,

    getUsers,
    getUser,
    addUser,
    updateUser,
    deleteUser,
    getSubdealers,

    getAccounts,
    addAccount,
    updateAccount,
    deleteAccount,

    changeProfilePassword,
    changeUserPassword,

    getLanguages,

    getCalcEngines,
    getCalcAuctions,
    getCalcDelivery,
    calcDelivery,
    calcComission,
    calcCustom,

    getTitles,
    getTitleColors,
    getTitleStates,
    editTitleComment,

    getDashboardInfo,

    getBrokerData,
    sendBrokerFile,

    getLocalRates,
    getOceanRates,
    getLocalRateChoices,
    getOceanRateChoices,

    getCarBySearch,

    getFees,
    addFee,
    updateFee,
    deleteFee,
    getFeeNames,
    getCustomFee,
    addCustomFee,
    updateCustomFee,
    deleteCustomFee
  }
}

// let's return back our create method as the default.
export default create();
