import React from 'react';

const Select = (props) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.36362 5L6.18086 5C8.43159 5 9.55695 5 9.79956 4.34939C10.0422 3.69879 9.19156 2.96196 7.49036 1.4883L7.08174 1.13434C6.45734 0.593458 6.14514 0.323017 5.77224 0.323017C5.39935 0.323017 5.08715 0.593458 4.46275 1.13434L4.05413 1.4883C2.35292 2.96196 1.50232 3.69879 1.74493 4.34939C1.98753 5 3.1129 5 5.36362 5Z" fill={props.color ? props.color : "#A8ACBB"} />
    <path d="M6.18069 7L5.36345 7C3.11272 7 1.98736 7 1.74475 7.65061C1.50215 8.30121 2.35275 9.03804 4.05395 10.5117L4.46258 10.8657C5.08697 11.4065 5.39917 11.677 5.77207 11.677C6.14496 11.677 6.45716 11.4065 7.08156 10.8657L7.08156 10.8657L7.49019 10.5117C9.19139 9.03804 10.042 8.30121 9.79939 7.65061C9.55678 7 8.43142 7 6.18069 7Z" fill={props.color ? props.color : "#A8ACBB"} />
  </svg>

);

export default Select;
