import { call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import BrokerActions from '../Redux/BrokerRedux'
import AuthActions from '../Redux/AuthRedux'
import ErrorsActions from '../Redux/ErrorsRedux'

export function * getBrokerData(api, authApi, {page, page_size, sort_field, sort_order, search, filter}) {
  try {
    const response = yield call(api.getBrokerData, page, page_size, sort_field, sort_order, search, filter);
    if (response && response.ok) {
      yield put(BrokerActions.brokerSuccess(response.data))
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(BrokerActions.brokerFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getBrokerData(api, authApi, {page, page_size, sort_field, sort_order, search, filter});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(BrokerActions.brokerFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * sendBrokerFile(api, authApi, {data}) {
  try {
    const response = yield call(api.sendBrokerFile, data);
    if (response && response.ok) {
      yield put(BrokerActions.brokerSendFileSuccess(response.data))
    } else {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(BrokerActions.brokerFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * sendBrokerFile(api, authApi, {data});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(BrokerActions.brokerFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}
