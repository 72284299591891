import React from 'react';

const Processes = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity={props.opacity ? props.opacity : '0.4'}>
      <rect x="2" y="3" width="8" height="4" rx="1" stroke="#262F56" strokeWidth="1.5" />
      <rect x="2" y="17" width="15" height="4" rx="1" stroke="#262F56" strokeWidth="1.5" />
      <rect x="1" y="9" width="20" height="6" rx="2" fill={props.color ? props.color : '#262F56'} />
    </g>
  </svg>

);

export default Processes;
