import React, {useState} from 'react'
import { Layout, Button, Input } from 'antd'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import UserActions from '../../Redux/UserRedux'
import NavActions from '../../Redux/NavRedux'
import i18n from '../../i18n'
import Images from '../../Images'

const { Header, Footer } = Layout;
const { Search } = Input;

function DealersHeader(props) {
  const {users, settings, isAdmin, nav} = props;

  const role = isAdmin ? 0 : 2;

  let history = useHistory();
  function handleNavigate(path) {
    history.push(history.location.pathname + path);
  }

  function logoNavigate(path) {
    if (!isAdmin) {
      history.push(path);
    }
  }

  function handleSearch(val) {
    if (users.search === val) {
      return;
    }
    props.getUsers(1, settings.page_size, null, null, role, val);
  }

  function handleOpenMobileMenu(e) {
    props.navSetProp('isShowMobileMenu', true)
  }

  return (
    <Header>
      {
        nav.isDesktop ?
          <>
            <div className="header-title-container">{i18n.t(isAdmin ? "dealers" : "subdealers")}</div>
            <div className="header-buttons-container">
              <Search
                placeholder={i18n.t("search")}
                allowClear
                className="content-search"
                onSearch={handleSearch}
              />
              <Button className="btn-primary" type="primary" style={{ width: 'auto' }} onClick={() => handleNavigate('/create')}>
                <img className="btn-icon icon-space" src={Images.plus} alt="" />
                {i18n.t(isAdmin ? "add_dealer" : "add_subdealer")}
              </Button>
              <div style={{ width: '8px' }} />
              <Button className="btn-primary" type="primary blue" style={{ width: 'auto' }} onClick={() => handleNavigate('/settings')}>
                <img className="btn-icon" src={Images.settings} alt="" />
              </Button>
            </div>
          </>
        :
          <div className="menu-btn">
            <img className="btn-icon" src={Images.logo} alt="" onClick={() => logoNavigate('/home/dashboard')}/>
            <div className="header-title-mobile">{i18n.t(isAdmin ? "dealers" : "subdealers")}</div>
            <div className="mobile-btn-wraper" onClick={handleOpenMobileMenu}>
              <img className="btn-icon" src={Images.menu} alt="" />
            </div>
          </div>
      }
    </Header>
  )
}

function DealersFooterComp(props) {
  const {users, settings, isAdmin} = props;

  const [showSearch, setShowSearch] = useState(false);

  const role = isAdmin ? 0 : 2;

  let history = useHistory();
  function handleNavigate(path) {
    history.push(history.location.pathname + path);
  }

  function handleSearch(val) {
    if (users.search === val) {
      return;
    }
    props.getUsers(1, settings.page_size, null, null, role, val);
  }

  return (
    <Footer>
      <div className="footer-buttons-container">
        <div className="mobile-btn-wraper" onClick={() => setShowSearch(!showSearch)}>
          <img className="btn-icon" src={Images.search} alt="" />
        </div>
        {
          showSearch &&
            <div className="mobile-search">
              <Search
                placeholder={i18n.t("search")}
                allowClear
                className="content-search"
                onSearch={handleSearch}
              />
            </div>
        }
        <Button className="btn-primary" type="primary" style={{ width: '40px' }} onClick={() => handleNavigate('/create')}>
          <img className="btn-icon" src={Images.plus} alt="" />
        </Button>
        <div style={{ width: '8px' }} />
        <Button className="btn-primary" type="primary blue" style={{ width: '40px' }} onClick={() => handleNavigate('/settings')}>
          <img className="btn-icon" src={Images.settings} alt="" />
        </Button>
      </div>
    </Footer>
  )
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    settings: state.settings,
    isAdmin: state.auth.isAdmin,
    nav: state.nav,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUsers: (page, page_size, sort_field, sort_order, role, search) => dispatch(UserActions.usersRequest(page, page_size, sort_field, sort_order, role, search)),
  navSetProp: (key, value) => dispatch(NavActions.navSetProp(key, value)),
})

export const DealersFooter = connect(mapStateToProps, mapDispatchToProps)(DealersFooterComp);
export default connect(mapStateToProps, mapDispatchToProps)(DealersHeader)
