import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  carsRequest: ['page', 'page_size', 'sort_field', 'sort_order', 'search', 'filter'],
  carsSuccess: ['list'],

  carSendFileRequest: ['data'],
  carSendFileSuccess: ['uploadFile'],
  carSendFileReset: null,

  carsReset: null,
  carFilterReset: null,
  carSearchReset: null,

  carSetFocusRow: ['focusRow'],

  carPortsRequest: null,
  carPortsSuccess: ['data'],

  carInfoRequest: ['vin'],
  carInfoSuccess: ['carInfo'],

  carInfoSaveRequest: ['vin', 'data'],
  carInfoSaveSuccess: ['carInfoSaveResult'],
  carInfoSaveReset: null,

  carImagesRequest: ['vin'],
  carImagesSuccess: ['carImages'],

  carDataReset: null,

  carPhotoFileRequest: ['data'],
  carPhotoFileSuccess: null,
  carPhotoFileReset: null,

  carSearchRequest: ['search'],
  carSearchSuccess: ['carInfo'],
  carSearchFailure: ['searchError'],
  carSearchErrorReset: null,

  carsFileRequest: ['sort_field', 'sort_order', 'filter'],
  carsFileSuccess: null,
  carsFileFailure: ['fileError'],

  carFinancesRequest: null,
  carFinancesSuccess: ['carFinances'],

  carChoicesRequest: ['page_choice', 'page_size_choice', 'search_choice'],
  carChoicesSuccess: ['carChoices'],
  carChoicesReset: null,

  carFetchingReset: null

})

export const CarsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: null,
  fetching: false,

  uploadFile: null,
  uploadFileFetching: false,

  fileError: null,
  fileFetching: false,

  page: 1,
  sort_field: '',
  sort_order: 1,
  search: '',
  filter: {
    vin: '',
    btrim: '',
    purchase_date: [],
    lot_number: '',
    port_of_arrival: '',
    sub_dealer: ''
  },

  focusRow: null,

  arr_ports: null,
  dep_ports: null,

  carInfo: null,
  carInfoFetching: false,

  carInfoSaveResult: null,
  carInfoSaveFetching: false,

  carImages: null,
  carImagesFetching: false,

  searchError: null,

  carFinances: null,
  carFinancesFetching: false,

  carChoices: null,
  carChoicesFetching: false,

  page_choice: 1,
  page_size_choice: 500,
  search_choice: '',
})

/* ------------- Selectors ------------- */
export const CarsSelectors = {
  getState: (state) => state.cars
}

/* ------------- Reducers ------------- */

export const carsRequest = (state: Object, {page, page_size, sort_field, sort_order, search, filter}: Object) => {
  const newState = {
    fetching: true,
  };
  page && (newState.page = page);
  page_size && (newState.page_size = page_size);
  sort_field && (newState.sort_field = sort_field);
  sort_order && (newState.sort_order = sort_order);
  search != null && (newState.search = search);
  filter && (newState.filter = filter);
  return state.merge(newState)
}

export const carsSuccess = (state: Object, { list }: Object) => {
  return state.merge({ fetching: false, list })
}

export const carSendFileRequest = (state: Object, { data }: Object) => {
  return state.merge({ uploadFileFetching: true })
}

export const carSendFileSuccess = (state: Object, { uploadFile }: Object) => {
  return state.merge({ uploadFileFetching: false, uploadFile })
}

export const carSendFileReset = (state: Object) =>{
  return state.merge({ uploadFileFetching: false, uploadFile:null })
}

export const carsReset = (state: Object) =>{
  return INITIAL_STATE
}

export const carFilterReset = (state: Object) => {
  const newState = {
    filter: {
      vin: '',
      btrim: '',
      purchase_date: [],
      lot_number: '',
      port_of_arrival: '',
      sub_dealer: ''
    }
  };
  return state.merge(newState)
}

export const carSearchReset = (state: Object) => {
  return state.merge({ search: '' })
}

export const carSetFocusRow = (state: Object, {focusRow }) => {
  return state.merge({ focusRow })
}

export const carPortsRequest = (state: Object) => {
  return state
}

export const carPortsSuccess = (state: Object, { data }: Object) => {
  return state.merge({ arr_ports: data.arr_ports, dep_ports: data.arr_ports })
}

export const carInfoRequest = (state: Object, { vin }: Object) => {
  return state.merge({ carInfoFetching: true })
}

export const carInfoSuccess = (state: Object, { carInfo }: Object) => {
  return state.merge({ carInfoFetching: false, carInfo })
}

export const carInfoSaveRequest = (state: Object, { vin, data }: Object) => {
  return state.merge({ carInfoSaveFetching: true })
}

export const carInfoSaveSuccess = (state: Object, { carInfoSaveResult }: Object) => {
  return state.merge({ carInfoSaveFetching: false, carInfoSaveResult })
}

export const carInfoSaveReset = (state: Object) =>{
  return state.merge({ carInfoSaveFetching: false,  carInfoSaveResult:null })
}

export const carImagesRequest = (state: Object, { vin }: Object) => {
  return state.merge({ carImagesFetching: true })
}

export const carImagesSuccess = (state: Object, { carImages }: Object) => {
  return state.merge({ carImagesFetching: false, carImages })
}

export const carDataReset = (state: Object) =>{
  return state.merge({ carInfo: null, carImages: null })
}

export const carPhotoFileRequest = (state: Object, { data }: Object) => {
  return state.merge({ fileFetching: true })
};

export const carPhotoFileSuccess = (state: Object) => {
  return state.merge({ fileFetching: false })
};

export const carPhotoFileReset = (state: Object) =>{
  return state.merge({ fileFetching: false })
}

export const carSearchRequest = (state: Object, { search }: Object) => {
  return state.merge({ searchError:null})
};

export const carSearchSuccess = (state: Object, { carInfo }: Object) => {
  return state.merge({ searchError:null, carInfo })
};

export const carSearchFailure = (state: Object, { searchError }: Object) => {
  return state.merge({ searchError })
};

export const carSearchErrorReset = (state: Object) =>{
  return state.merge({ searchError: null })
}

export const carsFileRequest = (state: Object, { sort_field, sort_order, filter }: Object) => {
  return state.merge({ fileFetching: true, fileError:null})
};

export const carsFileSuccess = (state: Object) => {
  return state.merge({ fileFetching: false, fileError:null })
};

export const carsFileFailure = (state: Object, { fileError }: Object) => {
  return state.merge({ fileFetching: false,  fileError })
};

export const carFinancesRequest = (state: Object) => {
  return state.merge({ carFinancesFetching: true })
}

export const carFinancesSuccess = (state: Object, { carFinances }: Object) => {
  return state.merge({ carFinancesFetching: false, carFinances })
}

export const carChoicesRequest = (state: Object, {page_choice, page_size_choice, search_choice}: Object) => {
  const newState = {
    carChoicesFetching: true,
  };
  page_choice && (newState.page_choice = page_choice);
  page_size_choice && (newState.page_size_choice = page_size_choice);
  search_choice != null && (newState.search_choice = search_choice);
  return state.merge(newState)
}

export const carChoicesSuccess = (state: Object, { carChoices }: Object) => {
  return state.merge({ carChoicesFetching: false, carChoices })
}

export const carChoicesReset = (state: Object) => {
  return state.merge({ carChoices: null, page_choice: 1, search_choice: '' })
}

export const carFetchingReset = (state: Object) => {
  return state.merge({
    fetching:false,
    uploadFileFetching:false,
    fileFetching:false,
    carInfoFetching:false,
    carInfoSaveFetching:false,
    carImagesFetching:false,
    carFinancesFetching:false,
    carChoicesFetching:false
  })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.CARS_REQUEST]: carsRequest,
  [Types.CARS_SUCCESS]: carsSuccess,

  [Types.CAR_SEND_FILE_REQUEST]: carSendFileRequest,
  [Types.CAR_SEND_FILE_SUCCESS]: carSendFileSuccess,
  [Types.CAR_SEND_FILE_RESET]: carSendFileReset,

  [Types.CARS_RESET]: carsReset,
  [Types.CAR_FILTER_RESET]: carFilterReset,
  [Types.CAR_SEARCH_RESET]: carSearchReset,

  [Types.CAR_SET_FOCUS_ROW]: carSetFocusRow,

  [Types.CAR_PORTS_REQUEST]: carPortsRequest,
  [Types.CAR_PORTS_SUCCESS]: carPortsSuccess,

  [Types.CAR_INFO_REQUEST]: carInfoRequest,
  [Types.CAR_INFO_SUCCESS]: carInfoSuccess,

  [Types.CAR_INFO_SAVE_REQUEST]: carInfoSaveRequest,
  [Types.CAR_INFO_SAVE_SUCCESS]: carInfoSaveSuccess,
  [Types.CAR_INFO_SAVE_RESET]: carInfoSaveReset,

  [Types.CAR_IMAGES_REQUEST]: carImagesRequest,
  [Types.CAR_IMAGES_SUCCESS]: carImagesSuccess,

  [Types.CAR_DATA_RESET]: carDataReset,

  [Types.CAR_PHOTO_FILE_REQUEST]: carPhotoFileRequest,
  [Types.CAR_PHOTO_FILE_SUCCESS]: carPhotoFileSuccess,
  [Types.CAR_PHOTO_FILE_RESET]: carPhotoFileReset,

  [Types.CAR_SEARCH_REQUEST]: carSearchRequest,
  [Types.CAR_SEARCH_SUCCESS]: carSearchSuccess,
  [Types.CAR_SEARCH_FAILURE]: carSearchFailure,
  [Types.CAR_SEARCH_ERROR_RESET]: carSearchErrorReset,

  [Types.CARS_FILE_REQUEST]: carsFileRequest,
  [Types.CARS_FILE_SUCCESS]: carsFileSuccess,
  [Types.CARS_FILE_FAILURE]: carsFileFailure,

  [Types.CAR_FINANCES_REQUEST]: carFinancesRequest,
  [Types.CAR_FINANCES_SUCCESS]: carFinancesSuccess,

  [Types.CAR_CHOICES_REQUEST]: carChoicesRequest,
  [Types.CAR_CHOICES_SUCCESS]: carChoicesSuccess,
  [Types.CAR_CHOICES_RESET]: carChoicesReset,

  [Types.CAR_FETCHING_RESET]: carFetchingReset,
})
