import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {useHistory} from 'react-router-dom'
import CarsActions from '../Redux/CarsRedux'
import CarsTable from '../Components/Tables/CarsTable'
import utils from '../Utils/utils'
import i18n from '../i18n'
import Images from '../Images'

function CarsPage(props) {
  const { cars, settings, role, nav } = props;

  const [start, setStart] = useState(false);

  let history = useHistory();

  useEffect(() => {
    props.resetFilter();
    props.resetSearch();
    props.getCars(1, settings.page_size);
    if (!nav.isDesktop) {
      props.getCarFinancesInfo();
    }
    setStart(true);

    return function cleanup() {
      props.carsReset();
      setStart(false);
    }
  }, [0]);

  useEffect(() => {
    if (start) {
      props.getCars(1, settings.page_size, cars.sort_field, cars.sort_order, cars.search, cars.filter);
    }
  }, [settings.page_size])

  function handleChangePage(page, page_size, sort_field, sort_order){
    props.getCars(page, page_size, sort_field, sort_order, cars.search, cars.filter);
  }

  return (
    <div className="content-page">
      {
        (role === 0 && (cars?.carFinances?.total_balance || cars?.carFinances?.del_cars_num || cars?.carFinances?.logistics_price || cars?.carFinances?.actual_balance)) &&
        <div className="amount-block-sm">
          {
            cars?.carFinances?.total_balance &&
              <div className="flex-center">
                <img className="btn-icon" src={Images.chartLine} alt="" />
                <div>
                  {i18n.t("total_balance")}
                  <span>{` ${utils.formatValue(cars?.carFinances?.total_balance)}$`}</span>
                </div>
              </div>
          }
          {
            cars?.carFinances?.del_cars_num &&
              <div className="flex-center">
                <img className="btn-icon" src={Images.carHeader} alt="" />
                <div>
                  {i18n.t("cars_total_on_the_way")}
                  <span>{` ${cars?.carFinances?.del_cars_num}`}</span>
                </div>
              </div>
          }
          {
            cars?.carFinances?.logistics_price &&
              <div className="flex-center">
                <img className="btn-icon" src={Images.chartBar} alt="" />
                <div style={{maxWidth: 220}}>
                  {i18n.t("postponed_logistics_payments")}
                  <span>{` ${utils.formatValue(cars?.carFinances?.logistics_price)}$`}</span>
                </div>
              </div>
          }
          {
            cars?.carFinances?.actual_balance &&
              <div className="flex-center">
                <img className="btn-icon" src={Images.balance} alt="" />
                <div>
                  {i18n.t("actual_balance")}
                  <span>{` ${utils.formatValue(cars?.carFinances?.actual_balance)}$`}</span>
                </div>
              </div>
          }
        </div>
      }
      <CarsTable
        data={cars.list}
        page={cars.page}
        fetching={cars.fetching}
        pageSize={settings.page_size}
        scale={settings.tableScale}
        isHide={settings.isHide}
        history={history}
        role={role}
        onChangePage={handleChangePage}
        setFocus={props.carSetFocusRow}
      />
    </div>

  )
}

const mapStateToProps = (state) => {
  return {
    cars: state.cars,
    settings: state.settings,
    role: state.auth.role,
    nav: state.nav,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getCars: (page, page_size, sort_field, sort_order, search, filter) => dispatch(CarsActions.carsRequest(page, page_size, sort_field, sort_order, search, filter)),
  carsReset: () => dispatch(CarsActions.carsReset()),
  resetFilter: () => dispatch(CarsActions.carFilterReset()),
  resetSearch: () => dispatch(CarsActions.carSearchReset()),
  carSetFocusRow: (row) => dispatch(CarsActions.carSetFocusRow(row)),
  getCarFinancesInfo: () => dispatch(CarsActions.carFinancesRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CarsPage)
