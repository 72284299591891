import React from 'react'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import { useHistory } from 'react-router-dom'
import NavActions from '../../Redux/NavRedux'
import i18n from '../../i18n'
import Images from '../../Images'

const { Header } = Layout;

function CalculatorHeader(props) {
  const {isAdmin, nav} = props;

  let history = useHistory();
  function logoNavigate(path) {
    if (!isAdmin) {
      history.push(path);
    }
  }

  function handleOpenMobileMenu(e) {
    props.navSetProp('isShowMobileMenu', true)
  }

  return (
    <Header>
      {
        nav.isDesktop ?
          <div className="header-title-container">{i18n.t("calculator")}</div>
        :
          <div className="menu-btn">
            <img className="btn-icon" src={Images.logo} alt="" onClick={() => logoNavigate('/home/dashboard')}/>
            <div className="header-title-mobile">{i18n.t("calculator")}</div>
            <div className="mobile-btn-wraper" onClick={handleOpenMobileMenu}>
              <img className="btn-icon" src={Images.menu} alt="" />
            </div>
      </div>
      }
    </Header>
  )
}

const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    nav: state.nav
  }
}

const mapDispatchToProps = (dispatch) => ({
  navSetProp: (key, value) => dispatch(NavActions.navSetProp(key, value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CalculatorHeader)
