import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  feesRequest: ['id'],
  feesSuccess: ['list'],

  feeSaveRequest: ['data'],
  feeSaveSuccess: ['saveResult'],
  feeSaveReset: null,

  feeDeleteRequest: ['id'],
  feeDeleteSuccess: ['deleteResult'],
  feeDeleteReset: null,

  feeCustomRequest: ['vin'],
  feeCustomSuccess: ['feeCustom'],

  feeCustomSaveRequest: ['data'],
  feeCustomSaveSuccess: ['saveFeeCustomResult'],
  feeCustomSaveFailure: ['saveFeeCustomError'],
  feeCustomSaveReset: null,

  feeCustomDeleteRequest: ['id'],
  feeCustomDeleteSuccess: ['deleteFeeCustomResult'],
  feeCustomDeleteReset: null,

  feeNamesRequest: null,
  feeNamesSuccess: ['feeNameList'],

  feesReset: null,

  feeSetFocusRow: ['focusRow'],

  feeFetchingReset: null
})

export const FeesTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: null,
  fetching: false,

  saveResult: null,
  saveFetching: false,

  deleteResult: null,
  deleteFetching: false,

  focusRow: null,

  feeCustom: null,

  saveFeeCustomResult: null,

  deleteFeeCustomResult: null,

  feeNameList: null,
})

/* ------------- Selectors ------------- */
export const FeesSelectors = {
  getState: (state) => state.fees
}

/* ------------- Reducers ------------- */

export const feesRequest = (state: Object, { id }: Object) =>{
  return state.merge({ fetching: true })
}

export const feesSuccess = (state: Object, { list }: Object) => {
  return state.merge({ fetching: false, list })
}

export const feeSaveRequest = (state: Object, { data }: Object) => {
  return state.merge({ saveFetching: true })
}

export const feeSaveSuccess = (state: Object, { saveResult }: Object) => {
  return state.merge({ saveFetching: false, saveResult })
}

export const feeSaveReset = (state: Object) =>{
  return state.merge({ saveFetching: false,  saveResult:null, focusRow:null })
}

export const feeDeleteRequest = (state: Object, { id }: Object) => {
  return state.merge({ deleteFetching: true })
}

export const feeDeleteSuccess = (state: Object, { deleteResult }: Object) => {
  return state.merge({ deleteFetching: false, deleteResult })
}

export const feeDeleteReset = (state: Object) =>{
  return state.merge({ deleteFetching: false,  deleteResult:null })
}

export const feeCustomRequest = (state: Object, { vin }: Object) =>{
  return state
}

export const feeCustomSuccess = (state: Object, { feeCustom }: Object) => {
  return state.merge({ feeCustom: feeCustom[0] })
}

export const feeCustomSaveRequest = (state: Object, { data }: Object) => {
  return state
}

export const feeCustomSaveSuccess = (state: Object, { saveFeeCustomResult }: Object) => {
  return state.merge({ saveFeeCustomResult })
}

export const feeCustomSaveReset = (state: Object) =>{
  return state.merge({ saveFeeCustomResult:null })
}

export const feeCustomDeleteRequest = (state: Object, { id }: Object) => {
  return state
}

export const feeCustomDeleteSuccess = (state: Object, { deleteFeeCustomResult }: Object) => {
  return state.merge({ deleteFeeCustomResult })
}

export const feeCustomDeleteReset = (state: Object) =>{
  return state.merge({ deleteFeeCustomResult:null })
}

export const feeNamesRequest = (state: Object) =>{
  return state
}

export const feeNamesSuccess = (state: Object, { feeNameList }: Object) => {
  return state.merge({ feeNameList })
}

export const feesReset = (state: Object) =>{
  return INITIAL_STATE
}

export const feeSetFocusRow = (state: Object, {focusRow }) => {
  return state.merge({ focusRow })
}

export const feeFetchingReset = (state: Object) => {
  return state.merge({ fetching:false, saveFetching:false, deleteFetching:false })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.FEES_REQUEST]: feesRequest,
  [Types.FEES_SUCCESS]: feesSuccess,

  [Types.FEE_SAVE_REQUEST]: feeSaveRequest,
  [Types.FEE_SAVE_SUCCESS]: feeSaveSuccess,
  [Types.FEE_SAVE_RESET]: feeSaveReset,

  [Types.FEE_DELETE_REQUEST]: feeDeleteRequest,
  [Types.FEE_DELETE_SUCCESS]: feeDeleteSuccess,
  [Types.FEE_DELETE_RESET]: feeDeleteReset,

  [Types.FEE_CUSTOM_REQUEST]: feeCustomRequest,
  [Types.FEE_CUSTOM_SUCCESS]: feeCustomSuccess,

  [Types.FEE_CUSTOM_SAVE_REQUEST]: feeCustomSaveRequest,
  [Types.FEE_CUSTOM_SAVE_SUCCESS]: feeCustomSaveSuccess,
  [Types.FEE_CUSTOM_SAVE_RESET]: feeCustomSaveReset,

  [Types.FEE_CUSTOM_DELETE_REQUEST]: feeCustomDeleteRequest,
  [Types.FEE_CUSTOM_DELETE_SUCCESS]: feeCustomDeleteSuccess,
  [Types.FEE_CUSTOM_DELETE_RESET]: feeCustomDeleteReset,

  [Types.FEE_NAMES_REQUEST]: feeNamesRequest,
  [Types.FEE_NAMES_SUCCESS]: feeNamesSuccess,

  [Types.FEES_RESET]: feesReset,

  [Types.FEE_SET_FOCUS_ROW]: feeSetFocusRow,

  [Types.FEE_FETCHING_RESET]: feeFetchingReset,
})
