import { call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import InvoicesActions from '../Redux/InvoicesRedux'
import AuthActions from '../Redux/AuthRedux'
import ErrorsActions from '../Redux/ErrorsRedux'

export function * getInvoices(api, authApi, {page, page_size, sort_field, sort_order, search}) {
  try {
    const response = yield call(api.getInvoices, page, page_size, sort_field, sort_order, search);
    if (response && response.ok) {
      yield put(InvoicesActions.invoicesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(InvoicesActions.invoiceFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getInvoices(api, authApi, {page, page_size, sort_field, sort_order, search});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(InvoicesActions.invoiceFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * saveInvoice(api, authApi, { data }: Object) {
  try {
    const saveFunc = data.id ? api.updateInvoice : api.addInvoice;
    const response = yield call(saveFunc, data);
    if (response && response.ok) {
      yield put(InvoicesActions.invoiceSaveSuccess(response.data))
      yield put(InvoicesActions.invoicesRequest());

    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(InvoicesActions.invoiceFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * saveInvoice(api, authApi, { data });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(InvoicesActions.invoiceFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * deleteInvoice(api, authApi, { id }:Object) {
  try {
    const response = yield call(api.deleteInvoice, id);
    if (response && response.ok) {
      yield put(InvoicesActions.invoiceDeleteSuccess(response.data ? response.data : true))
      yield put(InvoicesActions.invoicesRequest());

    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(InvoicesActions.invoiceFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * deleteInvoice(api, authApi, { id });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(InvoicesActions.invoiceFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getInvoiceChoices(api, authApi) {
  try {
    const response = yield call(api.getInvoiceChoices);
    if (response && response.ok) {
      yield put(InvoicesActions.choicesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(InvoicesActions.invoiceFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getInvoiceChoices(api, authApi);
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(InvoicesActions.invoiceFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}
