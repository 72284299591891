import immutablePersistenceTransform from '../Services/ImmutablePersistenceTransform'
//import createElectronStorage from "redux-persist-electron-storage";

import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
// More info here:  https://shift.infinite.red/shipping-persistant-reducers-7341691232b1
const REDUX_PERSIST = {
  active: true,
  reducerVersion: '0.46',
  storeConfig: {
    key: 'wiacargo',
    storage: storage,
    // Reducer keys that you do NOT want stored to persistence here.
    blacklist: ['startup', 'nav', 'calc'],
    // Optionally, just specify the keys you DO want stored to persistence.
    // An empty array means 'don't store any reducers' -> infinitered/ignite#409
    // whitelist: [],
    transforms: [immutablePersistenceTransform]
  }
}

export default REDUX_PERSIST
