import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  titlesRequest: ['page', 'page_size', 'sort_field', 'sort_order', 'search'],
  titlesSuccess: ['list'],

  titleColorsRequest: null,
  titleColorsSuccess: ['colorList'],

  titleStatesRequest: null,
  titleStatesSuccess: ['stateList'],

  titleCommentChangeRequest: ['id', 'data'],
  titleCommentChangeSuccess: ['commentChangeResult'],
  titleCommentChangeReset: null,

  titleSetFocusRow: ['focusRow'],

  titlesReset: null,
  titleSearchReset: null,

  titleFetchingReset: null
})

export const TitlesTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: null,
  fetching: false,

  colorList: null,
  colorFetching: false,

  stateList: null,
  stateFetching: false,

  page: 1,
  sort_field: '',
  sort_order: 1,
  search: '',

  focusRow: null,
  commentChangeResult: null

})

/* ------------- Selectors ------------- */
export const TitlesSelectors = {
  getState: (state) => state.titles
}

/* ------------- Reducers ------------- */

export const titlesRequest = (state: Object, {page, page_size, sort_field, sort_order, search}: Object) => {
  const newState = {
    fetching: true,
  };
  page && (newState.page = page);
  page_size && (newState.page_size = page_size);
  sort_field && (newState.sort_field = sort_field);
  sort_order && (newState.sort_order = sort_order);
  search != null && (newState.search = search);
  return state.merge(newState)
}

export const titlesSuccess = (state: Object, { list }: Object) => {
  return state.merge({ fetching: false, list })
}

export const titleColorsRequest = (state: Object, {}: Object) => {
  return state.merge({ colorFetching:true })
}

export const titleColorsSuccess = (state: Object, { colorList }: Object) => {
  return state.merge({ colorFetching:false, colorList })
}

export const titleStatesRequest = (state: Object, {}: Object) => {
  return state.merge({ stateFetching:true })
}

export const titleStatesSuccess = (state: Object, { stateList }: Object) => {
  return state.merge({ stateFetching:false, stateList })
}

export const titleCommentChangeRequest = (state: Object, {id, data}: Object) => {
  return state.merge({ fetching:true })
}

export const titleCommentChangeSuccess = (state: Object, { commentChangeResult }: Object) => {
  return state.merge({ commentChangeResult })
}

export const titleCommentChangeReset = (state: Object, {}: Object) => {
  return state.merge({ commentChangeResult: null })
}

export const titleSetFocusRow = (state: Object, {focusRow }) => {
  return state.merge({ focusRow })
}

export const titlesReset = (state: Object) =>{
  return INITIAL_STATE
}

export const titleSearchReset = (state: Object) => {
  return state.merge({ search: '' })
}

export const titleFetchingReset = (state: Object) => {
  return state.merge({ fetching:false, colorFetching:false, stateFetching:false })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.TITLES_REQUEST]: titlesRequest,
  [Types.TITLES_SUCCESS]: titlesSuccess,

  [Types.TITLE_COLORS_REQUEST]: titleColorsRequest,
  [Types.TITLE_COLORS_SUCCESS]: titleColorsSuccess,

  [Types.TITLE_STATES_REQUEST]: titleStatesRequest,
  [Types.TITLE_STATES_SUCCESS]: titleStatesSuccess,

  [Types.TITLE_COMMENT_CHANGE_REQUEST]: titleCommentChangeRequest,
  [Types.TITLE_COMMENT_CHANGE_SUCCESS]: titleCommentChangeSuccess,
  [Types.TITLE_COMMENT_CHANGE_RESET]: titleCommentChangeReset,

  [Types.TITLE_SET_FOCUS_ROW]: titleSetFocusRow,

  [Types.TITLES_RESET]: titlesReset,
  [Types.TITLE_SEARCH_RESET]: titleSearchReset,

  [Types.TITLE_FETCHING_RESET]: titleFetchingReset,
})
