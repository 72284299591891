import React, { useEffect } from 'react'
import { Col, Row, Button, Form, Input, DatePicker, Select } from 'antd'
import moment from 'moment'
import Images from '../../Images'
import IconCustom from '../../Icon'
import i18n from '../../i18n'

import './FiltersPopupForm.css'

const { Option } = Select;
const { RangePicker } = DatePicker;

function BrokerFiltersPopupForm(props) {

  const { form, initialFilter, arr_ports, onReset, onSubmit } = props;

  const {
    getFieldDecorator,
    getFieldsValue,
    setFieldsValue,
    resetFields
  } = form;

  const arrPortList = [];
  if (arr_ports){
    for (let i = 0; i < arr_ports.length; i++) {
      arrPortList.push(<Option key={arr_ports[i]}>{arr_ports[i]}</Option>);
    }
  }

  useEffect(() => {
    let initialData = initialFilter.asMutable ? initialFilter.asMutable({ deep: true }) : { ...initialFilter };

    if (initialData.purchase_date.length) {
      initialData.purchase_date = initialData.purchase_date.map(date => moment(date))
    }
    if (initialData.arrival_date.length) {
      initialData.arrival_date = initialData.arrival_date.map(date => moment(date))
    }

    setFieldsValue(initialData);

    return () => {
    }
  }, [0])


  function handleSubmit(e) {
    e.preventDefault();

    let data = getFieldsValue();

    if (data.purchase_date && data.purchase_date.length) {
      data.purchase_date = data.purchase_date.map(el => el.format('YYYY-MM-DD'))
    }
    if (data.arrival_date && data.arrival_date.length) {
      data.arrival_date = data.arrival_date.map(el => el.format('YYYY-MM-DD'))
    }

    onSubmit(data);
  }

  function resetData() {
    resetFields();
    onReset();
  }

  return (
    <div className="filter-content">

      {<Form id="filters-popup-form" onSubmit={handleSubmit} disabled>

        <Row gutter={{xl: 16, md: 24, xs: 48}} className="flex-row">
          <Col className="gutter-row" xl={8} md={12} xs={24}>
            <Form.Item label={i18n.t("purchase_date")}>
              {getFieldDecorator('purchase_date')(
                <RangePicker
                  format="DD.MM.YYYY"
                  suffixIcon={<IconCustom type="calendar" />}
                  placeholder={[i18n.t("from"), i18n.t("to")]}
                />
              )}
            </Form.Item>
          </Col>
          <Col className="gutter-row" xl={8} md={12} xs={24}>
            <Form.Item label={i18n.t("arrival")}>
              {getFieldDecorator('arrival_date')(
                <RangePicker
                  format="DD.MM.YYYY"
                  suffixIcon={<IconCustom type="calendar" />}
                  placeholder={[i18n.t("from"), i18n.t("to")]}
                />
              )}
            </Form.Item>
          </Col>
          <Col className="gutter-row" xl={8} md={12} xs={24} >
            <Form.Item label="VIN">
              {getFieldDecorator('vin')(
                <Input />,
              )}
            </Form.Item>
          </Col>

          <Col className="gutter-row" xl={8} md={12} xs={24} >
            <Form.Item label={i18n.t("port_of_arrival")}>
              {getFieldDecorator('port_of_arrival')(
                 <Select
                    size="large"
                    showArrow={true}
                    suffixIcon={<IconCustom type="select"/>}
                  >
                  {arrPortList}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>}

      <div className="dialog-footer">
        <Button className="btn-primary gray" type="primary" style={{ width: 'auto' }} onClick={resetData}>
          <img className="btn-icon icon-space" src={Images.cross} alt="" />
          {i18n.t("filter_discard")}
        </Button>
        <Button form="user-popup-form" htmlType="submit" onClick={handleSubmit} className="btn-primary blue" type="primary" style={{ width: 'auto' }}>
          <img className="btn-icon icon-space" src={Images.filters} alt="" />
          {i18n.t("filter_use")}
        </Button>
      </div>
    </div>
  )
}

const WrappedUserEditForm = Form.create({ name: 'filters-popup-form' })(BrokerFiltersPopupForm);

export default WrappedUserEditForm
