import logo from './logo.svg'
import logoBg from './logo-bg.svg'

import infoSuccessBg from './info-success-bg.svg'
import infoSuccessIcon from './info-success-icon.svg'

import infoErrorBg from './info-error-bg.svg'
import infoErrorIcon from './info-error-icon.svg'

import searchIcon from './search-icon.svg'

import login from './login.jpg'
import loginSm from './login-sm.jpg'
import logout from './logout.svg'

import calendar from './calendar.svg'

import car from './car.svg'
import wallet from './wallet.svg'
import broker from './broker.svg'
import title from './title.svg'
import invoice from './invoice.svg'
import rate from './rate.svg'
import calculator from './calculator.svg'
import user from './user.svg'
import users from './users.svg'
import document from './document.svg'
import settings from './settings.svg'
import filters from './filters.svg'
import cross from './cross.svg'
import edit from './edit.svg'
import message from './message.svg'
import rightSlide from './right-slide.svg'
import leftSlide from './left-slide.svg'
import invoiceWhite from './invoice_white.svg'
import trash from './trash.svg'
import check from './check.svg'
import cancel from './cancel.svg'
import plus from './plus.svg'
import viber from './viber.svg'
import viberBg from './viber-bg.svg'
import telegram from './telegram.svg'
import telegramBg from './telegram-bg.svg'
import dashboard from './dashboard.png'
import iaai from './iaai.svg'
import iaaiSm from './iaai-sm.svg'
import manheim from './manheim.svg'
import manheimSm from './manheim-sm.svg'
import copart from './copart.svg'
import copartSm from './copart-sm.svg'
import download from './download.svg'
import lock from './lock.svg'
import editWhite from './edit-white.svg'
import imgDownload from './img-download.svg'
import dots from './dots.svg'
import menu from './menu.svg'
import back from './back.svg'
import userWhite from './user-white.svg'
import search from './search.svg'
import balance from './balance.svg'
import logistic from './logistic.svg'
import exportAuto from './export.svg'
import carHeader from './car-header.svg'
import chartLine from './chart-line.svg'
import chartBar from './chart-bar.svg'


const Images = {
  logo: logo,
  logoBg: logoBg,

  infoSuccessBg: infoSuccessBg,
  infoSuccessIcon: infoSuccessIcon,
  infoErrorBg: infoErrorBg,
  infoErrorIcon: infoErrorIcon,

  searchIcon: searchIcon,

  login: login,
  loginSm: loginSm,
  logout: logout,

  calendar: calendar,

  car: car,
  wallet: wallet,
  broker: broker,
  title: title,
  invoice: invoice,
  rate: rate,
  calculator: calculator,
  user: user,
  users: users,
  document: document,
  settings: settings,
  filters: filters,
  cross: cross,
  edit: edit,
  message: message,
  rightSlide: rightSlide,
  leftSlide: leftSlide,
  invoiceWhite: invoiceWhite,
  trash: trash,
  check: check,
  cancel: cancel,
  plus: plus,
  viber: viber,
  viberBg: viberBg,
  telegram: telegram,
  telegramBg: telegramBg,
  dashboard: dashboard,
  iaai: iaai,
  iaaiSm: iaaiSm,
  manheim: manheim,
  manheimSm: manheimSm,
  copart: copart,
  copartSm: copartSm,
  download: download,
  lock: lock,
  editWhite: editWhite,
  imgDownload: imgDownload,
  dots: dots,
  menu: menu,
  back: back,
  userWhite: userWhite,
  search: search,
  balance: balance,
  logistic: logistic,
  exportAuto: exportAuto,
  carHeader: carHeader,
  chartLine: chartLine,
  chartBar: chartBar
}

export default Images
