import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PaymentsActions from '../Redux/PaymentsRedux'
import PaymentsTable from '../Components/Tables/PaymentsTable'

function PaymentsPage(props) {
  const { payments, settings } = props;

  const [start, setStart] = useState(false);

  useEffect(() => {
    props.resetFilter();
    props.resetSearch();
    props.getPayments(1, settings.page_size);
    setStart(true);

    return function cleanup() {
      props.paymentsReset();
      setStart(false);
    }
  }, [0]);

  useEffect(() => {
    if (start) {
      props.getPayments(1, settings.page_size, payments.sort_field, payments.sort_order, payments.search, payments.filter);
    }
  }, [settings.page_size])

  function handleChangePage(page, page_size, sort_field, sort_order){
    props.getPayments(page, page_size, sort_field, sort_order, payments.search, payments.filter);
  }

  return (
    <div className="content-page">
      <PaymentsTable
        data={payments.list}
        page={payments.page}
        fetching={payments.fetching}
        pageSize={settings.page_size}
        scale={settings.tableScale}
        isHide={settings.isHide}
        onChangePage={handleChangePage}
      />
    </div>

  )
}

const mapStateToProps = (state) => {
  return {
    payments: state.payments,
    settings: state.settings
  }
}

const mapDispatchToProps = (dispatch) => ({
  getPayments: (page, page_size, sort_field, sort_order, search, filter) => dispatch(PaymentsActions.paymentsRequest(page, page_size, sort_field, sort_order, search, filter)),
  paymentsReset: () => dispatch(PaymentsActions.paymentsReset()),
  resetFilter: () => dispatch(PaymentsActions.paymentFilterReset()),
  resetSearch: () => dispatch(PaymentsActions.paymentSearchReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsPage)
