import { put, select } from 'redux-saga/effects'
import StartupActions from '../Redux/StartupRedux'
import { AuthSelectors } from '../Redux/AuthRedux'
import AuthApi from '../Services/AuthApi';
import { push } from 'connected-react-router'
import Config from '../Config'
import { getLang } from '../Redux/LangRedux'

import i18n from '../i18n'

// process STARTUP actions
export function * startup (action) {

  if (Config.withOutApi) {
    /*yield delay(1000);
    if(document.location.pathname.indexOf('/admin')>=0){
      yield put(replace('/admin/myprocesses'))
    } else if(document.location.pathname.indexOf('/home')>=0){
      yield put(replace('/home/dashboard'))
    }else if(document.location.pathname.indexOf("/")===0){
      yield put(replace('/home/dashboard'))
    }*/
  } else {

  }
  const userInfo = yield select(AuthSelectors.getUserInfo);
  const accessToken = yield select(AuthSelectors.getAccessToken);
  const refreshToken = yield select(AuthSelectors.getRefreshToken);

  const lang = yield select(getLang);

  i18n.changeLanguage(lang)
  //const lang = yield select(getLang);



  if (accessToken && userInfo) {
    AuthApi.updateAccessToken(accessToken, refreshToken);
    var pathname = document.location.pathname;
    if (pathname.indexOf('/home')>=0) {
      yield put(push(pathname))
    } else {
      yield put(push('/home/cars'))
    }
  } else {
    yield put(push('/login'))
  }

  yield put(StartupActions.startupSuccess())

}
