import React from 'react'
import { Collapse, Row, Col, Popconfirm } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import i18n from '../i18n'
import Images from '../Images'

import './FeeTemplates.css'

const { Panel } = Collapse;

function FeeTextNode (props) {
  return (
    <span className="fee-text-node">{props.text}</span>
  )
}

function FeeTemplates(props) {
  const { data, history, isAdmin } = props;

  function handleNavigate(path) {
    history.push(path);
  }

  function genExtra(item) {
    return (
      !isAdmin &&
      <div className="collapse-control" onClick={e => e.stopPropagation()}>
        <img className="btn-icon" src={Images.edit} alt="" onClick={() => editTemplate(item)} />
        <div style={{ width: '24px' }} />
        <Popconfirm
          title={i18n.t("template_delete_confirm")}
          onConfirm={() => removeTemplate(item.id)}
          okText={i18n.t("yes")}
          cancelText={i18n.t("no")}
        >
          <img className="btn-icon" src={Images.trash} alt="" />
        </Popconfirm>
      </div>
    )
  }

  function editTemplate(item) {
    props.setFocusRow(item);
    handleNavigate('/home/profile/edit-fee-template');
  }

  function removeTemplate(id) {
    props.onDelete(id);
  }

  return (
    data &&
    <div>
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) =>
          isActive ?
            <PlusOutlined style={{ color: '#262F56' }} />
            :
            <MinusOutlined style={{ color: '#262F56' }} />
        }
        className="site-collapse-custom-collapse"
      >
        {
          data.results && data.results.map(el => (
            <Panel header={<FeeTextNode text={el.name} />} key={el.id} className="site-collapse-custom-panel" extra={genExtra(el)}>
              <Row gutter={[16, 30]}>
                <Col className="gutter-row rate-title" md={{span: 8, offset: 6}} xs={{span: 14, offset: 2}}>
                  <div className="flex-block">
                    <span>{i18n.t("auto_sbor")}</span>
                    <span className="dots"></span>
                  </div>
                </Col>
                <Col className="gutter-row rate-value" md={8} xs={8}>
                  {el.c_price_sbor}
                </Col>
              </Row>
              <Row gutter={[16, 30]}>
                <Col className="gutter-row rate-title" md={{span: 8, offset: 6}} xs={{span: 14, offset: 2}}>
                  <div className="flex-block">
                    <span>{i18n.t("parking")}</span>
                    <span className="dots"></span>
                  </div>
                </Col>
                <Col className="gutter-row rate-value" md={8} xs={8}>
                  {el.c_parking}
                </Col>
              </Row>
              <Row gutter={[16, 30]}>
                <Col className="gutter-row rate-title" md={{span: 8, offset: 6}} xs={{span: 14, offset: 2}}>
                  <div className="flex-block">
                    <span>{i18n.t("add_payment")}</span>
                    <span className="dots"></span>
                  </div>
                </Col>
                <Col className="gutter-row rate-value" md={8} xs={8}>
                  {el.c_add_payment}
                </Col>
              </Row>
              <Row gutter={[16, 30]}>
                <Col className="gutter-row rate-title" md={{span: 8, offset: 6}} xs={{span: 14, offset: 2}}>
                  <div className="flex-block">
                    <span>{i18n.t("local_transp")}</span>
                    <span className="dots"></span>
                  </div>
                </Col>
                <Col className="gutter-row rate-value" md={8} xs={8}>
                  {el.c_local_transp}
                </Col>
              </Row>
              <Row gutter={[16, 30]}>
                <Col className="gutter-row rate-title" md={{span: 8, offset: 6}} xs={{span: 14, offset: 2}}>
                  <div className="flex-block">
                    <span>Sales_tax</span>
                    <span className="dots"></span>
                  </div>
                </Col>
                <Col className="gutter-row rate-value" md={8} xs={8}>
                  {el.c_fine_memo}
                </Col>
              </Row>
              <Row gutter={[16, 30]}>
                <Col className="gutter-row rate-title" md={{span: 8, offset: 6}} xs={{span: 14, offset: 2}}>
                  <div className="flex-block">
                    <span>{i18n.t("insurance")}</span>
                    <span className="dots"></span>
                  </div>
                </Col>
                <Col className="gutter-row rate-value" md={8} xs={8}>
                  {el.c_insurance}
                </Col>
              </Row>
              <Row gutter={[16, 30]}>
                <Col className="gutter-row rate-title" md={{span: 8, offset: 6}} xs={{span: 14, offset: 2}}>
                  <div className="flex-block">
                    <span>{i18n.t("ocean_transp")}</span>
                    <span className="dots"></span>
                  </div>
                </Col>
                <Col className="gutter-row rate-value" md={8} xs={8}>
                  {el.c_ocean_transp}
                </Col>
              </Row>
              <Row gutter={[16, 30]}>
                <Col className="gutter-row rate-title" md={{span: 8, offset: 6}} xs={{span: 14, offset: 2}}>
                  <div className="flex-block">
                    <span>{i18n.t("doc_tranfered")}</span>
                    <span className="dots"></span>
                  </div>
                </Col>
                <Col className="gutter-row rate-value" md={8} xs={8}>
                  {el.c_doc_tranfered}
                </Col>
              </Row>
              <Row gutter={[16, 30]}>
                <Col className="gutter-row rate-title" md={{span: 8, offset: 6}} xs={{span: 14, offset: 2}}>
                  <div className="flex-block">
                    <span>{i18n.t("office_fee")}</span>
                    <span className="dots"></span>
                  </div>
                </Col>
                <Col className="gutter-row rate-value" md={8} xs={8}>
                  {el.c_office_fee}
                </Col>
              </Row>
              <Row gutter={[16, 30]}>
                <Col className="gutter-row rate-title" md={{span: 8, offset: 6}} xs={{span: 14, offset: 2}}>
                  <div className="flex-block">
                    <span>{i18n.t("storage")}</span>
                    <span className="dots"></span>
                  </div>
                </Col>
                <Col className="gutter-row rate-value" md={8} xs={8}>
                  {el.c_storage}
                </Col>
              </Row>
            </Panel>
          ))
        }
      </Collapse>
    </div>
  )
}

export default FeeTemplates
