import React, { useState, useEffect } from 'react'
import { Layout, Button, Input, Select } from 'antd'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import TitlesActions from '../../Redux/TitlesRedux'
import NavActions from '../../Redux/NavRedux'
import i18n from '../../i18n'
import Images from '../../Images'
import IconCustom from '../../Icon'

const { Header, Footer } = Layout;
const { Search } = Input;
const { Option } = Select;

function TitlesHeader(props) {
  const { titles, states, page_size, isAdmin, nav, isDesktop } = props;

  const [stateList, setStateList] = useState(null);

  let history = useHistory();

  useEffect(() => {
    props.getTitleStates();
  }, [0]);

  useEffect(() => {
    if (states) {
      const arr = [];
      for (let i = 0; i < states.length; i++) {
        arr.push(<Option key={states[i]}>{states[i]}</Option>);
      }
      setStateList(arr);
    }
  }, [states]);

  function handleNavigate(path) {
    history.push(history.location.pathname + path);
  }

  function logoNavigate(path) {
    if (!isAdmin) {
      history.push(path);
    }
  }

  function handleSearch(val) {
    if (titles.search === val) {
      return;
    }
    props.getTitles(1, page_size, null, null, val);
  }

  function handleOpenMobileMenu(e) {
    props.navSetProp('isShowMobileMenu', true)
  }

  function changeState(val) {
    props.getTitles(1, page_size, null, null, val);
  }

  return (
    <Header className="rates-header">
      {
        nav.isDesktop ?
          <>
            <div className="header-title-container">{i18n.t("titles")}</div>
            <div className="header-select">
              <Select
                size="large"
                showArrow={true}
                showSearch
                dropdownClassName="auction-location"
                optionFilterProp="children"
                allowClear
                suffixIcon={<IconCustom type="select" />}
                placeholder={i18n.t("state")}
                onChange={changeState}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {stateList}
              </Select>
            </div>
            <div className="header-buttons-container">
              <Search
                placeholder={i18n.t("search")}
                allowClear
                className="content-search"
                onSearch={handleSearch}
              />
              <Button className="btn-primary" type="primary blue" style={{ width: 'auto' }} onClick={() => handleNavigate('/settings')}>
                <img className="btn-icon" src={Images.settings} alt="" />
              </Button>
            </div>
          </>
          :
          <>
            <div className="menu-btn">
              <img className="btn-icon" src={Images.logo} alt="" onClick={() => logoNavigate('/home/dashboard')} />
              <div className="header-title-mobile">{i18n.t("titles")}</div>
              <div className="mobile-btn-wraper" onClick={handleOpenMobileMenu}>
                <img className="btn-icon" src={Images.menu} alt="" />
              </div>
            </div>
            <div className="header-select-block">
              <div className="header-select" style={{marginTop: !isDesktop && 40}}>
                <Select
                  size="large"
                  showArrow={true}
                  showSearch
                  dropdownClassName="auction-location"
                  optionFilterProp="children"
                  allowClear
                  suffixIcon={<IconCustom type="select" />}
                  placeholder={i18n.t("state")}
                  onChange={changeState}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {stateList}
                </Select>
              </div>
            </div>
          </>
      }
    </Header>
  )
}

function TitlesFooterComp(props) {
  const { titles, page_size } = props;

  const [showSearch, setShowSearch] = useState(false);

  let history = useHistory();
  function handleNavigate(path) {
    history.push(history.location.pathname + path);
  }

  function handleSearch(val) {
    if (titles.search === val) {
      return;
    }
    props.getTitles(1, page_size, null, null, val);
  }

  return (
    <Footer>
      <div className="footer-buttons-container">
        <div className="mobile-btn-wraper" onClick={() => setShowSearch(!showSearch)}>
          <img className="btn-icon" src={Images.search} alt="" />
        </div>
        {
          showSearch &&
          <div className="mobile-search">
            <Search
              placeholder={i18n.t("search")}
              allowClear
              className="content-search"
              onSearch={handleSearch}
            />
          </div>
        }
        <Button className="btn-primary" type="primary blue" style={{ width: '40px' }} onClick={() => handleNavigate('/settings')}>
          <img className="btn-icon" src={Images.settings} alt="" />
        </Button>
      </div>
    </Footer>
  )
}

const mapStateToProps = (state) => {
  return {
    titles: state.titles,
    states: state.titles.stateList,
    page_size: state.settings.page_size,
    isAdmin: state.auth.isAdmin,
    nav: state.nav,
    isDesktop: state.nav.isDesktop
  }
}

const mapDispatchToProps = (dispatch) => ({
  getTitles: (page, page_size, sort_field, sort_order, search) => dispatch(TitlesActions.titlesRequest(page, page_size, sort_field, sort_order, search)),
  getTitleStates: () => dispatch(TitlesActions.titleStatesRequest()),
  navSetProp: (key, value) => dispatch(NavActions.navSetProp(key, value)),
})

export const TitlesFooter = connect(mapStateToProps, mapDispatchToProps)(TitlesFooterComp)
export default connect(mapStateToProps, mapDispatchToProps)(TitlesHeader)
