import React, { useState, useEffect } from 'react'
import { Layout, Card, message, Input, Button, Spin } from 'antd'
import AuthActions from '../Redux/AuthRedux'
import CarsActions from '../Redux/CarsRedux'
import { connect } from 'react-redux'
import { push, goBack } from "connected-react-router"
import AutoCardPage from './AutoCardPage'
import LoginForm from '../Components/Forms/LoginForm'
import Images from '../Images'
import i18n from '../i18n'

const { Search } = Input;

function LoginPage(props) {

  const {error, fetching, carInfo, searchError, fileFetching, isDesktop } = props;

  const [searchActive, setSearchActive] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  function handleSubmit(data) {
    props.signIn(data.login, data.password);
  }

  useEffect(() => {
    props.carsReset();
  }, [0])

  useEffect(() => {
    if (error && error.message) {
      for (let i in error.message) {
        message.error(`${i}: ${error.message[i]}`, 5);
      }
      props.resetError();
    }
  }, [error])

  useEffect(() => {
    if (searchError && searchError.message) {
      for (let i in searchError.message) {
        message.error(searchError.message[i], 5);
      }
      props.resetSearchError();
    }
  }, [searchError])

  useEffect(() => {
    if (carInfo) {
      setSearchActive(true);
    } else {
      setSearchActive(false);
    }
  }, [carInfo])

  function handleSearch(val) {
    if (val) {
      props.searchCar(val);
    } else {
      props.carsReset();
    }
  }

  function backToLogin() {
    setShowSearch(false);
    setSearchActive(false);
  }

  return (
    <Layout>
      <Spin size="large" spinning={fileFetching}>
      <div className="login-row" style={{ background: `center / cover url(${isDesktop ? Images.login : Images.loginSm})`, backgroundSize: 'cover' }}>
        <div className="login-logo-wrap" style={{background: searchActive && !isDesktop && '#EAEDF3'}}>
          <div className="login-logo">
            <img src={Images.logoBg} alt=""/>
            {
              isDesktop ?
                <Search
                  placeholder={i18n.t("login_search")}
                  allowClear
                  className="login-search"
                  onSearch={handleSearch}
                />
              :
                !searchActive ?
                  (showSearch ?
                    <Search
                      placeholder={i18n.t("login_search")}
                      allowClear
                      className="login-search"
                      onSearch={handleSearch}
                    />
                  :
                    <div className="mobile-btn-wraper" onClick={() => setShowSearch(true)}>
                      <img className="btn-icon" src={Images.search} alt="" />
                    </div>)
                :
                  <div className="car-desc">{carInfo && carInfo.description}</div>
            }
            {
              searchActive && isDesktop &&
                <Button type="primary" className="btn-primary login-enter" onClick={backToLogin}>
                  {i18n.t("submit")}
                </Button>
            }
            {
              searchActive && !isDesktop &&
                <Button type="primary" className="btn-primary login-enter" onClick={backToLogin}>
                  <img src={Images.userWhite} alt=""/>
                </Button>
            }
          </div>
        </div>
        <div className="login-col">
          <Card className="login-card">
            <div className="login-title">{i18n.t("entrance")}</div>
            <LoginForm useSuspense={false} isLoginFetching={fetching} onSubmit={handleSubmit} />
          </Card>
        </div>
        {
          searchActive &&
            <div className="search-info">
              <AutoCardPage />
            </div>
        }
      </div>
      </Spin>
    </Layout>
  )
}


const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    fetching: state.auth.fetching,
    carInfo: state.cars.carInfo,
    fileFetching: state.cars.fileFetching,
    searchError: state.cars.searchError,
    isDesktop: state.nav.isDesktop,
  }
}

// wraps dispatch to create nicer functions to call within our component
const mapDispatchToProps = (dispatch) => ({
  navPush: (path, state) => dispatch(push(path, state)),
  navGoBack: () => dispatch(goBack()),
  signIn: (email, password) => dispatch(AuthActions.signInRequest(email, password)),
  resetError: () => dispatch(AuthActions.signInReset()),
  searchCar: (data) => dispatch(CarsActions.carSearchRequest(data)),
  carsReset: () => dispatch(CarsActions.carsReset()),
  resetSearchError: () => dispatch(CarsActions.carSearchErrorReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
