import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  dealersReset: null,

  userColumbsRequest: null,
  userColumbsSuccess: ['data'],

  dealerFetchingReset: null
})

export const DealersTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  columbs: null,
  columbsFetching: false
})

/* ------------- Selectors ------------- */
export const DealersSelectors = {
  getState: (state) => state.dealers
}

/* ------------- Reducers ------------- */

export const userColumbsRequest = (state: Object, { }: Object) => {
  return state.merge({ columbsFetching:true })
}

export const userColumbsSuccess = (state: Object, { data }: Object) => {
  return state.merge({ columbsFetching:false, columbs: data })
}

export const dealersReset = (state: Object) =>{
  return INITIAL_STATE
}

export const dealerFetchingReset = (state: Object) =>{
  return state.merge({ columbsFetching:false })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_COLUMBS_REQUEST]: userColumbsRequest,
  [Types.USER_COLUMBS_SUCCESS]: userColumbsSuccess,

  [Types.DEALERS_RESET]: dealersReset,

  [Types.DEALER_FETCHING_RESET]: dealerFetchingReset,
})
