import React, { useEffect} from 'react'
import { connect } from 'react-redux'
import {useHistory} from 'react-router-dom'
import { message } from 'antd'
import CarsActions from '../../Redux/CarsRedux'
import TitlesActions from '../../Redux/TitlesRedux'
import EditCommentPopupForm from '../../Components/Forms/EditCommentPopupForm'
import i18n from '../../i18n'

function EditCommentPopupPage (props) {

  const { cars, page_size, carsResult, titles, titlesResult, location } = props;

  let history = useHistory();

  const isCars = location.pathname.includes('cars');

  function handleClose() {
    if (isCars) {
      props.carSetFocusRow(null);
      props.dataReset();
      history.push('/home/cars');
    } else {
      props.titleSetFocusRow(null);
      history.push('/home/titles');
    }
  }

  function handleSubmit(data) {
    if (isCars) {
      data.vin = cars.focusRow.vin;
      props.changeData(cars.focusRow.vin, data);
    } else {
      props.editTitleComment(titles.focusRow?.id, data)
    }
  }

  useEffect(()=>{
    isCars && props.dataReset();
    return () => {
      isCars && props.dataReset();
    }
  }, [0]);

  useEffect(()=>{
    if(carsResult){
      message.success(i18n.t("successful_comment_save"), 2);
      props.getCars(cars.page, page_size, cars.sort_field, cars.sort_order, cars.search, cars.filter);
      handleClose();
    }
  }, [carsResult]);

  useEffect(()=>{
    if(titlesResult){
      message.success(i18n.t("successful_comment_save"), 2);
      props.getTitles(titles.page, page_size, titles.sort_field, titles.sort_order, titles.search);
      handleClose();
    }
  }, [titlesResult]);

  return (
      <div className="popup-dialog">
        <EditCommentPopupForm
          data={isCars ? cars.focusRow : titles.focusRow}
          onClose={handleClose}
          onSubmit={handleSubmit}
          />
      </div>
  )
}

const mapStateToProps = (state) => {
  return {
    cars: state.cars,
    page_size: state.settings.page_size,
    carsResult: state.cars.carInfoSaveResult,
    titles: state.titles,
    titlesResult: state.titles.commentChangeResult,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getCars: (page, page_size, sort_field, sort_order, search, filter) => dispatch(CarsActions.carsRequest(page, page_size, sort_field, sort_order, search, filter)),
  changeData: (vin, data) => dispatch(CarsActions.carInfoSaveRequest(vin, data)),
  dataReset: () => dispatch(CarsActions.carInfoSaveReset()),
  carSetFocusRow: (row) => dispatch(CarsActions.carSetFocusRow(row)),

  getTitles: (page, page_size, sort_field, sort_order, search) => dispatch(TitlesActions.titlesRequest(page, page_size, sort_field, sort_order, search)),
  titleSetFocusRow: (row) => dispatch(TitlesActions.titleSetFocusRow(row)),
  editTitleComment: (id, comment) => dispatch(TitlesActions.titleCommentChangeRequest(id, comment)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditCommentPopupPage);
