import React from 'react';

const Users = (props) => (
  <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity={props.opacity ? props.opacity : '0.4'}>
      <path d="M15 1.41579C15.4634 1.14965 15.9858 1 16.5385 1C18.4502 1 20 2.79086 20 5C20 7.20914 18.4502 9 16.5385 9C15.9858 9 15.4634 8.85035 15 8.58421" stroke="#262F56" strokeWidth="1.8" strokeLinecap="round" />
      <path d="M18 13C22.5 13 24 15.3175 24 17C24 18.6825 22 18.4579 20.1875 18.4579" stroke="#262F56" strokeWidth="1.8" strokeLinecap="round" />
      <ellipse cx="9" cy="5" rx="4.5" ry="5" fill="#262F56" />
      <path d="M18 17.5C18 20.5376 13.4183 19.5 9 19.5C4.58172 19.5 0 20.5376 0 17.5C0 14.4624 4.58172 12 9 12C13.4183 12 18 14.4624 18 17.5Z" fill={props.color ? props.color : '#262F56'} />
    </g>
  </svg>
);

export default Users;
