import React from 'react';

const Rate = (props) => (
  <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity={props.opacity ? props.opacity : '0.4'}>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.4566 0C4.95954 0 4.5566 0.402944 4.5566 0.9C4.5566 1.39706 4.95954 1.8 5.4566 1.8H15.2094C15.7065 1.8 16.1094 1.39706 16.1094 0.9C16.1094 0.402944 15.7065 0 15.2094 0H5.4566ZM3.40921 4.34216C3.40921 3.84511 3.81215 3.44216 4.30921 3.44216H17.5042C18.0012 3.44216 18.4042 3.84511 18.4042 4.34216C18.4042 4.83922 18.0012 5.24217 17.5042 5.24217H4.30921C3.81215 5.24217 3.40921 4.83922 3.40921 4.34216ZM0.0302891 7.87944L1.6357 14.3011C1.85828 15.1914 2.65825 15.816 3.57598 15.816H18.1168C18.8063 15.816 19.4473 15.4608 19.8128 14.876L24.0059 8.16691C24.4222 7.50086 23.9434 6.63691 23.158 6.63691H1.00043C0.349861 6.63691 -0.127498 7.2483 0.0302891 7.87944ZM18.0779 11.2265C18.7116 11.2265 19.2253 10.7128 19.2253 10.0791C19.2253 9.44539 18.7116 8.93168 18.0779 8.93168C17.4442 8.93168 16.9305 9.44539 16.9305 10.0791C16.9305 10.7128 17.4442 11.2265 18.0779 11.2265Z" fill={props.color ? props.color : "#262F56"} />
    </g>
  </svg>

);

export default Rate;
