import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import InvoicesActions from '../Redux/InvoicesRedux'
import CarsActions from '../Redux/CarsRedux'
import InvoicesTable from '../Components/Tables/InvoicesTable'

function InvoicesPage(props) {
  const { invoices, settings } = props;

  const [start, setStart] = useState(false);

  useEffect(() => {
    props.resetSearch();
    props.resetChoices();
    props.resetCarChoices();
    props.getInvoices(1, settings.page_size);
    props.getChoices();
    setStart(true);
    return function cleanup() {
      props.invoicesReset();
      setStart(false);
    }
  }, [0]);

  useEffect(() => {
    if (start) {
      props.getInvoices(1, settings.page_size, invoices.sort_field, invoices.sort_order, invoices.search);
    }
  }, [settings.page_size])

  function handleChangePage(page, page_size, sort_field, sort_order){
    props.getInvoices(page, page_size, sort_field, sort_order, invoices.search);
  }

  function handleDelete(id) {
    props.deleteInvoice(id);
  }

  return (
    <div className="content-page">
      <InvoicesTable
        data={invoices.list}
        page={invoices.page}
        fetching={invoices.fetching}
        pageSize={settings.page_size}
        scale={settings.tableScale}
        isHide={settings.isHide}
        onChangePage={handleChangePage}
        onDelete={handleDelete}
      />
    </div>

  )
}

const mapStateToProps = (state) => {
  return {
    invoices: state.invoices,
    settings: state.settings
  }
}

const mapDispatchToProps = (dispatch) => ({
  getInvoices: (page, page_size, sort_field, sort_order, search) => dispatch(InvoicesActions.invoicesRequest(page, page_size, sort_field, sort_order, search)),
  invoicesReset: () => dispatch(InvoicesActions.invoicesReset()),
  resetSearch: () => dispatch(InvoicesActions.invoiceSearchReset()),
  deleteInvoice: (id) => dispatch(InvoicesActions.invoiceDeleteRequest(id)),
  getChoices: () => dispatch(InvoicesActions.choicesRequest()),
  resetChoices: () => dispatch(InvoicesActions.choicesReset()),

  resetCarChoices: () => dispatch(CarsActions.carChoicesReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesPage)
