import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Tabs } from 'antd'
import ItemsCarousel from 'react-items-carousel'
import CarsActions from '../Redux/CarsRedux'
import PaymentsActions from '../Redux/PaymentsRedux'
import FeesActions from '../Redux/FeesRedux'
import AutoInfo from '../Components/AutoInfo'
import AutoFinance from '../Components/AutoFinance'
import AutoTrans from '../Components/AutoTrans'
import AutoPayment from '../Components/AutoPayment'
import AutoSubdealer from '../Components/AutoSubdealer'
import CardGallery from '../Components/CardGallery'
import Images from '../Images'
import i18n from '../i18n'

import './AutoCardPage.css'

const { TabPane } = Tabs;

function AutoCardPage(props) {
  const {carInfo, carImages, payments, page, page_size, tableScale, isHide, match, user, role, feeCustom, feeCustomDeleteResult, lang, isDesktop } = props;

  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [images, setImages] = useState(null);
  const [navData, setNavData] = useState(null);
  const [activeNavDataIndex, setActiveNavDataIndex] = useState(null);
  const [showGallery, setShowGallery] = useState(false);

  const initialData = [
    {
      value: 'common',
      text: i18n.t("common"),
      active: true
    },
    {
      value: 'AuctionPhotos',
      text: i18n.t("auction"),
      active: false
    },
    {
      value: 'TerminalPhotos',
      text: i18n.t("terminal"),
      active: false
    },
    {
      value: 'LoadingPhotos',
      text: i18n.t("shipment"),
      active: false
    },
    {
      value: 'UnloadingPhotos',
      text: i18n.t("unloading"),
      active: false
    }
  ]

  let history = useHistory();
  function handleNavigate(path) {
    history.push(history.location.pathname + path);
  }

  function editTemplate() {
    props.feeSetFocusRow(feeCustom);
    history.push(history.location.pathname + '/edit-fee-template');
  }


  useEffect(() => {
    props.resetCarFiles();
    if (user) {
      props.resetCarData();
      props.getCarInfo(match.params.vin);
      props.getCarImages(match.params.vin);
      props.getCarPayments(null, null, null, null, null, {vin: match.params.vin});
      if (role === 0) {
        props.getCustomFee(match.params.vin);
      }
    }

    return function cleanup() {
      props.paymentsReset();
      initialData.forEach((el, i) => {
        if (i === 0) {
          el.active = true;
        } else {
          el.active = false;
        }
      })
    }
  }, [0]);

  useEffect(() => {
    if (activeNavDataIndex != null) {
      const data = [...initialData];
      data.forEach(el => el.active = false);
      data[activeNavDataIndex].active = true;
      setNavData(data);
    }
  }, [lang])

  useEffect(() => {
    if (carImages && carImages.results) {
      const data = carImages.results.asMutable ? carImages.results.asMutable({ deep: true }) : [...carImages.results];
      setImages(data);
      setNavData([...initialData]);
    }
  }, [carImages]);

  useEffect(()=>{
    if(feeCustomDeleteResult){
      props.getCustomFee(match.params.vin);
      props.feeCustomDeleteReset();
    }
  }, [feeCustomDeleteResult]);

  function handleChangePage(page, page_size, sort_field, sort_order){
    props.getCarPayments(page, page_size, sort_field, sort_order, null, {vin: match.params.vin});
  }

  function downloadImages(val) {
    const data = {
      vin: carInfo.vin
    };
    if (val !== 'common') {
      data.photo_type = val;
    }
    props.downloadFiles(data);
  }

  function changeActive(value, index) {
    if (navData[index].active) {
      return;
    }
    const data = [...navData];
    data.forEach(el => {
      el.active = el.value === value;
    });
    setNavData(data);
    setActiveNavDataIndex(index);
    let imgData = carImages.results.asMutable ? carImages.results.asMutable({ deep: true }) : [...carImages.results];
    if (data[index].value !== 'common') {
      imgData = imgData.filter(img => img.photo_type === data[index].value);
    }
    setImages(imgData);
  }

  function clickImage(i) {
    setShowGallery(!showGallery);
    setTimeout(() => {
      let selected = document.getElementsByClassName('ReactGridGallery_tile-viewport')[i];
      if (selected) {
        selected.click();
      }
    })
  }

  function handleDelete() {
    const feeId = feeCustom.id;
    if (feeId) {
      props.unassignSubdealer(feeId);
    }
  }

  return (
    <>
      <CardGallery
        images={images}
        isShow={showGallery}
      />
      <div className="content-page no-table" style={{padding: !user && '0', height: !user && '100%'}}>
        <div className="auto-card-page">
          <div className="carousel-block">
            {
              images && images.length ?
                <ItemsCarousel
                  requestToChangeActive={setActiveItemIndex}
                  activeItemIndex={activeItemIndex}
                  numberOfCards={isDesktop ? 3 : 1.5}
                  gutter={isDesktop ? 3 : 2}
                  infiniteLoop={true}
                  leftChevron={isDesktop && <img src={Images.leftSlide} alt="" />}
                  rightChevron={isDesktop && <img src={Images.rightSlide} alt="" />}
                  chevronWidth={50}
                >
                  {
                    images.map((image, i) => (
                      <div key={image.url} className="carousel-item" style={{ backgroundImage: 'url(' + image.url + ')' }} onClick={() => clickImage(i)}></div>
                    ))
                  }
                </ItemsCarousel>
              :
                <div className="no-data-block">{i18n.t("no_images")}</div>
            }
            {
              carImages && carImages.results && carImages.results.length ?
                <div className="photo-filter-block">
                  {
                    navData && navData.length && navData.map((item, i) => (
                      <div className={"photo-filter-item" + (item.active?" active":'')} key={item.value}>
                        <span onClick={() => changeActive(item.value, i)}>{item.text}</span>
                        {
                          item.active && images.length ?
                            <div className="img-download" onClick={() => downloadImages(item.value)}>
                              <img src={Images.imgDownload} alt="" />
                            </div>
                          :
                            null
                        }
                      </div>
                    ))
                  }
                </div>
              :
                null
            }
          </div>

          {
            !user && isDesktop &&
              <div className="car-title">{carInfo && carInfo.description}</div>
          }

          <Tabs type="card">
            <TabPane tab={i18n.t("info")} key="info">
              <AutoInfo
                data={carInfo}
                role={role}
              />
            </TabPane>
            {
              user &&
                <TabPane tab={i18n.t("finances")} key="finances">
                  <AutoFinance
                    data={carInfo}
                  />
                </TabPane>
            }
            <TabPane tab={i18n.t("trans")} key="trans">
              <AutoTrans
                data={carInfo}
              />
            </TabPane>
            {
              user &&
                <TabPane tab={i18n.t("payments")} key="payments">
                  <AutoPayment
                    scale={tableScale}
                    isHide={isHide}
                    page={page}
                    pageSize={page_size}
                    data={payments.list}
                    fetching={payments.fetching}
                    onChangePage={handleChangePage}
                  />
                </TabPane>
            }
            {
              user && role === 0 &&
                <TabPane tab={i18n.t("subdealer")} key="subdealer">
                  <AutoSubdealer
                    data={feeCustom}
                    editTemplate={editTemplate}
                    onDelete={handleDelete}
                    onNavigate={handleNavigate}
                  />
                </TabPane>
            }
          </Tabs>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    carInfo: state.cars.carInfo,
    carImages: state.cars.carImages,
    payments: state.payments,
    page: state.payments.page,
    page_size: state.settings.page_size,
    tableScale: state.settings.tableScale,
    isHide: state.settings.isHide,
    user: state.auth.userInfo,
    role: state.auth.role,
    feeCustom: state.fees.feeCustom,
    feeCustomDeleteResult: state.fees.deleteFeeCustomResult,
    lang: state.lang.lang,
    isDesktop: state.nav.isDesktop,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getCarPayments: (page, page_size, sort_field, sort_order, search, filter) => dispatch(PaymentsActions.paymentsRequest(page, page_size, sort_field, sort_order, search, filter)),
  paymentsReset: () => dispatch(PaymentsActions.paymentsReset()),

  getCarInfo: (vin) => dispatch(CarsActions.carInfoRequest(vin)),
  getCarImages: (vin) => dispatch(CarsActions.carImagesRequest(vin)),
  resetCarData: () => dispatch(CarsActions.carDataReset()),

  downloadFiles: (data) => dispatch(CarsActions.carPhotoFileRequest(data)),
  resetCarFiles: () => dispatch(CarsActions.carPhotoFileReset()),

  getCustomFee: (vin) => dispatch(FeesActions.feeCustomRequest(vin)),
  unassignSubdealer: (feeId) => dispatch(FeesActions.feeCustomDeleteRequest(feeId)),
  feeSetFocusRow: (data) => dispatch(FeesActions.feeSetFocusRow(data)),
  feeCustomDeleteReset: () => dispatch(FeesActions.feeCustomDeleteReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AutoCardPage)
