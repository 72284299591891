import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import i18n from '../i18n'

function ProfileInfo(props) {
  const { user, accounts, isAdmin, role, withExtra, forDealer, isDesktop } = props;

  const [showData, setShowData] = useState(null);

  useEffect(() => {
    if (accounts && accounts.results) {
      const data = {};
      accounts.results.forEach(acc => {
        data[acc.id] = false;
      });
      setShowData(data);
    }
  }, [accounts]);

  function toggleText(id) {
    const data = { ...showData };
    data[id] = !data[id];
    setShowData(data);
  }

  return (
    user &&
    <div className="profile-info-content">
      <Row gutter={[16, 30]}>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("login")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" g={14} xs={12}>
            {user.login}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("name_and_surname")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" g={14} xs={12}>
            {user.name}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("company")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" g={14} xs={12}>
            {user.company}
          </Col>
        </Col>
      </Row>

      {
        !forDealer && (isAdmin || role === 0) ?
          <Row gutter={[16, 30]}>
            <Col className="gutter-row" lg={8} xs={24}>
              <Col className="gutter-row auto-info-title" lg={10} xs={12}>
                <div className="flex-block">
                  <span>Columb Auto ID</span>
                  <span className="dots"></span>
                </div>
              </Col>
              <Col className="gutter-row auto-info-value" g={14} xs={12}>
                {user.columb_trade_id}
              </Col>
            </Col>
          </Row>
          :
          <Row gutter={[16, 30]}>
            <Col className="gutter-row" lg={8} xs={24}>
              <Col className="gutter-row auto-info-title" lg={10} xs={12}>
                <div className="flex-block">
                  <span>{i18n.t("fees_template")}</span>
                  <span className="dots"></span>
                </div>
              </Col>
              <Col className="gutter-row auto-info-value" g={14} xs={12}>
                {user.fee_template && user.fee_template.name}
              </Col>
            </Col>
          </Row>
      }

      <Row gutter={[16, 30]}>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("country")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" g={14} xs={12}>
            {user.country}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("city")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" g={14} xs={12}>
            {user.city}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("address")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" g={14} xs={12}>
            {user.address}
          </Col>
        </Col>
      </Row>

      <Row gutter={[16, 30]}>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("index")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" g={14} xs={12}>
            {user.zip_code}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("phone")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" g={14} xs={12}>
            {user.phone}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>Email</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" g={14} xs={12}>
            {user.email}
          </Col>
        </Col>
      </Row>

      <Row gutter={[16, 30]}>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>Telegram</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" g={14} xs={12}>
            {user.telegram}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>Viber</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" g={14} xs={12}>
            {user.viber}
          </Col>
        </Col>
      </Row>

      {
        isAdmin && withExtra &&
        <Row gutter={[16, 30]}>
          <Col className="gutter-row" lg={8} xs={24}>
            <Col className="gutter-row auto-info-title" lg={10} xs={12}>
              <div className="flex-block">
                <span>{i18n.t("local_extra")}</span>
                <span className="dots"></span>
              </div>
            </Col>
            <Col className="gutter-row auto-info-value" g={14} xs={12}>
              {user.local_extra}
            </Col>
          </Col>
          <Col className="gutter-row" lg={8} xs={24}>
            <Col className="gutter-row auto-info-title" lg={10} xs={12}>
              <div className="flex-block">
                <span>{i18n.t("ocean_extra")}</span>
                <span className="dots"></span>
              </div>
            </Col>
            <Col className="gutter-row auto-info-value" g={14} xs={12}>
              {user.ocean_extra}
            </Col>
          </Col>
          <Col className="gutter-row" lg={8} xs={24}>
            <Col className="gutter-row auto-info-title" lg={10} xs={12}>
              <div className="flex-block">
                <span>{i18n.t("total_extra")}</span>
                <span className="dots"></span>
              </div>
            </Col>
            <Col className="gutter-row auto-info-value" g={14} xs={12}>
              {user.total_extra}
            </Col>
          </Col>
        </Row>
      }

      {
        role !== 2 &&
          <>
            <Row>
              <div className="popup-title">{i18n.t("accounts")}</div>
            </Row>

            {
              showData && accounts && accounts.results && accounts.results.map(account => (
                <Row gutter={[16, 30]} key={account.id} className="mobile-account-block">
                  <Col className="gutter-row" lg={8} xs={24}>
                    <Col className="gutter-row auto-info-title" lg={10} xs={12}>
                      <div className="flex-block">
                        <span>{i18n.t("account")}</span>
                        <span className="dots"></span>
                      </div>
                    </Col>
                    <Col className="gutter-row auto-info-value" g={14} xs={12}>
                      {account.account}
                    </Col>
                  </Col>
                  <Col className="gutter-row" lg={8} xs={24}>
                    <Col className="gutter-row auto-info-title" lg={10} xs={12}>
                      <div className="flex-block">
                        <span>{i18n.t("login")}</span>
                        <span className="dots"></span>
                      </div>
                    </Col>
                    <Col className="gutter-row auto-info-value" g={14} xs={12}>
                      {account.login}
                    </Col>
                  </Col>
                  <Col className="gutter-row" lg={8} xs={24}>
                    <Col className="gutter-row auto-info-title" lg={10} xs={12}>
                      <div className="flex-block">
                        <span>{i18n.t("password")}</span>
                        <span className="dots"></span>
                      </div>
                    </Col>
                    <Col className="gutter-row auto-info-value" g={14} xs={12}>
                      {
                        isDesktop ?
                          <span
                            onMouseEnter={() => toggleText(account.id)}
                            onMouseLeave={() => toggleText(account.id)}>
                            {showData[account.id] ? account.password : i18n.t("show")}
                          </span>
                        :
                          <span
                            onClick={() => toggleText(account.id)}
                          >
                            {showData[account.id] ? account.password : i18n.t("show")}
                          </span>
                      }
                    </Col>
                  </Col>
                </Row>
              ))
            }
          </>
      }

    </div>
  )
}

export default ProfileInfo
