import React, { useState, useEffect } from 'react'
import { Layout, Button, Input, Select } from 'antd'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import RatesActions from '../../Redux/RatesRedux'
import NavActions from '../../Redux/NavRedux'
import i18n from '../../i18n'
import Images from '../../Images'
import IconCustom from '../../Icon'

import './RatesHeader.css'

const { Header, Footer } = Layout;
const { Search } = Input;
const { Option } = Select;

function RatesHeader(props) {
  const { match, page_size, ports, selectPort, selectQuantity, rates, lang, isAdmin, nav } = props;

  const { item } = match.params;

  const [data, setData] = useState(null);

  const initialData = [
    {
      value: 'local',
      text: i18n.t("local"),
      active: false
    },
    {
      value: 'ocean',
      text: i18n.t("ocean"),
      active: false
    }
  ]

  const options = [
    <Option key='unit'>{i18n.t("per_unit")}</Option>,
    <Option key='container'>{i18n.t("per_container")}</Option>
  ];

  const portList = [];

  if (item === 'ocean') {
    if (ports) {
      for (let i = 0; i < ports.length; i++) {
        portList.push(<Option key={ports[i]}>{ports[i]}</Option>);
      }
    }
  }

  useEffect(() => {
    const data = [...initialData];
    data.forEach(el => {
      if (el.value === item) {
        el.active = true;
      }
    })
    setData(data);

    return function cleanup() {
      initialData.forEach(el => {
        el.active = false;
      })
    }
  }, [0])

  useEffect(() => {
    const data = [...initialData];
    data.forEach(el => {
      if (el.value === item) {
        el.active = true;
      }
    })
    setData(data);
  }, [lang])

  let history = useHistory();

  function subtabNavigate(path) {
    history.push(path);
  }

  function handleNavigate(path) {
    history.push(history.location.pathname + path);
  }

  function logoNavigate(path) {
    if (!isAdmin) {
      history.push(path);
    }
  }

  function changeActive(i) {
    if (data[i].active) return;
    //props.resetRateFilter();
    const newData = initialData.map(el => ({
      ...el,
      active: false
    }))
    newData[i].active = true;
    if (newData[i].value === 'local') {
      props.getLocalRates(1, page_size, rates.sort_field, rates.sort_order, rates.search, rates.filter);
      props.getLocalRateChoices();
    } else if (newData[i].value === 'ocean') {
      props.getOceanRates(rates.filter);
      props.getOceanRateChoices();
    }
    setData([
      ...newData
    ])
    subtabNavigate(`/home/rates/${newData[i].value}`)
  }

  function changePort(val) {
    const newFilter = {
      ...rates.filter,
      port_arrival: val,
      per: selectQuantity
    }
    props.getOceanRates(newFilter);
  }

  function changeQuantity(val) {
    const newFilter = {
      ...rates.filter,
      port_arrival: selectPort,
      per: val
    }
    props.getOceanRates(newFilter);
  }

  function handleSearch(val) {
    if (rates.search === val) {
      return;
    }
    props.getLocalRates(1, page_size, null, null, val, rates.filter);
  }

  function handleOpenMobileMenu(e) {
    props.navSetProp('isShowMobileMenu', true)
  }

  return (
    <Header className="rates-header" style={{ height: (!nav.isDesktop && item === 'ocean') && '192px' }}>
      {
        nav.isDesktop ?
          <>
            <div className="header-title-container">{i18n.t("my_rates")}</div>
            {
              isAdmin &&
              <div className="header-nav-block">
                {
                  data && data.map((el, i) => (
                    <div className={"header-nav-item" + (el.active ? " active" : '')} key={el.text}
                      onClick={() => changeActive(i)}>
                      {el.text}
                    </div>
                  ))
                }
              </div>
            }
            {
              item === 'ocean' &&
              <>
                <div className="header-select">
                  <Select
                    size="large"
                    showArrow={true}
                    value={selectPort}
                    allowClear
                    suffixIcon={<IconCustom type="select" />}
                    placeholder={i18n.t("select_destination_port")}
                    onChange={changePort}
                  >
                    {portList}
                  </Select>
                </div>
                <div style={{ width: '24px' }} />
                <div className="header-select">
                  <Select
                    size="large"
                    showArrow={true}
                    value={selectQuantity}
                    allowClear
                    suffixIcon={<IconCustom type="select" />}
                    placeholder={i18n.t("set_quantity")}
                    onChange={changeQuantity}
                  >
                    {options}
                  </Select>
                </div>
              </>
            }
            <div className="header-buttons-container">
              {
                (item === 'local' && isAdmin) &&
                <Search
                  placeholder={i18n.t("search")}
                  defaultValue={rates.search}
                  allowClear
                  className="content-search"
                  onSearch={handleSearch}
                />
              }
              <Button className="btn-primary" type="primary blue" style={{ width: 'auto' }} onClick={() => handleNavigate('/settings')}>
                <img className="btn-icon" src={Images.settings} alt="" />
              </Button>
              <div style={{ width: '8px' }} />
              <Button className="btn-primary" type="primary blue" style={{ width: 'auto' }} onClick={() => handleNavigate('/filters')}>
                <img className="btn-icon" src={Images.filters} alt="" />
              </Button>
            </div>
          </>
          :
          <>
            <div className="menu-btn">
              <img className="btn-icon" src={Images.logo} alt="" onClick={() => logoNavigate('/home/dashboard')} />
              <div className="header-title-mobile">{i18n.t("my_rates")}</div>
              <div className="mobile-btn-wraper" onClick={handleOpenMobileMenu}>
                <img className="btn-icon" src={Images.menu} alt="" />
              </div>
            </div>
            {
              isAdmin &&
              <div className="header-nav-block">
                {
                  data && data.map((el, i) => (
                    <div className={"header-nav-item" + (el.active ? " active" : '')} key={el.text}
                      onClick={() => changeActive(i)}>
                      {el.text}
                    </div>
                  ))
                }
              </div>
            }
            {
              item === 'ocean' &&
              <div className="header-select-block">
                <div className="header-select">
                  <Select
                    size="large"
                    showArrow={true}
                    value={selectPort}
                    allowClear
                    suffixIcon={<IconCustom type="select" />}
                    placeholder={i18n.t("select_destination_port")}
                    onChange={changePort}
                  >
                    {portList}
                  </Select>
                </div>
                <div className="header-select">
                  <Select
                    size="large"
                    showArrow={true}
                    value={selectQuantity}
                    allowClear
                    suffixIcon={<IconCustom type="select" />}
                    placeholder={i18n.t("set_quantity")}
                    onChange={changeQuantity}
                  >
                    {options}
                  </Select>
                </div>
              </div>
            }
          </>
      }
    </Header>
  )
}

function RatesFooterComp(props) {
  const { match, page_size, rates, isAdmin } = props;

  const { item } = match.params;

  const [showSearch, setShowSearch] = useState(false);

  let history = useHistory();

  function handleNavigate(path) {
    history.push(history.location.pathname + path);
  }

  function handleSearch(val) {
    if (rates.search === val) {
      return;
    }
    props.getLocalRates(1, page_size, null, null, val, rates.filter);
  }

  useEffect(() => {
    if (item) {
      setShowSearch(false);
    }
  }, [item])

  return (
    <Footer>
      <div className="footer-buttons-container">
        {
          (item === 'local' && isAdmin) &&
          <div className="mobile-btn-wraper" onClick={() => setShowSearch(!showSearch)}>
            <img className="btn-icon" src={Images.search} alt="" />
          </div>
        }
        {
          showSearch &&
          <div className="mobile-search">
            <Search
              placeholder={i18n.t("search")}
              allowClear
              className="content-search"
              onSearch={handleSearch}
            />
          </div>
        }
        <Button className="btn-primary" type="primary blue" style={{ width: '40px' }} onClick={() => handleNavigate('/settings')}>
          <img className="btn-icon" src={Images.settings} alt="" />
        </Button>
        <div style={{ width: '8px' }} />
        <Button className="btn-primary" type="primary blue" style={{ width: '40px' }} onClick={() => handleNavigate('/filters')}>
          <img className="btn-icon" src={Images.filters} alt="" />
        </Button>
      </div>
    </Footer>
  )
}

const mapStateToProps = (state) => {
  return {
    page_size: state.settings.page_size,
    rates: state.rates,
    ports: state.rates.oceanPortList,
    selectPort: state.rates.filter.port_arrival,
    selectQuantity: state.rates.filter.per,
    lang: state.lang.lang,
    isAdmin: state.auth.isAdmin,
    nav: state.nav,
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetRateFilter: () => dispatch(RatesActions.rateFilterReset()),
  getLocalRates: (page, page_size, sort_field, sort_order, search, filter) => dispatch(RatesActions.ratesLocalRequest(page, page_size, sort_field, sort_order, search, filter)),
  getOceanRates: (filter) => dispatch(RatesActions.ratesOceanRequest(filter)),
  getLocalRateChoices: () => dispatch(RatesActions.rateLocalChoicesRequest()),
  getOceanRateChoices: () => dispatch(RatesActions.rateOceanChoicesRequest()),
  navSetProp: (key, value) => dispatch(NavActions.navSetProp(key, value)),
})

export const RatesFooter = connect(mapStateToProps, mapDispatchToProps)(RatesFooterComp);
export default connect(mapStateToProps, mapDispatchToProps)(RatesHeader)
