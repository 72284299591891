import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  dashboardRequest: null,
  dashboardSuccess: ['result'],

  dashboardReset: null,

  dashboardFetchingReset: null
})

export const DashboardTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  result: null,
  fetching: false

})

/* ------------- Selectors ------------- */
export const DashboardSelectors = {

}

/* ------------- Reducers ------------- */

export const dashboardRequest = (state: Object, { processType }: Object) => {
  return state.merge({ fetching: true})
}

export const dashboardSuccess = (state: Object, { result }: Object) => {
  return state.merge({ fetching: false, result })
}

export const dashboardReset = (state: Object) =>{
  return INITIAL_STATE
}

export const dashboardFetchingReset = (state: Object) => {
  return state.merge({ fetching:false })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.DASHBOARD_REQUEST]: dashboardRequest,
  [Types.DASHBOARD_SUCCESS]: dashboardSuccess,
  [Types.DASHBOARD_RESET]: dashboardReset,

  [Types.DASHBOARD_FETCHING_RESET]: dashboardFetchingReset,

})
