import React, { useState } from 'react'
import { Layout, Button } from 'antd'
import i18n from '../i18n'
import Images from '../Images'
import IconCustom from '../Icon'
const { Sider } = Layout;

function Sidebar({ path, onNavigate, logout, lang, isAdmin, role, onChangeLang, onCollapse, breakpoint, onBreakpoint }) {
  const selected = '1';

  const [visible, setVisible] = useState(false);

  function signOut() {
    logout();
    onNavigate && onNavigate('/login');
  }

  function toggleLangPopover() {
    setVisible(!visible);
  }

  function handleNavigate() {
    isAdmin ?
      onNavigate('/home/cars')
      :
      onNavigate('/home/dashboard')
  }

  return (
    <Sider
      width={64}
      breakpoint={breakpoint}
      onBreakpoint={onBreakpoint}
      onCollapse={onCollapse}
      collapsedWidth={0}
      trigger={null}
      style={{
        background: '#EAEDF3',
        paddingTop: '0px'
      }}
    >
      <div className="header-logo-container">
        <img src={Images.logo} onClick={handleNavigate} alt="" />
      </div>
      <IconCustom
        onClick={() => onNavigate && onNavigate('/home/cars')}
        opacity={path && path.indexOf('/home/car') >= 0 && selected}
        role="button"
        btnType="sidebar"
        type="car"
        titleText={i18n.t("auto")}
      />

      <IconCustom
        onClick={() => onNavigate && onNavigate('/home/payments')}
        opacity={path && path.indexOf('/home/payments') >= 0 && selected}
        role="button"
        btnType="sidebar"
        type="wallet"
        titleText={i18n.t("payments")}
      />

      {
        !isAdmin ?
          <>
            {/* <IconCustom
            onClick={()=>onNavigate && onNavigate('/home/broker')}
            opacity={path && path.indexOf('/home/broker')>=0 && selected}
            role="button"
            btnType="sidebar"
            type="broker"
            titleText={i18n.t("expeditor_broker")}
          /> */}

            {/* <IconCustom
            onClick={()=>onNavigate && onNavigate('/home/titles')}
            opacity={path && path.indexOf('/home/titles')>=0 && selected}
            role="button"
            btnType="sidebar"
            type="title"
            titleText={i18n.t("titles")}
          /> */}

            <IconCustom
              onClick={() => onNavigate && onNavigate('/home/invoices')}
              opacity={path && path.indexOf('/home/invoices') >= 0 && selected}
              role="button"
              btnType="sidebar"
              type="invoice"
              titleText={i18n.t("invoices")}
            />

            {
              role === 0 &&
              <>
                <IconCustom
                  onClick={() => onNavigate && onNavigate('/home/rates/ocean')}
                  opacity={path && path.indexOf('/home/rates') >= 0 && selected}
                  role="button"
                  btnType="sidebar"
                  type="rate"
                  titleText={i18n.t("my_rates")}
                />
                <IconCustom
                  onClick={() => onNavigate && onNavigate('/home/calculator')}
                  opacity={path && path.indexOf('/home/calculator') >= 0 && selected}
                  role="button"
                  btnType="sidebar"
                  type="calculator"
                  titleText={i18n.t("calculator")}
                />
              </>
            }

          </>
          :
          <>
            <IconCustom
              onClick={() => onNavigate && onNavigate('/home/titles')}
              opacity={path && path.indexOf('/home/titles') >= 0 && selected}
              role="button"
              btnType="sidebar"
              type="title"
              titleText={i18n.t("titles")}
            />
            <IconCustom
              onClick={() => onNavigate && onNavigate('/home/invoices')}
              opacity={path && path.indexOf('/home/invoices') >= 0 && selected}
              role="button"
              btnType="sidebar"
              type="invoice"
              titleText={i18n.t("invoices")}
            />
            <IconCustom
              onClick={() => onNavigate && onNavigate('/home/calculator')}
              opacity={path && path.indexOf('/home/calculator') >= 0 && selected}
              role="button"
              btnType="sidebar"
              type="calculator"
              titleText={i18n.t("calculator")}
            />
            <IconCustom
              onClick={() => onNavigate && onNavigate('/home/dealers')}
              opacity={path && path.indexOf('/home/dealer') >= 0 && selected}
              role="button"
              btnType="sidebar"
              type="users"
              titleText={i18n.t("dealers")}
            />
          </>
      }
      {
        role === 0 &&
        <IconCustom
          onClick={() => onNavigate && onNavigate('/home/subdealers')}
          opacity={path && path.indexOf('/home/subdealer') >= 0 && selected}
          role="button"
          btnType="sidebar"
          type="users"
          titleText={i18n.t("subdealers")}
        />
      }

      <div className="sidebar-pull-down">

        <div className="language-btn" onClick={toggleLangPopover}>
          {
            visible &&
            <div className="language-popover">
              <Button className={lang.lang === 'en' ? 'btn-primary secondary active' : 'btn-primary secondary'} type="primary" onClick={() => onChangeLang('en')}>
                en
              </Button>
              <Button className={lang.lang === 'ru' ? 'btn-primary secondary active' : 'btn-primary secondary'} type="primary" onClick={() => onChangeLang('ru')}>
                ru
              </Button>
              <Button className={lang.lang === 'pl' ? 'btn-primary secondary active' : 'btn-primary secondary'} type="primary" onClick={() => onChangeLang('pl')}>
                pl
              </Button>
            </div>
          }
          <span>{lang.lang}</span>
        </div>

        <IconCustom
          role="button"
          btnType="sidebar"
          type="user"
          titleText={i18n.t("my_profile")}
          opacity={path && path.indexOf('/home/profile') >= 0 && selected}
          onClick={() => onNavigate && onNavigate('/home/profile')}
        />

        <IconCustom
          onClick={signOut}
          role="button"
          btnType="sidebar"
          type="logout"
        />
      </div>
    </Sider>
  )
}

export default Sidebar
