import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Tabs } from 'antd'
import { useHistory } from 'react-router-dom'
import UserActions from '../Redux/UserRedux'
import FeesActions from '../Redux/FeesRedux'
import ProfileActions from '../Redux/ProfileRedux'
import ProfileInfo from '../Components/ProfileInfo'
import FeeTemplates from '../Components/FeeTemplates'
import i18n from '../i18n'

import './ProfilePage.css'

const { TabPane } = Tabs;

function ProfilePage(props) {
  const {user, isAdmin, role, accounts, fees, isDesktop} = props;

  let history = useHistory();

  useEffect(() => {
    props.changeTab('general_information');
    props.getAccounts(user.id);
    if (role === 0) {
      props.getFees();
    }
    return function cleanup() {
    }
  }, [0]);

  function handleDelete(id) {
    props.removeTemplate(id);
  }

  function setFocusRow(data) {
    props.feeSetFocusRow(data);
  }

  return (
    <div className="content-page profile">
      {
        role === 0 ?
          <div className="profile-content">
            <Tabs type="card" onChange={(tab) => props.changeTab(tab)}>
              <TabPane tab={i18n.t("general_information")} key="general_information">
                <ProfileInfo
                  user={user}
                  accounts={accounts}
                  isAdmin={isAdmin}
                  role={role}
                  isDesktop={isDesktop}
                />
              </TabPane>
              <TabPane tab={i18n.t("fees_templates")} key="fees_templates">
                <FeeTemplates
                  data={fees}
                  history={history}
                  onDelete={handleDelete}
                  setFocusRow={setFocusRow}
                />
              </TabPane>
            </Tabs>
          </div>
        :
          <ProfileInfo
            user={user}
            accounts={accounts}
            isAdmin={isAdmin}
            role={role}
            isDesktop={isDesktop}
          />
      }
    </div>

  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.userInfo,
    accounts: state.users.accounts,
    fees: state.fees.list,
    isAdmin: state.auth.isAdmin,
    role: state.auth.role,
    isDesktop: state.nav.isDesktop
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAccounts: (userId) => dispatch(UserActions.accountsRequest(userId)),
  getFees: () => dispatch(FeesActions.feesRequest()),
  removeTemplate: (id) => dispatch(FeesActions.feeDeleteRequest(id)),
  feeSetFocusRow: (data) => dispatch(FeesActions.feeSetFocusRow(data)),
  changeTab: (tab) => dispatch(ProfileActions.changeActiveTab(tab)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage)
