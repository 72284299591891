import React, { useState, useEffect } from 'react'
import { Table, Popconfirm } from 'antd'
import Images from '../../Images'
import PaginationItemRender from '../PaginationItemRender'
import { titleWithSortFilters } from '../titleWithSortFilters'
import i18n from '../../i18n'
import CustomIcon from '../../Icon'

import './Table.css'

function DealersTable(props){
  const {
    page,
    pageSize,
    scale,
    data,
    history,
    isAdmin,
    role,
    fetching
  } = props;

  const [dataSource, setDataSource] = useState(null);

  useEffect(() => {
    setDataSource(data && data.results ? data.results.asMutable({deep:true}) : []);
  }, [data]);

  function handleNavigate(record) {
    isAdmin ?
      history.push(`/home/dealer/${record.id}`)
    :
      history.push(`/home/subdealer/${record.id}`)
  }

  function getColumns() {
    return [
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("name"), true, false, stateData, 'name'),
        dataIndex: 'name',
        key: 'name',
        sorter:true,
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("company"), true, false, stateData, 'company'),
        dataIndex: 'company',
        key: 'company',
        sorter: true
      },
      {
        title: (stateData)=>titleWithSortFilters('Columb Auto ID', true, false, stateData, 'columb_trade_id'),
        dataIndex: 'columb_trade_id',
        key: 'columb_trade_id',
        sorter: true,
        className: !isAdmin && 'hidden-column'
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("email"), true, false, stateData, 'email'),
        dataIndex: 'email',
        key: 'email',
        sorter: true
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("phone"), true, false, stateData, 'phone'),
        dataIndex: 'phone',
        key: 'phone',
        sorter: true
      },
      {
        title: (""),
        dataIndex: 'id',
        key: 'id',
        render: (text, record) => {
          return (
            <div>
              <span className="download-icon" onClick={() => handleNavigate(record)}><img src={Images.dots} alt=""/></span>
              {
                role === 0 &&
                  <Popconfirm
                    title={i18n.t("subdealer_delete_confirm")}
                    onConfirm={() => props.onDelete(text)}
                    okText={i18n.t("yes")}
                    cancelText={i18n.t("no")}
                  >
                    <span className="download-icon" style={{marginLeft: 32}}><img src={Images.trash} alt=""/></span>
                  </Popconfirm>
              }
            </div>
          )
        },
      }
    ];
  }

  function handleTableChange(pagination, filters, sorter){
    var sort_field = null;
    var sort_order = null;

    if(sorter && sorter.field && sorter.order){
      sort_field = sorter.field;
      sort_order = sorter.order //=== "ascend" ? 1 : 0;
    }

    props.onChangePage(pagination.current, pagination.pageSize, sort_field, sort_order)

  }

  const totalCount = data ? data.count : 0;

  return (
    <Table
      loading={{spinning:fetching, indicator:<div className="loader"><CustomIcon type="loader" /></div>}}
      pagination= {{itemRender:PaginationItemRender, current:page, pageSize:pageSize, total:totalCount}}
      className="main-table"
      style={{fontSize: (scale * 14 / 100) + 'px'}}
      rowKey={row => row.id}
      rowClassName="process-table-row"
      dataSource={dataSource}
      columns={getColumns()}
      scrollToFirstRowOnChange
      onChange={handleTableChange}
      sortDirections={['descend', 'ascend']}
    />
   )


}

export default DealersTable
