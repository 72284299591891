import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  engineRequest: null,
  engineSuccess: ['engines'],

  auctionRequest: null,
  auctionSuccess: ['data'],

  deliveryRequest: null,
  deliverySuccess: ['data'],

  deliveryCalcRequest: ['data'],
  deliveryCalcSuccess: ['data'],
  deliveryCalcReset: null,

  comissionCalcRequest: ['data'],
  comissionCalcSuccess: ['comissionCalcResult'],
  comissionCalcReset: null,

  customCalcRequest: ['data'],
  customCalcSuccess: ['data'],
  customCalcReset: null,

  calcReset: null,
  calcValueReset: null,

  calcFetchingReset: null
})

export const CalcTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  engines: null,

  auctions: null,

  categories: null,

  bodyTypes: null,
  locations: null,
  ports: null,

  deliveryCalcResult: null,
  exceptions: null,
  deliveryCalcFetching: false,

  comissionCalcResult: null,
  comissionCalcFetching: false,

  customCalcResult: null,
  pension_fund: null,
  customCalcFetching: false,
})

/* ------------- Selectors ------------- */
export const CalcSelectors = {
  getState: state => state.calc,
}

/* ------------- Reducers ------------- */


export const engineRequest = (state: Object, { }: Object) => {
  return state
}

export const engineSuccess = (state: Object, { engines }: Object) => {
  return state.merge({ engines })
}

export const auctionRequest = (state: Object, { }: Object) => {
  return state
}

export const auctionSuccess = (state: Object, { data }: Object) => {
  return state.merge({ auctions: data.auctions, categories: data.categories })
}

export const deliveryRequest = (state: Object, { }: Object) => {
  return state
}

export const deliverySuccess = (state: Object, { data }: Object) => {
  return state.merge({ bodyTypes: data.car_sizes, locations: data.auction_locations, ports: data.ports_of_arrival })
}

export const deliveryCalcRequest = (state: Object, { data }: Object) => {
  return state.merge({ deliveryCalcFetching:true })
}

export const deliveryCalcSuccess = (state: Object, { data }: Object) => {
  return state.merge({ deliveryCalcFetching:false, deliveryCalcResult: data.total_transportation, exceptions: data.exceptions })
}

export const deliveryCalcReset = (state: Object) =>{
  return state.merge({  deliveryCalcFetching:false, deliveryCalcResult:null, exceptions:null })
}

export const comissionCalcRequest = (state: Object, { data }: Object) => {
  return state.merge({ comissionCalcFetching:true })
}

export const comissionCalcSuccess = (state: Object, { comissionCalcResult }: Object) => {
  return state.merge({ comissionCalcFetching:false, comissionCalcResult })
}

export const comissionCalcReset = (state: Object) =>{
  return state.merge({  comissionCalcFetching:false, comissionCalcResult:null })
}

export const customCalcRequest = (state: Object, { data }: Object) => {
  return state.merge({ customCalcFetching:true })
}

export const customCalcSuccess = (state: Object, { data }: Object) => {
  return state.merge({ customCalcFetching:false, customCalcResult: data.clearance_price, pension_fund: data.pension_fund })
}

export const customCalcReset = (state: Object) =>{
  return state.merge({  customCalcFetching:false, customCalcResult:null, pension_fund:null })
}

export const calcReset = (state: Object) =>{
  return INITIAL_STATE
}

export const calcValueReset = (state: Object) =>{
  return state.merge({ comissionCalcResult:null, customCalcResult:null, pension_fund:null })
}

export const calcFetchingReset = (state: Object) => {
  return state.merge({ deliveryCalcFetching: false, comissionCalcFetching:false, customCalcFetching:false })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.ENGINE_REQUEST]: engineRequest,
  [Types.ENGINE_SUCCESS]: engineSuccess,

  [Types.AUCTION_REQUEST]: auctionRequest,
  [Types.AUCTION_SUCCESS]: auctionSuccess,

  [Types.DELIVERY_REQUEST]: deliveryRequest,
  [Types.DELIVERY_SUCCESS]: deliverySuccess,

  [Types.DELIVERY_CALC_REQUEST]: deliveryCalcRequest,
  [Types.DELIVERY_CALC_SUCCESS]: deliveryCalcSuccess,
  [Types.DELIVERY_CALC_RESET]: deliveryCalcReset,

  [Types.COMISSION_CALC_REQUEST]: comissionCalcRequest,
  [Types.COMISSION_CALC_SUCCESS]: comissionCalcSuccess,
  [Types.COMISSION_CALC_RESET]: comissionCalcReset,

  [Types.CUSTOM_CALC_REQUEST]: customCalcRequest,
  [Types.CUSTOM_CALC_SUCCESS]: customCalcSuccess,
  [Types.CUSTOM_CALC_RESET]: customCalcReset,

  [Types.CALC_RESET]: calcReset,
  [Types.CALC_VALUE_RESET]: calcValueReset,

  [Types.CALC_FETCHING_RESET]: calcFetchingReset,
})
