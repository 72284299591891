import { call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import CarsActions from '../Redux/CarsRedux'
import AuthActions from '../Redux/AuthRedux'
import ErrorsActions from '../Redux/ErrorsRedux'

const FileDownload = require('js-file-download');

export function * getCars(api, authApi, {page, page_size, sort_field, sort_order, search, filter}) {
  try {
    const response = yield call(api.getCars, page, page_size, sort_field, sort_order, search, filter);
    if (response && response.ok) {
      yield put(CarsActions.carsSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(CarsActions.carFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getCars(api, authApi, {page, page_size, sort_field, sort_order, search, filter});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(CarsActions.carFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * sendCarFile(api, authApi, {data}) {
  try {
    const response = yield call(api.sendCarFile, data);
    if (response && response.ok) {
      yield put(CarsActions.carSendFileSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(CarsActions.carFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * sendCarFile(api, authApi, {data});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(CarsActions.carFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getCarPorts(api, authApi) {
  try {
    const response = yield call(api.getCarPorts);
    if (response && response.ok) {
      yield put(CarsActions.carPortsSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(CarsActions.carFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getCarPorts(api, authApi);
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(CarsActions.carFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getCarInfo(api, authApi, {vin}) {
  try {
    const response = yield call(api.getCarInfo, vin);
    if (response && response.ok) {
      yield put(CarsActions.carInfoSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(CarsActions.carFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getCarInfo(api, authApi, {vin});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(CarsActions.carFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * updateCarInfo(api, authApi, {vin, data}) {
  try {
    const response = yield call(api.updateCarInfo, vin, data);
    if (response && response.ok) {
      yield put(CarsActions.carInfoSaveSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(CarsActions.carFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * updateCarInfo(api, authApi, {vin, data});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(CarsActions.carFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getCarImages(api, authApi, {vin}) {
  try {
    const response = yield call(api.getCarImages, vin);
    if (response && response.ok) {
      yield put(CarsActions.carImagesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(CarsActions.carFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getCarImages(api, authApi, {vin});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(CarsActions.carFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getCarsFile(api, authApi, {sort_field, sort_order, filter}) {
  try {
    const response = yield call(api.getCarsFile, sort_field, sort_order, filter);
    if (response && response.ok){
        FileDownload(response.data, `auto.xls`);
        yield put(CarsActions.carsFileSuccess())
      } else  {
        if (response && response.data) {
          if (response.status === 401) {
            yield put(ErrorsActions.errorSave(null))
          } else {
            yield put(ErrorsActions.errorSave(response.data))
          }
        } else {
          yield put(ErrorsActions.errorSave("Server response error"))
        }
        yield put(CarsActions.carFetchingReset())
      }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getCarsFile(api, authApi, {sort_field, sort_order, filter});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(CarsActions.carFetchingReset())
      yield put(ErrorsActions.errorSave('Server response error: '+ e))
  }
}

export function * getCarPhotoFile(api, authApi, {data}) {
  try {
    const response = yield call(api.getCarPhotoFile, data);
    if (response && response.ok){
        FileDownload(response.data, `photos.zip`);
        yield put(CarsActions.carPhotoFileSuccess())
      } else  {
        if (response && response.data) {
          if (response.status === 401) {
            yield put(ErrorsActions.errorSave(null))
          } else {
            yield put(ErrorsActions.errorSave(response.data))
          }
        } else {
          yield put(ErrorsActions.errorSave("Server response error"))
        }
        yield put(CarsActions.carFetchingReset())
      }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getCarPhotoFile(api, authApi, {data});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(CarsActions.carFetchingReset())
      yield put(ErrorsActions.errorSave('Server response error: '+ e))
  }
}

export function * getCarBySearch(api, authApi, {search}) {
  try {
    const response = yield call(api.getCarBySearch, search);
    if (response && response.ok) {
      yield put(CarsActions.carSearchSuccess(response.data));
      yield put(CarsActions.carImagesRequest(response.data.vin));
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(CarsActions.carFetchingReset())
    }

  } catch(e) {
    yield put(CarsActions.carFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getCarFinancesInfo(api, authApi) {
  try {
    const response = yield call(api.getCarFinancesInfo);
    if (response && response.ok) {
      yield put(CarsActions.carFinancesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(CarsActions.carFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getCarFinancesInfo(api, authApi);
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(CarsActions.carFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getCarChoices(api, authApi, {page_choice, page_size_choice, search_choice}) {
  try {
    const response = yield call(api.getCarChoices, page_choice, page_size_choice, search_choice);
    if (response && response.ok) {
      yield put(CarsActions.carChoicesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(CarsActions.carFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getCarChoices(api, authApi, {page_choice, page_size_choice, search_choice});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(CarsActions.carFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}
