import { call, put, delay } from 'redux-saga/effects'
import AuthActions from '../Redux/AuthRedux'
import { push } from 'connected-react-router'



export function * signIn(authApi, { login, password }) {
  try {
    const result = yield call(authApi.signIn, login, password);
    if (result && result.access && result.userInfo) {
      yield put(AuthActions.signInSuccess(result.userInfo, result.access, result.refresh))
      yield delay(500)
      if (result.userInfo.role.id === 1) {
        yield put(push('/home/cars'))
      } else {
        yield put(push('/home/dashboard'))
      }

    } else if (result) {
      yield put(AuthActions.signInFailure(result))
    } else {
      yield put(AuthActions.signInFailure('Server response error'))
    }
  } catch(e) {
    yield put(AuthActions.signInFailure('Unknown error: '+e))
  }
}

export function * logout(authApi) {
  try {
    //const response = yield call(authApi.logout);
    authApi.updateAccessToken(null);
    yield delay(500)
    yield put(push('/login'))

  } catch(e) {
    yield put(AuthActions.signInFailure('Unknown error: '+e))
  }
}

export function * resetPassword(authApi, { login }) {
  try {
    const response = yield call(authApi.resetPassword, login );
    if (response && response.ok ) {
      yield put(AuthActions.resetPasswordSuccess())
      yield delay(500)
      yield put(push('/check-mail-page'))
    } else if (response) {
      yield put(AuthActions.resetPasswordFailure(response.data))
    } else {
      yield put(AuthActions.resetPasswordFailure('Server response error'))
    }
  } catch(e) {
    yield put(AuthActions.resetPasswordFailure('Unknown error: '+e))
  }
}
