import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import PaginationItemRender from '../PaginationItemRender'
import { titleWithSortFilters } from '../titleWithSortFilters'
import i18n from '../../i18n'
import CustomIcon from '../../Icon'
import Images from '../../Images'

import './Table.css'

function TitlesTable(props){
  const {
    page,
    pageSize,
    scale,
    data,
    colors,
    history,
    isAdmin,
    setFocus,
    fetching
  } = props;

  const [dataSource, setDataSource] = useState(null);

  useEffect(() => {
    setDataSource(data && data.results ? data.results.asMutable({deep:true}) : []);
  }, [data]);

  function getStyles(color) {
    switch (color) {
      case 'gray': {
        return {
          color: '#9BA1B4',
          background: 'rgba(155, 161, 180, 0.2)',
        }
      }
      case 'yellow': {
        return {
          color: '#EFAE21',
          background: 'rgba(239, 174, 33, 0.2)',
        }
      }
      case 'red': {
        return {
          color: '#E0738D',
          background: 'rgba(224, 115, 141, 0.2)'
        }
      }
      case 'green': {
        return {
          color: '#00C292',
          background: 'rgba(0, 194, 146, 0.2)'
        }
      }
      case 'blue': {
        return {
          color: '#6197D6',
          background: 'background: rgba(97, 151, 214, 0.2)'
        }
      }
      default: return null;
    }
  }

  function getColumns() {
    return [
       {
        title: (""),
        dataIndex: 'id',
        key: 'id',
        render: (text, record) => {
          let styles = getStyles(record.color);
          return styles ?
            <div className="color-type" style={{background: styles.background}}>
              <div className="color-pick" style={{background: styles.color}}></div>
              <span style={{color: styles.color}}>{colors && colors[record.color]}</span>
            </div>
            :
            null
        },
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("state"), true, false, stateData, 'state'),
        dataIndex: 'state',
        key: 'state',
        sorter: true
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("document"), true, false, stateData, 'document'),
        dataIndex: 'document',
        key: 'document',
        sorter: true
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("comments"), true, false, stateData, 'comments'),
        dataIndex: 'comments',
        key: 'comments',
        sorter: true
      },
      {
        title: (""),
        dataIndex: 'id' + 1,
        key: 'id' + 1,
        render: (text, record) => {
          return (
            <span className="download-icon" onClick={() => handleNavigate(record)}><img src={Images.edit} alt=""/></span>
          )
        },
        className: !isAdmin && 'hidden-column',
      }
    ];
  }

  function handleNavigate(record) {
    setFocus(record);
    history.push('/home/titles/edit-comment')
  }

  function handleTableChange(pagination, filters, sorter){

    var sort_field = null;
    var sort_order = null;

    if(sorter && sorter.field && sorter.order){
      sort_field = sorter.field;
      sort_order = sorter.order //=== "ascend" ? 1 : 0;
    }

    props.onChangePage(pagination.current, pagination.pageSize, sort_field, sort_order)

  }

  const totalCount = data ? data.count : 0;

  return (
    <Table
      loading={{spinning:fetching, indicator:<div className="loader"><CustomIcon type="loader" /></div>}}
      pagination= {{itemRender:PaginationItemRender, current:page, pageSize:pageSize, total:totalCount}}
      className="main-table spec-table rates-local-table"
      style={{fontSize: (scale * 14 / 100) + 'px'}}
      rowKey={row => row.id}
      rowClassName="process-table-row"
      dataSource={dataSource}
      columns={getColumns()}
      scrollToFirstRowOnChange
      onChange={handleTableChange}
      sortDirections={['descend', 'ascend']}
    />
   )


}

export default TitlesTable
