import React from 'react';

const Invoice = (props) => (
  <svg width="17" height="24" viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity={props.opacity ? props.opacity : '0.4'}>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.32 0C6.87228 0 7.32 0.447715 7.32 1V6C7.32 7.92195 8.87805 9.48 10.8 9.48H15.8C16.3523 9.48 16.8 9.92772 16.8 10.48V21.6C16.8 22.9255 15.7255 24 14.4 24H2.4C1.07452 24 0 22.9255 0 21.6V2.4C0 1.07452 1.07452 0 2.4 0H6.32ZM14.8664 6.46645C15.1814 6.78143 14.9583 7.32 14.5129 7.32H10.8C10.071 7.32 9.48 6.72902 9.48 6V2.28711C9.48 1.84165 10.0186 1.61857 10.3336 1.93355L14.8664 6.46645ZM7.92338 14.0112C7.28113 14.0755 6.80789 14.4088 6.80789 14.9408C6.80789 15.3618 7.11887 15.6658 7.73408 15.8412C7.79493 15.8587 7.85577 15.8762 7.92338 15.8879V14.0112ZM8.96451 19.5946C9.68113 19.542 10.1882 19.162 10.1882 18.589C10.1882 18.1096 9.8569 17.7881 9.09972 17.6068C9.05918 17.601 9.01188 17.5893 8.96458 17.5776L8.96451 17.5776V19.5946ZM8.97127 20.8575L8.97803 21.6H7.88282L7.88958 20.8692C5.65183 20.7289 4.81352 19.5888 4.8 18.5072H6.57127C6.60507 19.0451 7.05803 19.4953 7.92338 19.5946V17.4197L7.34197 17.3086C6.08451 17.0689 4.97577 16.3907 4.97577 15.0636C4.97577 13.6604 6.26028 12.8828 7.89634 12.7542V12H8.99155V12.76C10.702 12.9179 11.8039 13.8417 11.831 15.0577H10.1003C10.08 14.59 9.68113 14.1223 8.96451 14.0229V16.0633L9.53916 16.1686C10.898 16.42 12 17.0163 12 18.4312C12 19.8227 10.8237 20.7055 8.97127 20.8575Z" fill={props.color ? props.color : "#262F56"} />
    </g>
  </svg>

);

export default Invoice;
