import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import PaginationItemRender from '../PaginationItemRender'
import { titleWithSortFilters } from '../titleWithSortFilters'
import i18n from '../../i18n'
import CustomIcon from '../../Icon'
import utils from '../../Utils/utils'

import './Table.css'

function RateLocalTable(props){
  const {
    page,
    pageSize,
    scale,
    data,
    fetching
  } = props;

  const [dataSource, setDataSource] = useState(null);

  useEffect(() => {
    setDataSource(data && data.results ? data.results.asMutable({deep:true}) : []);
  }, [data]);

  function getColumns() {
    return [
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("port"), true, false, stateData, 'port'),
        dataIndex: 'port',
        key: 'port',
        sorter: true
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("auction_location"), true, false, stateData, 'auction_location'),
        dataIndex: 'auction_location',
        key: 'auction_location',
        sorter: true
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("auction"), true, false, stateData, 'auction'),
        dataIndex: 'auction',
        key: 'auction',
        sorter: true
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("city"), true, false, stateData, 'city'),
        dataIndex: 'city',
        key: 'city',
        sorter: true
      },
      {
        title: (stateData)=>titleWithSortFilters("ZIP", true, false, stateData, 'zip'),
        dataIndex: 'zip',
        key: 'zip',
        sorter: true,
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("cost"), true, false, stateData, 'price'),
        dataIndex: 'price',
        key: 'price',
        sorter: true,
        render: text => <span>{utils.round10Value(text)}</span>
      }
    ];
  }

  function handleTableChange(pagination, filters, sorter){

    var sort_field = null;
    var sort_order = null;

    if(sorter && sorter.field && sorter.order){
      sort_field = sorter.field;
      sort_order = sorter.order //=== "ascend" ? 1 : 0;
    }

    props.onChangePage(pagination.current, pagination.pageSize, sort_field, sort_order)

  }

  const totalCount = data ? data.count : 0;

  return (
    <Table
      loading={{spinning:fetching, indicator:<div className="loader"><CustomIcon type="loader" /></div>}}
      pagination= {{itemRender:PaginationItemRender, current:page, pageSize:pageSize, total:totalCount}}
      className="main-table spec-table rates-local-table"
      style={{fontSize: (scale * 14 / 100) + 'px'}}
      rowKey={row => row.id}
      rowClassName="process-table-row"
      dataSource={dataSource}
      columns={getColumns()}
      scrollToFirstRowOnChange
      onChange={handleTableChange}
      sortDirections={['descend', 'ascend']}
    />
   )


}

export default RateLocalTable
