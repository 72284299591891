import React, { useEffect } from 'react'
import { Layout, Spin, message } from 'antd'
import Sidebar from '../Components/Sidebar'
import SidebarMobile from '../Components/SidebarMobile'
import {
  useLocation,
  useHistory,
  Switch,
  Route
} from 'react-router-dom'
import { connect } from 'react-redux'
import AuthActions from '../Redux/AuthRedux'
import NavActions from '../Redux/NavRedux'
import LangActions from '../Redux/LangRedux'
import ErrorsActions from '../Redux/ErrorsRedux'

import CarsPage from './CarsPage'
import AutoCardPage from './AutoCardPage'
import InvoicesPage from './InvoicesPage'
import RatesPage from './RatesPage'
import PaymentsPage from './PaymentsPage'
import BrokerPage from './BrokerPage'
import DealersPage from './DealersPage'
import DealerPage from './DealerPage'
import TitlesPage from './TitlesPage'
import CalculatorPage from './CalculatorPage'
import DashboardPage from './DashboardPage'
import ProfilePage from './ProfilePage'

import CarsHeader, { CarsFooter } from '../Components/Headers/CarsHeader'
import AutoCardHeader from '../Components/Headers/AutoCardHeader'
import PaymentsHeader, { PaymentsFooter } from '../Components/Headers/PaymentsHeader'
import BrokerHeader, { BrokerFooter } from '../Components/Headers/BrokerHeader'
import InvoicesHeader, { InvoicesFooter } from '../Components/Headers/InvoicesHeader'
import RatesHeader, { RatesFooter } from '../Components/Headers/RatesHeader'
import DealersHeader, { DealersFooter } from '../Components/Headers/DealersHeader'
import DealerHeader, { DealerFooter } from '../Components/Headers/DealerHeader'
import TitlesHeader, { TitlesFooter } from '../Components/Headers/TitlesHeader'
import CalculatorHeader from '../Components/Headers/CalculatorHeader'
import ProfileHeader, { ProfileFooter } from '../Components/Headers/ProfileHeader'

import PrivateRoute from '../Components/PrivateRoute'

import NotFoundPage from './NotFoundPage'
import StartupPage from './StartupPage'

import SettingsPopupPage from './PopupPages/SettingsPopupPage'
import FiltersPopupPage from './PopupPages/FiltersPopupPage'
import InvoicePopupPage from './PopupPages/InvoicePopupPage'
import DealerPopupPage from './PopupPages/DealerPopupPage'
import ChangePasswordPopupPage from './PopupPages/ChangePasswordPopupPage'
import EditCommentPopupPage from './PopupPages/EditCommentPopupPage'
import FeePopupPage from './PopupPages/FeePopupPage'
import AssignSubdealerPopupPage from './PopupPages/AssignSubdealerPopupPage'

const { Content } = Layout;

function HomePage(props) {
  const { isAdmin, lang, role, fileFetching, carsUploadFetching, paymentsUploadFetching, brokerUploadFetching, error, nav } = props;

  let location = useLocation();
  let history = useHistory();
  const path = location.pathname;

  function handleNavigate(path) {
    history.push(path);
  }

  function handleMobileMenuNavigate(path) {
    history.push(path);
    props.navSetProp('isShowMobileMenu', false)
  }

  function handleChangeLang(lang) {
    props.langChange(lang)
  }

  function handleCloseMobileMenu(e) {
    props.navSetProp('isShowMobileMenu', false)
  }

  useEffect(() => {
    if (error) {
      if (error.message) {
        for (let i in error.message) {
          message.error(`${i}: ${error.message[i]}`, 5);
        }
      } else if (typeof error === 'string') {
        message.error(error, 5);
      } else {
        for (let i in error) {
          message.error(`${i}: ${error[i]}`, 5);
        }
      }
      props.errorReset();
    }
  }, [error]);

  if (props.auth.userInfo === null || props.startup.success === false) {
    return (
      <Layout>
        <Route path="*" component={StartupPage} />
      </Layout>
    )
  }

  return (
    <Layout style={{ overflowY: 'hidden' }}>
      <Layout>
        <Sidebar
          isDesktop={nav.isDesktop}
          lang={lang}
          isAdmin={isAdmin}
          role={role}
          path={path}
          breakpoint="xl"
          onChangeLang={handleChangeLang}
          onNavigate={handleNavigate}
          logout={props.logout}
          onBreakpoint={broken => { }}
          onCollapse={(collapsed, type) => {
            if (collapsed) {
              props.navSetProp('isDesktop', false);
            } else {
              props.navSetProp('isDesktop', true);
            }
          }}
        />
        <Layout>
          <Spin size="large" spinning={fileFetching || carsUploadFetching || paymentsUploadFetching || brokerUploadFetching}>
            <Switch>
              <Route path="/home/cars" component={CarsHeader} />
              <Route path="/home/payments" component={PaymentsHeader} />
              {/* <PrivateRoute path="/home/broker" forOther={!isAdmin} component={BrokerHeader} /> */}
              <Route path="/home/car/:vin" component={AutoCardHeader} />
              <Route path="/home/invoices" component={InvoicesHeader} />
              <PrivateRoute path="/home/rates/:item" forDealer={role === 0} component={RatesHeader} />
              <PrivateRoute path="/home/dealers" forAdmin={isAdmin} component={DealersHeader} />
              <PrivateRoute path="/home/dealer/:id" forAdmin={isAdmin} component={DealerHeader} />
              <PrivateRoute path="/home/subdealers" forDealer={role === 0} component={DealersHeader} />
              <PrivateRoute path="/home/subdealer/:id" forDealer={role === 0} component={DealerHeader} />
              <PrivateRoute path="/home/titles" forAdmin={isAdmin} component={TitlesHeader} />
              <PrivateRoute path="/home/calculator" forOther={role !== 2} component={CalculatorHeader} />
              <Route path="/home/profile" component={ProfileHeader} />
            </Switch>
            <Content>
              <Switch>
                <Route path="/home/cars" component={CarsPage} />
                <Route path="/home/car/:vin" component={AutoCardPage} />
                <Route path="/home/payments" component={PaymentsPage} />
                {/* <PrivateRoute path="/home/broker" forOther={!isAdmin} component={BrokerPage} /> */}
                <Route path="/home/invoices" component={InvoicesPage} />
                <PrivateRoute path="/home/rates/:item" forDealer={role === 0} component={RatesPage} />
                <PrivateRoute path="/home/dealers" forAdmin={isAdmin} component={DealersPage} />
                <PrivateRoute path="/home/dealer/:id" forAdmin={isAdmin} component={DealerPage} />
                <PrivateRoute path="/home/subdealers" forDealer={role === 0} component={DealersPage} />
                <PrivateRoute path="/home/subdealer/:id" forDealer={role === 0} component={DealerPage} />
                <PrivateRoute path="/home/titles" forAdmin={isAdmin} component={TitlesPage} />
                <PrivateRoute path="/home/calculator" forOther={role !== 2} component={CalculatorPage} />
                <PrivateRoute path="/home/dashboard" forOther={!isAdmin} component={DashboardPage} />
                <Route path="/home/profile" component={ProfilePage} />

                <Route path="/home/*" component={NotFoundPage} />
              </Switch>
            </Content>
            {!nav.isDesktop &&
              <Switch>
                <Route path="/home/cars" component={CarsFooter} />
                <Route path="/home/payments" component={PaymentsFooter} />
                {/* <PrivateRoute path="/home/broker" forOther={!isAdmin} component={BrokerFooter} /> */}
                <Route path="/home/invoices" component={InvoicesFooter} />
                <PrivateRoute path="/home/rates/:item" forDealer={role === 0} component={RatesFooter} />
                <PrivateRoute path="/home/dealers" forAdmin={isAdmin} component={DealersFooter} />
                <PrivateRoute path="/home/dealer/:id" forAdmin={isAdmin} component={DealerFooter} />
                <PrivateRoute path="/home/subdealers" forDealer={role === 0} component={DealersFooter} />
                <PrivateRoute path="/home/subdealer/:id" forDealer={role === 0} component={DealerFooter} />
                <PrivateRoute path="/home/titles" forAdmin={isAdmin} component={TitlesFooter} />
                <Route path="/home/profile" component={ProfileFooter} />
              </Switch>
            }
          </Spin>
        </Layout>
      </Layout>
      <Route path="/home/:tab/settings" component={SettingsPopupPage} />
      <PrivateRoute path="/home/rates/:subtab/settings" forDealer={role === 0} component={SettingsPopupPage} />
      <Route exact path="/home/:tab/filters" component={FiltersPopupPage} />
      <PrivateRoute exact path="/home/rates/:subtab/filters" forDealer={role === 0} component={FiltersPopupPage} />
      <Route path="/home/invoices/create" component={InvoicePopupPage} />
      <PrivateRoute path="/home/dealers/create" forAdmin={isAdmin} component={DealerPopupPage} />
      <PrivateRoute path="/home/dealer/:id/edit" forAdmin={isAdmin} component={DealerPopupPage} />
      <PrivateRoute path="/home/subdealers/create" forDealer={role === 0} component={DealerPopupPage} />
      <PrivateRoute path="/home/subdealer/:id/edit" forDealer={role === 0} component={DealerPopupPage} />
      <Route path="/home/profile/edit" component={DealerPopupPage} />
      <Route path="/home/profile/change-password" component={ChangePasswordPopupPage} />
      <PrivateRoute path="/home/dealer/:id/change-password" forAdmin={isAdmin} component={ChangePasswordPopupPage} />
      <PrivateRoute path="/home/subdealer/:id/change-password" forDealer={role === 0} component={ChangePasswordPopupPage} />
      <Route path="/home/cars/edit-comment" component={EditCommentPopupPage} />
      <Route path="/home/titles/edit-comment" component={EditCommentPopupPage} />

      <PrivateRoute path="/home/profile/create-fee-template" forDealer={role === 0} component={FeePopupPage} />
      <PrivateRoute path="/home/profile/edit-fee-template" forDealer={role === 0} component={FeePopupPage} />
      <PrivateRoute path="/home/car/:vin/edit-fee-template" forDealer={role === 0} component={FeePopupPage} />
      <PrivateRoute path="/home/car/:vin/assign-subdealer" forDealer={role === 0} component={AssignSubdealerPopupPage} />

      <SidebarMobile
        isAdmin={isAdmin}
        role={role}
        path={path}
        nav={nav}
        lang={lang}
        onChangeLang={handleChangeLang}
        onNavigate={handleMobileMenuNavigate}
        onClose={handleCloseMobileMenu}
        logout={props.logout}
      />
    </Layout>

  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    isAdmin: state.auth.isAdmin,
    role: state.auth.role,
    startup: state.startup,
    lang: state.lang,
    fileFetching: state.cars.fileFetching,
    carsUploadFetching: state.cars.uploadFileFetching,
    paymentsUploadFetching: state.payments.uploadFileFetching,
    brokerUploadFetching: state.broker.uploadFileFetching,
    error: state.errors.data,
    nav: state.nav
  }
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(AuthActions.logout()),
  langChange: (lang) => dispatch(LangActions.langChange(lang)),
  errorReset: () => dispatch(ErrorsActions.errorReset()),
  navSetProp: (key, value) => dispatch(NavActions.navSetProp(key, value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
