import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  langChange: ['lang']
})

export const LangTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  lang: 'ru'
})

/* ------------- Selectors ------------- */

export const getLang = (state) => state.lang.lang

/* ------------- Reducers ------------- */

export const langChange = (state: Object, { lang }:Object) => {
  return state.merge({ lang })
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LANG_CHANGE]: langChange
})
