import React, { useState, useEffect} from 'react'
import { connect } from 'react-redux'
import {useHistory} from 'react-router-dom'
import DealersActions from '../../Redux/DealersRedux'
import UserActions from '../../Redux/UserRedux'
import AuthActions from '../../Redux/AuthRedux'
import FeesActions from '../../Redux/FeesRedux'
import DealerPopupForm from '../../Components/Forms/DealerPopupForm'
import SubdealerPopupForm from '../../Components/Forms/SubdealerPopupForm'
import utils from '../../Utils/utils'

function DealerPopupPage (props) {
  const {
    auth,
    users,
    columbs,
    isAdmin,
    location,
    match,
    feeNameList,
    userData,
    page_size
  } = props;

  const [user, setUser] = useState(null);

  let history = useHistory();
  function handleClose (e){
    props.userSaveReset();
    if (location.pathname === '/home/profile/edit') {
      history.push('/home/profile');
    } else {
      let path = utils.cutUrlToPrevious(history.location.pathname);
      history.push(path);
    }
  }

  function handleSubmit(data){
    props.userSaveRequest(data);
  }

  function handleDelete(row){
    props.userDeleteRequest(row.id);
  }

  useEffect(() => {
    if (location.pathname === '/home/profile/edit') {
      setUser(auth.userInfo);
    } else if (match.params.id) {
      props.userSetData(null);
      props.getUser(match.params.id);
    } else {
      props.userSetData(null);
    }

    if (isAdmin) {
      props.getUserColumbs();
    } else {
      if (auth.role === 0) {
        props.getFeeNames();
      }
    }
  }, [0])

  useEffect(() => {
    if (user) {
      if (location.pathname === '/home/profile/edit') {
        props.getAccounts(user.id);
      }
    }

  }, [user])

  useEffect(() => {
    if (location.pathname !== '/home/profile/edit') {
      setUser(userData);
    }
  }, [userData])


  useEffect(()=>{
    if(users.saveResult) {
      if (location.pathname === '/home/profile/edit') {
        props.updateProfile(users.saveResult);
        history.push('/home/profile');
      } else {
        if (match.params.id) {
          props.userSetData(users.saveResult);
        } else {
          if (isAdmin) {
            props.getUsers(1, page_size, null, null, 0, null);
          } else {
            props.getUsers(1, page_size, null, null, 2, null);
          }
        }
        let path = utils.cutUrlToPrevious(history.location.pathname);
        history.push(path);
      }
      props.userSaveReset();
    }
  }, [users.saveResult]);

  return (
      <div className="popup-dialog">
        {
          isAdmin || (auth.role === 0 && location.pathname === '/home/profile/edit')?
            <DealerPopupForm
              columbs={columbs}
              users={users}
              edit={user}
              isAdmin={isAdmin}
              isProfile={location.pathname === '/home/profile/edit'}
              accountSaveResult={users.accountSaveResult}
              accountDeleteResult={users.accountDeleteResult}
              addAccount={props.saveAccount}
              deleteAccount={props.deleteAccount}
              onClose={handleClose}
              onSubmit={handleSubmit}
              onDelete={handleDelete}
            />
          :
            <SubdealerPopupForm
              templates={feeNameList}
              users={users}
              edit={user}
              isProfile={location.pathname === '/home/profile/edit'}
              accountSaveResult={users.accountSaveResult}
              accountDeleteResult={users.accountDeleteResult}
              addAccount={props.saveAccount}
              deleteAccount={props.deleteAccount}
              role={auth.role}
              onClose={handleClose}
              onSubmit={handleSubmit}
              onDelete={handleDelete}
            />
        }
      </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    users: state.users,
    userData: state.users.userData,
    columbs: state.dealers.columbs,
    isAdmin: state.auth.isAdmin,
    feeNameList: state.fees.feeNameList,
    page_size: state.settings.page_size,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUsers: (page, page_size, sort_field, sort_order, role, search) => dispatch(UserActions.usersRequest(page, page_size, sort_field, sort_order, role, search)),
  getUser: (userId) => dispatch(UserActions.userRequest(userId)),
  userSetData: (data) => dispatch(UserActions.userSetData(data)),

  getUserColumbs: () => dispatch(DealersActions.userColumbsRequest()),
  getAccounts: (userId) => dispatch(UserActions.accountsRequest(userId)),
  resetAccounts: () => dispatch(UserActions.accountsReset()),

  saveAccount: (data, userId) => dispatch(UserActions.accountSaveRequest(data, userId)),
  accountSaveReset: () => dispatch(UserActions.accountSaveReset()),
  deleteAccount:(accountId, userId) => dispatch(UserActions.accountDeleteRequest(accountId, userId)),
  accountDeleteReset: () => dispatch(UserActions.accountDeleteReset()),

  userSaveRequest: (data) => dispatch(UserActions.userSaveRequest(data)),
  userSaveReset: () => dispatch(UserActions.userSaveReset()),
  userDeleteRequest:(id) => dispatch(UserActions.userDeleteRequest(id)),
  userDeleteReset: () => dispatch(UserActions.userDeleteReset()),
  updateProfile: (data) => dispatch(AuthActions.updateProfile(data)),

  getFeeNames: () => dispatch(FeesActions.feeNamesRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DealerPopupPage);
