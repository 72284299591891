import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import moment from 'moment'
import PaginationItemRender from '../PaginationItemRender'
import { titleWithSortFilters } from '../titleWithSortFilters'
import i18n from '../../i18n'
import CustomIcon from '../../Icon'
import utils from '../../Utils/utils'

import './Table.css'

function PaymentsTable(props){
  const {
    page,
    pageSize,
    scale,
    data,
    isShot,
    fetching
  } = props;

  const [dataSource, setDataSource] = useState(null);

  useEffect(() => {
    setDataSource(data && data.results ? data.results.asMutable({deep:true}) : []);
  }, [data]);

  function getColumns() {
    return [
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("date"), true, false, stateData, 'date'),
        dataIndex: 'date',
        key: 'date',
        sorter: true,
        render: (text, record) => {
          return (
              <span>{text && moment(text).format('DD.MM.YYYY')}</span>
          )
        },
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("payment"), true, false, stateData, 'payment_number'),
        dataIndex: 'payment_number',
        key: 'payment_number',
        sorter:true,
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("information"), true, false, stateData, 'payment_method'),
        dataIndex: 'payment_method',
        key: 'payment_method',
        sorter: true
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("amount"), true, false, stateData, 'payment_amount'),
        dataIndex: 'payment_amount',
        key: 'payment_amount',
        sorter: true,
        render: text => <span>{utils.round10Value(text)}</span>
      }
    ];
  }

  function handleTableChange(pagination, filters, sorter){

    var sort_field = null;
    var sort_order = null;

    if(sorter && sorter.field && sorter.order){
      sort_field = sorter.field;
      sort_order = sorter.order //=== "ascend" ? 1 : 0;
    }

    props.onChangePage(pagination.current, pagination.pageSize, sort_field, sort_order)

  }

  const totalCount = data ? data.count : 0;

  return (
    <Table
      loading={{spinning:fetching, indicator:<div className="loader"><CustomIcon type="loader" /></div>}}
      pagination= {{itemRender:PaginationItemRender, current:page, pageSize:pageSize, total:totalCount}}
      className={!isShot ? 'main-table' : ''}
      style={{fontSize: (scale * 14 / 100) + 'px'}}
      rowKey={row => row.id}
      rowClassName="process-table-row"
      dataSource={dataSource}
      columns={getColumns()}
      scrollToFirstRowOnChange
      onChange={handleTableChange}
      sortDirections={['descend', 'ascend']}
    />
   )


}

export default PaymentsTable
