import React from 'react'
import { connect } from 'react-redux'
import { Layout, Breadcrumb } from 'antd'
import { useHistory } from 'react-router-dom'
import NavActions from '../../Redux/NavRedux'
import i18n from '../../i18n'
import Images from '../../Images'

const { Header } = Layout;

function AutoCardHeader(props) {
  const {cars, nav} = props;

  let history = useHistory();

  function handleNavigate(path) {
    history.push(path);
  }

  function handleOpenMobileMenu(e) {
    props.navSetProp('isShowMobileMenu', true)
  }

  return (
    <Header>
      {
        nav.isDesktop ?
          <div className="header-title-container">
            <Breadcrumb separator=">">
              <Breadcrumb.Item className="root-breadcrumb" onClick={() => handleNavigate('/home/cars')}>{i18n.t("auto")}</Breadcrumb.Item>
              <Breadcrumb.Item className="child-breadcrumb">{cars.carInfo && cars.carInfo.description}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        :
          <div className="menu-btn">
            <div className="mobile-btn-wraper back-btn" onClick={() => handleNavigate('/home/cars')}>
              <img className="btn-icon" src={Images.back} alt="" />
            </div>
            <div className="car-desc">{cars.carInfo && cars.carInfo.description}</div>
            <div className="mobile-btn-wraper" onClick={handleOpenMobileMenu}>
              <img className="btn-icon" src={Images.menu} alt="" />
            </div>
          </div>
      }
    </Header>
  )
}

const mapStateToProps = (state) => {
  return {
    cars: state.cars,
    nav: state.nav,
  }
}

const mapDispatchToProps = (dispatch) => ({
  navSetProp: (key, value) => dispatch(NavActions.navSetProp(key, value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AutoCardHeader)
