import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {useHistory} from 'react-router-dom'
import UserActions from '../Redux/UserRedux'
import DealersTable from '../Components/Tables/DealersTable'

function DealersPage(props) {
  const [start, setStart] = useState(false);

  let history = useHistory();

  useEffect(() => {
    props.resetSearch();
    if (props.isAdmin) {
      props.getDealers(1, props.page_size, null, null, 0, null);
    } else {
      props.getDealers(1, props.page_size, null, null, 2, null);
    }
    setStart(true);

    return function cleanup() {
      setStart(false);
    }
  }, [0]);

  useEffect(() => {
    if (start) {
      props.getDealers(props.page, props.page_size, props.sort_field, props.sort_order, props.role, props.search);
    }
  }, [props.page_size])

  function handleChangePage(page, page_size, sort_field, sort_order){
    props.getDealers(page, page_size, sort_field, sort_order, props.role, props.search);
  }

  function handleDelete(id) {
    props.deleteSubdealer(id);
  }

  return (
    <div className="content-page">
      <DealersTable
        scale={props.tableScale}
        isHide={props.isHide}
        page={props.page}
        pageSize={props.page_size}
        data={props.users}
        fetching={props.fetching}
        history={history}
        isAdmin={props.isAdmin}
        role={props.userRole}
        onChangePage={handleChangePage}
        onDelete={handleDelete}
      />
    </div>

  )
}

const mapStateToProps = (state) => {
  return {
    users: state.users.list,
    fetching: state.users.fetching,
    page: state.users.page,
    sort_field: state.users.sort_field,
    sort_order: state.users.sort_order,
    role: state.users.role,
    search: state.users.search,
    page_size: state.settings.page_size,
    tableScale: state.settings.tableScale,
    isHide: state.settings.isHide,
    isAdmin: state.auth.isAdmin,
    userRole: state.auth.role,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDealers: (page, page_size, sort_field, sort_order, role, search) => dispatch(UserActions.usersRequest(page, page_size, sort_field, sort_order, role, search)),
  dealersReset: () => dispatch(UserActions.usersReset()),
  dealerErrorReset: () => dispatch(UserActions.usersErrorReset()),
  resetSearch: () => dispatch(UserActions.userSearchReset()),
  deleteSubdealer: (id) => dispatch(UserActions.userDeleteRequest(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DealersPage)
