import React, { useEffect } from 'react'
import { Col, Row, Button, Form, Input } from 'antd'
import Images from '../../Images'
import i18n from '../../i18n'

const { TextArea } = Input;

function EditCommentPopupForm(props) {

  const { form, data } = props;

  const {
    getFieldDecorator,
    setFieldsValue,
  } = form;

  useEffect(() => {
    let initialData;
    if (data) {
      initialData = data.asMutable ? data.asMutable({ deep: true }) : { ...data };
    } else {
      initialData = {
        comments: ''
      };
    }

    setFieldsValue(initialData);
    return () => {
    }
  }, [0])

  function handleSubmit(e) {

    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        const data = { ...values }

        props.onSubmit(data);
      }
    });

  }

  return (
    <div className="dialog-container settings-popup-dialog invoice-popup-dialog" >
      <div className="dialog-close-container">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={props.onClose}>
          <path d="M1.66663 1.66667L11 11M11 11L20.3333 20.3333M11 11L20.3333 1.66667M11 11L1.66663 20.3333" stroke="#262F56" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
      <div className="popup-title">{i18n.t("comments")}</div>
      <div className="popup-content">

        {<Form id="edit-comment-popup-form" onSubmit={handleSubmit} disabled>

          <Row gutter={16}>
            <Col className="gutter-row" xl={{span: 16, offset: 4}} xs={24}>
              <Form.Item label={i18n.t("edit_comment")}>
                {getFieldDecorator('comments')(
                  <TextArea rows={10} />
                )}
              </Form.Item>
            </Col>
          </Row>

        </Form>}

      </div>
      <div className="dialog-footer">
        <Button className="btn-primary gray" type="primary" onClick={props.onClose}>
          <img className="btn-icon icon-space" src={Images.cancel} alt="" />
          {i18n.t("cancel")}
        </Button>
        <div style={{ width: '16px' }} />
        <Button form="user-popup-form" htmlType="submit" onClick={handleSubmit} className="btn-primary" type="primary" >
          <img className="btn-icon icon-space" src={Images.check} alt="" />
          {i18n.t("save")}
        </Button>
      </div>
    </div>
  )
}

const WrappedUserEditForm = Form.create({ name: 'edit-comment-popup-form' })(EditCommentPopupForm);

export default WrappedUserEditForm
