import React from 'react';

const Templates = (props) => (
  <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity={props.opacity ? props.opacity : '0.4'}>
      <path d="M4.75 12.3375C4.75 8.56629 4.75 6.68067 5.92157 5.5091C7.09315 4.33752 8.97876 4.33752 12.75 4.33752H14.375C17.065 4.33752 18.41 4.33752 19.39 4.95811C19.8897 5.27454 20.313 5.69784 20.6294 6.19753C21.25 7.17755 21.25 8.52254 21.25 11.2125V11.2125C21.25 13.9025 21.25 15.2475 20.6294 16.2275C20.313 16.7272 19.8897 17.1505 19.39 17.4669C18.41 18.0875 17.065 18.0875 14.375 18.0875H10.5271C10.0812 18.0875 9.85832 18.0875 9.64437 18.1106C8.88305 18.1928 8.1613 18.4917 7.56487 18.972C7.39726 19.1069 7.23964 19.2646 6.9244 19.5798V19.5798C6.53619 19.968 6.34209 20.1621 6.19656 20.2347C5.65258 20.5062 4.99422 20.2335 4.80155 19.6569C4.75 19.5026 4.75 19.2281 4.75 18.6791V12.3375Z" stroke="#262F56" strokeWidth="1.5" strokeLinejoin="round" />
      <path d="M0 8.6709C0 4.89966 0 3.01404 1.17157 1.84247C2.34315 0.670898 4.22876 0.670898 8 0.670898H10.5C13.7875 0.670898 15.4312 0.670898 16.5376 1.57886C16.7401 1.74507 16.9258 1.93079 17.092 2.13333C18 3.23968 18 4.88342 18 8.1709C18 11.4584 18 13.1021 17.092 14.2085C16.9258 14.411 16.7401 14.5967 16.5376 14.7629C15.4312 15.6709 13.7875 15.6709 10.5 15.6709H6.30224C5.66763 15.6709 5.35032 15.6709 5.04737 15.7176C4.41251 15.8155 3.81055 16.0648 3.29242 16.4445C3.04518 16.6257 2.82081 16.8501 2.37207 17.2988C1.81866 17.8522 1.54195 18.1289 1.32073 18.2039C0.84595 18.3646 0.325499 18.149 0.10346 17.6996C0 17.4902 0 17.0989 0 16.3163V8.6709Z" fill={props.color ? props.color : '#262F56'} />
      <path d="M6 11.6709L6.42857 10.1709M12 11.6709L11.5714 10.1709M6.42857 10.1709L7.79278 5.39618C7.91544 4.96688 8.30782 4.6709 8.7543 4.6709H9.2457C9.69218 4.6709 10.0846 4.96688 10.2072 5.39618L11.5714 10.1709M6.42857 10.1709H11.5714" stroke="#E5E5E5" strokeWidth="1.5" strokeLinecap="round" />
    </g>
  </svg>
);

export default Templates;
