import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  navSetProp: ['key', 'value']
})

export const NavTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  isDesktop: window.innerWidth > 1151 ? true : false,
  isShowMobileMenu: false,
  isShowMainFilter: false
})

/* ------------- Selectors ------------- */
//export const isOpenSidebar = (state) => state.nav.sidebarVisible

export const navSetProp = (state: Object, {key, value}) => {
  const obj = {};
  obj[key] = value;

  return state.merge(obj)
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.NAV_SET_PROP]: navSetProp
})
