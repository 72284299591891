import React, { useState, useEffect } from 'react'
import { Layout, Button, Input, Upload, message, Alert } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import BrokerActions from '../../Redux/BrokerRedux'
import NavActions from '../../Redux/NavRedux'
import i18n from '../../i18n'
import Images from '../../Images'

const { Header, Footer } = Layout;
const { Search } = Input;

function BrokerHeader(props) {
  const {broker, settings, isAdmin, uploadFile, nav} = props;

  const [fileUid, setFileUid] = useState(null);

  let history = useHistory();
  function handleNavigate(path) {
    history.push(history.location.pathname + path);
  }

  function logoNavigate(path) {
    if (!isAdmin) {
      history.push(path);
    }
  }

  function handleSearch(val) {
    if (broker.search === val) {
      return;
    }
    props.getBrokerData(1, settings.page_size, null, null, val, broker.filter);
  }

  function onChange({ file, fileList }) {
    if (fileUid && fileUid === file.uid) {
      return;
    }
    setFileUid(file.uid);

    let formData = new FormData();

    if (fileList && fileList.length) {
      formData.set('file', fileList[0].originFileObj, fileList[0].originFileObj.name);
    }
    props.sendUploadFile(formData);
  }

  function handleOpenMobileMenu(e) {
    props.navSetProp('isShowMobileMenu', true)
  }

  useEffect(() => {
    if (uploadFile) {
      message.success(i18n.t("successful_data_upload"), 3);
      props.resetUploadData();
      props.getBrokerData(broker.page, settings.page_size, broker.sort_field, broker.sort_order, broker.search, broker.filter);
    }
  }, [uploadFile])

  return (
    <Header>
      {
        nav.isDesktop ?
          <>
            <div className="header-title-container">{i18n.t("expeditor_broker")}</div>
            <Alert
              message={i18n.t("in_development")}
              showIcon
              icon={<ExclamationCircleOutlined style={{color: '#faad14'}} />}
            />
            <div className="header-buttons-container">
              <Search
                placeholder={i18n.t("search")}
                allowClear
                className="content-search"
                onSearch={handleSearch}
              />
              {
                isAdmin &&
                <>
                  <Upload accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" method="get" onChange={onChange}>
                    <Button className="btn-primary" type="primary" style={{ width: 'auto' }}>
                    <img className="btn-icon icon-space" src={Images.document} alt="" />
                    {i18n.t("upload_data")}
                    </Button>
                  </Upload>
                  <div style={{ width: '8px' }} />
                </>
              }
              <Button className="btn-primary" type="primary blue" style={{ width: 'auto' }}onClick={() => handleNavigate('/settings')}>
                <img className="btn-icon" src={Images.settings} alt="" />
              </Button>
              <div style={{ width: '8px' }} />
              <Button className="btn-primary" type="primary blue" style={{ width: 'auto' }} onClick={() => handleNavigate('/filters')}>
                <img className="btn-icon" src={Images.filters} alt="" />
              </Button>
            </div>
          </>
        :
          <>
            <div className="menu-btn">
              <img className="btn-icon" src={Images.logo} alt="" onClick={() => logoNavigate('/home/dashboard')}/>
              <div className="header-title-mobile">{i18n.t("expeditor_broker")}</div>
              <div className="mobile-btn-wraper" onClick={handleOpenMobileMenu}>
                <img className="btn-icon" src={Images.menu} alt="" />
              </div>
            </div>
            <Alert
              message={i18n.t("in_development_shot")}
              style={{padding: 0, marginTop: '35px'}}
            />
          </>
      }
    </Header>
  )
}

function BrokerFooterComp(props) {
  const {broker, settings, isAdmin, uploadFile} = props;

  const [fileUid, setFileUid] = useState(null);
  const [showSearch, setShowSearch] = useState(false);

  let history = useHistory();
  function handleNavigate(path) {
    history.push(history.location.pathname + path);
  }

  function handleSearch(val) {
    if (broker.search === val) {
      return;
    }
    props.getBrokerData(1, settings.page_size, null, null, val, broker.filter);
  }

  function onChange({ file, fileList }) {
    if (fileUid && fileUid === file.uid) {
      return;
    }
    setFileUid(file.uid);

    let formData = new FormData();

    if (fileList && fileList.length) {
      formData.set('file', fileList[0].originFileObj, fileList[0].originFileObj.name);
    }
    props.sendUploadFile(formData);
  }

  useEffect(() => {
    if (uploadFile) {
      message.success(i18n.t("successful_data_upload"), 3);
      props.resetUploadData();
      props.getBrokerData(broker.page, settings.page_size, broker.sort_field, broker.sort_order, broker.search, broker.filter);
    }
  }, [uploadFile])

  return (
    <Footer>
      <div className="footer-buttons-container">
        <div className="mobile-btn-wraper" onClick={() => setShowSearch(!showSearch)}>
          <img className="btn-icon" src={Images.search} alt="" />
        </div>
        {
          showSearch &&
            <div className="mobile-search">
              <Search
                placeholder={i18n.t("search")}
                allowClear
                className="content-search"
                onSearch={handleSearch}
              />
            </div>
        }
        {
          isAdmin &&
          <>
            <Upload accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" method="get" onChange={onChange}>
              <Button className="btn-primary" type="primary" style={{ width: '40px' }}>
                <img className="btn-icon" src={Images.download} alt="" />
              </Button>
            </Upload>
            <div style={{ width: '8px' }} />
          </>
        }
        <Button className="btn-primary" type="primary blue" style={{ width: '40px' }}onClick={() => handleNavigate('/settings')}>
          <img className="btn-icon" src={Images.settings} alt="" />
        </Button>
        <div style={{ width: '8px' }} />
        <Button className="btn-primary" type="primary blue" style={{ width: '40px' }} onClick={() => handleNavigate('/filters')}>
          <img className="btn-icon" src={Images.filters} alt="" />
        </Button>
      </div>
    </Footer>
  )
}

const mapStateToProps = (state) => {
  return {
    broker: state.broker,
    uploadFile: state.broker.uploadFile,
    settings: state.settings,
    isAdmin: state.auth.isAdmin,
    nav: state.nav,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getBrokerData: (page, page_size, sort_field, sort_order, search, filter) => dispatch(BrokerActions.brokerRequest(page, page_size, sort_field, sort_order, search, filter)),
  sendUploadFile: (data) => dispatch(BrokerActions.brokerSendFileRequest(data)),
  resetUploadData: () => dispatch(BrokerActions.brokerSendFileReset()),
  navSetProp: (key, value) => dispatch(NavActions.navSetProp(key, value)),
})

export const BrokerFooter = connect(mapStateToProps, mapDispatchToProps)(BrokerFooterComp);
export default connect(mapStateToProps, mapDispatchToProps)(BrokerHeader)
