import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import RatesActions from '../Redux/RatesRedux'
import RateLocalTable from '../Components/Tables/RateLocalTable'
import RateOceanTable from '../Components/Tables/RateOceanTable'

function RatesPage(props) {
  const { rates, settings, isDesktop, isAdmin } = props;

  const { item } = props.match.params;
  const [start, setStart] = useState(false);
  const [compHeight, setCompHeight] = useState(null);

  useEffect(() => {
    props.resetFilter();
    props.resetSearch();

    if (item === 'local' && isAdmin) {
      props.getLocalRates(1, settings.page_size);
      props.getLocalRateChoices();
    } else if (item === 'ocean') {
      props.getOceanRates({
        port_arrival: 'Odessa, UA',
        per: 'unit'
      });
      props.getOceanRateChoices();
    }

    setStart(true);

    return function cleanup() {
      props.resetRates();
      setStart(false);
    }
  }, [0]);

  useEffect(() => {
    if (start && isAdmin) {
      props.getLocalRates(1, settings.page_size, rates.sort_field, rates.sort_order, rates.search, rates.filter);
    }
  }, [settings.page_size])

  useEffect(() => {
    if (item) {
      item === 'local' ?
        setCompHeight('calc(100Vh - 128px)')
        :
        setCompHeight('calc(100Vh - 192px)')
    }
  }, [item])

  function handleChangePage(page, page_size, sort_field, sort_order) {
    props.getLocalRates(page, page_size, sort_field, sort_order, rates.search, rates.filter);
  }

  return (
    <div className="content-page" style={{ height: !isDesktop && compHeight }}>
      {
        (item === 'local' && isAdmin) &&
        <RateLocalTable
          data={rates.localList}
          page={rates.page}
          fetching={rates.localFetching}
          pageSize={settings.page_size}
          scale={settings.tableScale}
          isHide={settings.isHide}
          onChangePage={handleChangePage}
        />
      }
      {
        item === 'ocean' &&
        <RateOceanTable
          data={rates.oceanList}
          page={rates.page}
          fetching={rates.oceanFetching}
          pageSize={settings.page_size}
          scale={settings.tableScale}
          isHide={settings.isHide}
          onChangePage={handleChangePage}
        />
      }
    </div>

  )
}

const mapStateToProps = (state) => {
  return {
    rates: state.rates,
    settings: state.settings,
    isDesktop: state.nav.isDesktop,
    isAdmin: state.auth.isAdmin,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getLocalRates: (page, page_size, sort_field, sort_order, search, filter) => dispatch(RatesActions.ratesLocalRequest(page, page_size, sort_field, sort_order, search, filter)),
  getOceanRates: (filter) => dispatch(RatesActions.ratesOceanRequest(filter)),
  resetRates: () => dispatch(RatesActions.ratesReset()),

  getLocalRateChoices: () => dispatch(RatesActions.rateLocalChoicesRequest()),
  getOceanRateChoices: () => dispatch(RatesActions.rateOceanChoicesRequest()),

  resetFilter: () => dispatch(RatesActions.rateFilterReset()),
  resetSearch: () => dispatch(RatesActions.rateSearchReset()),

})

export default connect(mapStateToProps, mapDispatchToProps)(RatesPage)
