import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  usersRequest: ['page', 'page_size', 'sort_field', 'sort_order', 'role', 'search'],
  usersSuccess: ['list'],

  userRequest: ['userId'],
  userSuccess: ['userData'],
  userSetData: ['data'],

  userSaveRequest: ['data'],
  userSaveSuccess: ['saveResult'],
  userSaveReset: null,

  userDeleteRequest: ['id'],
  userDeleteSuccess: ['deleteResult'],
  userDeleteReset: null,

  usersReset: null,
  userSearchReset: null,

  subdealersRequest: null,
  subdealersSuccess: ['subdealers'],

  accountsRequest: ['userId'],
  accountsSuccess: ['accounts'],
  accountsReset: null,

  accountSaveRequest: ['data', 'userId'],
  accountSaveSuccess: ['accountSaveResult'],
  accountSaveReset: null,

  accountDeleteRequest: ['accountId', 'userId'],
  accountDeleteSuccess: ['accountDeleteResult'],
  accountDeleteReset: null,

  profileChangePasswordRequest: ['data'],
  profileChangePasswordSuccess: ['changePasswordResult'],

  userChangePasswordRequest: ['data'],
  userChangePasswordSuccess: ['changePasswordResult'],

  changePasswordReset: null,

  userFetchingReset: null
})

export const UserTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: null,
  fetching: false,

  userData: null,

  saveResult: null,
  saveFetching: false,

  deleteResult: null,
  deleteFetching: false,

  page:1,
  page_size:20,
  sort_field:'id',
  sort_order: 1,
  search:'',
  role: '',

  subdealers: null,

  accounts: null,

  accountSaveResult: null,

  accountDeleteResult: null,

  changePasswordResult: null,
})

/* ------------- Selectors ------------- */
export const UserSelectors = {
  getState: state => state.users,
}

/* ------------- Reducers ------------- */


export const usersRequest = (state: Object, { page, page_size, sort_field, sort_order, role, search }: Object) => {
  const newState = {
    fetching: true
  };
  page && (newState.page = page);
  page_size && (newState.page_size = page_size);
  sort_field && (newState.sort_field = sort_field);
  sort_order && (newState.sort_order = sort_order);
  role != null && (newState.role = role);
  search != null && (newState.search = search);
  return state.merge(newState)
}

export const usersSuccess = (state: Object, { list }: Object) => {
  return state.merge({ fetching: false, list })
}

export const userRequest = (state: Object, { userId }: Object) => {
  return state
}

export const userSuccess = (state: Object, { userData }: Object) => {
  return state.merge({ userData })
}

export const userSetData = (state: Object, { data }: Object) =>{
  return state.merge({ userData:data })
}

export const userSaveRequest = (state: Object, { data }: Object) => {
  return state.merge({ saveFetching: true})
}

export const userSaveSuccess = (state: Object, { saveResult }: Object) => {
  return state.merge({ saveFetching: false, saveResult })
}

export const userSaveReset = (state: Object) =>{
  return state.merge({ saveFetching: false,  saveResult:null })
}

export const userDeleteRequest = (state: Object, { id }: Object) => {
  return state.merge({ deleteFetching: true})
}

export const userDeleteSuccess = (state: Object, { deleteResult }: Object) => {
  return state.merge({ deleteFetching: false, deleteResult })
}

export const userDeleteReset = (state: Object) =>{
  return state.merge({ deleteFetching: false,  deleteResult:null })
}

export const usersReset = (state: Object) =>{
  return INITIAL_STATE
}

export const userSearchReset = (state: Object) => {
  return state.merge({ search: '' })
}

export const subdealersRequest = (state: Object) => {
  return state.merge({ fetching: true})
}

export const subdealersSuccess = (state: Object, { subdealers }: Object) => {
  return state.merge({ fetching: false, subdealers })
}

export const accountsRequest = (state: Object, { userId }: Object) => {
  return state
}

export const accountsSuccess = (state: Object, { accounts }: Object) => {
  return state.merge({ accounts })
}

export const accountsReset = (state: Object) => {
  return state.merge({ accounts:null })
}

export const accountSaveRequest = (state: Object, { data, userId }: Object) => {
  return state
}

export const accountSaveSuccess = (state: Object, { accountSaveResult }: Object) => {
  return state.merge({ accountSaveResult })
}

export const accountSaveReset = (state: Object) =>{
  return state.merge({ accountSaveResult:null })
}

export const accountDeleteRequest = (state: Object, { accountId, userId }: Object) => {
  return state
}

export const accountDeleteSuccess = (state: Object, { accountDeleteResult }: Object) => {
  return state.merge({ accountDeleteResult })
}

export const accountDeleteReset = (state: Object) =>{
  return state.merge({ accountDeleteResult:null })
}

export const profileChangePasswordRequest = (state: Object, { data }: Object) => {
  return state
}

export const profileChangePasswordSuccess = (state: Object, { changePasswordResult }: Object) => {
  return state.merge({ changePasswordResult })
}

export const userChangePasswordRequest = (state: Object, { data }: Object) => {
  return state
}

export const userChangePasswordSuccess = (state: Object, { changePasswordResult }: Object) => {
  return state.merge({ changePasswordResult })
}

export const changePasswordReset = (state: Object) =>{
  return state.merge({ changePasswordResult:null })
}

export const userFetchingReset = (state: Object) => {
  return state.merge({ fetching:false, saveFetching:false, deleteFetching:false })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.USERS_REQUEST]: usersRequest,
  [Types.USERS_SUCCESS]: usersSuccess,

  [Types.USER_REQUEST]: userRequest,
  [Types.USER_SUCCESS]: userSuccess,
  [Types.USER_SET_DATA]: userSetData,

  [Types.USER_SAVE_REQUEST]: userSaveRequest,
  [Types.USER_SAVE_SUCCESS]: userSaveSuccess,
  [Types.USER_SAVE_RESET]: userSaveReset,

  [Types.USER_DELETE_REQUEST]: userDeleteRequest,
  [Types.USER_DELETE_SUCCESS]: userDeleteSuccess,
  [Types.USER_DELETE_RESET]: userDeleteReset,

  [Types.USERS_RESET]: usersReset,
  [Types.USER_SEARCH_RESET]: userSearchReset,

  [Types.SUBDEALERS_REQUEST]: subdealersRequest,
  [Types.SUBDEALERS_SUCCESS]: subdealersSuccess,

  [Types.ACCOUNTS_REQUEST]: accountsRequest,
  [Types.ACCOUNTS_SUCCESS]: accountsSuccess,
  [Types.ACCOUNTS_RESET]: accountsReset,

  [Types.ACCOUNT_SAVE_REQUEST]: accountSaveRequest,
  [Types.ACCOUNT_SAVE_SUCCESS]: accountSaveSuccess,
  [Types.ACCOUNT_SAVE_RESET]: accountSaveReset,

  [Types.ACCOUNT_DELETE_REQUEST]: accountDeleteRequest,
  [Types.ACCOUNT_DELETE_SUCCESS]: accountDeleteSuccess,
  [Types.ACCOUNT_DELETE_RESET]: accountDeleteReset,

  [Types.PROFILE_CHANGE_PASSWORD_REQUEST]: profileChangePasswordRequest,
  [Types.PROFILE_CHANGE_PASSWORD_SUCCESS]: profileChangePasswordSuccess,

  [Types.USER_CHANGE_PASSWORD_REQUEST]: userChangePasswordRequest,
  [Types.USER_CHANGE_PASSWORD_SUCCESS]: userChangePasswordSuccess,

  [Types.CHANGE_PASSWORD_RESET]: changePasswordReset,

  [Types.USER_FETCHING_RESET]: userFetchingReset,
})
