import React, { useEffect } from 'react'
import { Col, Row, Button, Form, Input, InputNumber, Select } from 'antd'
import Images from '../../Images'
import IconCustom from '../../Icon'
import i18n from '../../i18n'
import AccountForm from './AccountForm'

const { Option } = Select;

function DealerPopupForm(props) {

  const { form, columbs, users, edit, isProfile, isAdmin } = props;

  const {
    getFieldDecorator,
    setFieldsValue,
  } = form;

  const columbsList = [];
  if (columbs) {
    for (let i = 0; i < columbs.length; i++) {
      columbsList.push(<Option key={columbs[i]}>{columbs[i]}</Option>);
    }
  }

  useEffect(() => {
    let initialData;
    if (edit) {
      initialData = edit.asMutable ? edit.asMutable({ deep: true }) : { ...edit };
      setFieldsValue(initialData);
    }
  }, [edit])

  let non_field_errors = null;

  function handleSubmit(e) {

    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        const data = { ...values }

        if (edit) {
          data.id = edit.id;
        }

        props.onSubmit(data);
      }
    });

  }

  function resetData() {
    props.onClose();
  }

  return (
    <div className="dialog-container settings-popup-dialog invoice-popup-dialog" >
      <div className="dialog-close-container">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={props.onClose}>
          <path d="M1.66663 1.66667L11 11M11 11L20.3333 20.3333M11 11L20.3333 1.66667M11 11L1.66663 20.3333" stroke="#262F56" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
      {
        isProfile ?
          <div className="popup-title">{i18n.t("edit_profile")}</div>
        :
          <div className="popup-title">{i18n.t(edit ? "edit_dealer" : "add_dealer")}</div>
      }
      <div className="popup-content">

        {<Form id="dealer-popup-form" onSubmit={handleSubmit} disabled>

          {non_field_errors}
          <Row gutter={16} className="flex-row">
            <Col className="gutter-row" xl={8} md={12} xs={24}>
              <Form.Item label={i18n.t("login")+'*'}>
                {getFieldDecorator('login', {
                  rules: [{ required: true, message: i18n.t("required_field") }],
                })(
                  <Input />,
                )}
              </Form.Item>
            </Col>
            {
              !edit &&
                <Col className="gutter-row" xl={8} md={12} xs={24}>
                  <Form.Item label={i18n.t("password")+'*'}>
                    {getFieldDecorator('password', {
                      rules: [{ required: true, message: i18n.t("required_field") }],
                    })(
                      <Input.Password
                        size="large"
                        type="password"
                        autoComplete="new-password"
                      />,
                    )}
                  </Form.Item>
                </Col>
            }
            <Col className="gutter-row" xl={8} md={12} xs={24}>
              <Form.Item label={i18n.t("name_and_surname")+'*'}>
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: i18n.t("required_field") }],
                })(
                  <Input />,
                )}
              </Form.Item>
            </Col>
            {
              edit &&
                <Col className="gutter-row" xl={8} md={12} xs={24}>
                  <Form.Item label={i18n.t("company")}>
                    {getFieldDecorator('company')(
                      <Input />,
                    )}
                  </Form.Item>
                </Col>
            }

            {
              !edit &&
                <Col className="gutter-row" xl={8} md={12} xs={24}>
                  <Form.Item label={i18n.t("company")}>
                    {getFieldDecorator('company')(
                      <Input />,
                    )}
                  </Form.Item>
                </Col>
            }
            {
              isAdmin &&
                <Col className="gutter-row" xl={8} md={12} xs={24}>
                  <Form.Item label="Columb Auto ID">
                    {getFieldDecorator('columb_trade_id')(
                      <Select
                        showSearch={true}
                        size="large"
                        showArrow={true}
                        suffixIcon={<IconCustom type="select" />}
                      >
                        {columbsList}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
            }
          </Row>

          <Row gutter={16} className="flex-row">
            <Col className="gutter-row" xl={8} md={12} xs={24}>
              <Form.Item label={i18n.t("country")}>
                {getFieldDecorator('country')(
                  <Input />,
                )}
              </Form.Item>
            </Col>
            <Col className="gutter-row" xl={8} md={12} xs={24}>
              <Form.Item label={i18n.t("city")}>
                {getFieldDecorator('city')(
                  <Input />,
                )}
              </Form.Item>
            </Col>
            <Col className="gutter-row" xl={8} md={12} xs={24}>
              <Form.Item label={i18n.t("address")}>
                {getFieldDecorator('address')(
                  <Input />,
                )}
              </Form.Item>
            </Col>

            <Col className="gutter-row" xl={8} md={12} xs={24}>
            <Form.Item label={i18n.t("index")}>
                {getFieldDecorator('zip_code')(
                  <InputNumber min={0} />
                )}
              </Form.Item>
            </Col>
            <Col className="gutter-row" xl={8} md={12} xs={24}>
              <Form.Item label={i18n.t("phone")}>
                {getFieldDecorator('phone')(
                  <Input />,
                )}
              </Form.Item>
            </Col>
            <Col className="gutter-row" xl={8} md={12} xs={24}>
              <Form.Item label="Email">
                {getFieldDecorator('email', {
                  rules: [{ type: 'email', message: i18n.t("invalid_email") }],
                })(
                  <Input />,
                )}
              </Form.Item>
            </Col>

            <Col className="gutter-row" xl={8} md={12} xs={24}>
              <Form.Item label="Telegram">
                {getFieldDecorator('telegram')(
                  <Input />,
                )}
              </Form.Item>
            </Col>
            <Col className="gutter-row" xl={8} md={12} xs={24}>
              <Form.Item label="Viber">
                {getFieldDecorator('viber')(
                  <Input />,
                )}
              </Form.Item>
            </Col>
          </Row>

          {
            !isProfile &&
              <Row gutter={16} className="flex-row">
                <Col className="gutter-row" xl={8} md={12} xs={24}>
                <Form.Item label={i18n.t("local_extra")}>
                    {getFieldDecorator('local_extra')(
                      <InputNumber min={0} />
                    )}
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xl={8} md={12} xs={24}>
                  <Form.Item label={i18n.t("ocean_extra")}>
                    {getFieldDecorator('ocean_extra')(
                      <InputNumber min={0} />
                    )}
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xl={8} md={12} xs={24}>
                  <Form.Item label={i18n.t("total_extra")}>
                    {getFieldDecorator('total_extra')(
                      <InputNumber min={0} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
          }

        </Form>}

      </div>
      {
        isProfile &&
          <>
             <div className="popup-title">{i18n.t("accounts")}</div>
            <AccountForm
              accounts={users.accounts}
              accountPreList={users.accountPreList}
              accountSaveResult={users.accountSaveResult}
              accountDeleteResult={users.accountDeleteResult}
              user={edit}
              addAccount={props.addAccount}
              deleteAccount={props.deleteAccount}
            />
          </>
      }
      <div className="dialog-footer">
        <Button className="btn-primary gray" type="primary" onClick={resetData}>
          <img className="btn-icon icon-space" src={Images.cancel} alt="" />
          {i18n.t("cancel")}
        </Button>
        <div style={{ width: '16px' }} />
        <Button form="user-popup-form" htmlType="submit" onClick={handleSubmit} className="btn-primary" type="primary" >
          <img className="btn-icon icon-space" src={Images.check} alt="" />
          {i18n.t("save")}
        </Button>
      </div>
    </div>
  )
}

const WrappedDealerForm = Form.create({ name: 'dealer-popup-form' })(DealerPopupForm);

export default WrappedDealerForm
