import React, { useEffect } from 'react'
import { Col, Row, Button, Form, Input, InputNumber } from 'antd'
import Images from '../../Images'
import i18n from '../../i18n'

function FeePopupForm(props) {

  const { form, edit, isProfile } = props;

  const {
    getFieldDecorator,
    setFieldsValue,
  } = form;

  useEffect(() => {
    let initialData;
    if (edit) {
      initialData = edit.asMutable ? edit.asMutable({ deep: true }) : { ...edit };
      setFieldsValue(initialData);
    }
  }, [edit])

  function handleSubmit(e) {

    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        const data = { ...values }

        if (edit) {
          data.id = edit.id;
        }

        props.onSubmit(data);
      }
    });

  }

  return (
    <div className="dialog-container settings-popup-dialog invoice-popup-dialog" >
      <div className="dialog-close-container">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={props.onClose}>
          <path d="M1.66663 1.66667L11 11M11 11L20.3333 20.3333M11 11L20.3333 1.66667M11 11L1.66663 20.3333" stroke="#262F56" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
      <div className="popup-title">{i18n.t(edit ? "edit_fee_template" : "create_fee_template")}</div>
      <div className="popup-content">

        {<Form id="fee-popup-form" onSubmit={handleSubmit} disabled>
          {
            isProfile &&
              <Row gutter={16}>
                <Col className="gutter-row" xl={{span: 10, offset: 7}} xs={24}>
                  <Form.Item label={i18n.t("fee_template_name")}>
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: i18n.t("required_field") }],
                    })(
                      <Input />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
          }

          <Row gutter={16}>
            <Col className="gutter-row"  lg={6}  md={12} xs={24}>
              <Form.Item label={i18n.t("auto_sbor")}>
                {getFieldDecorator('c_price_sbor')(
                  <InputNumber min={0} />
                )}
              </Form.Item>
            </Col>
            <Col className="gutter-row" lg={6}  md={12} xs={24}>
              <Form.Item label={i18n.t("parking")}>
                {getFieldDecorator('c_parking')(
                  <InputNumber min={0} />
                )}
              </Form.Item>
            </Col>
            <Col className="gutter-row" lg={6}  md={12} xs={24}>
              <Form.Item label={i18n.t("add_payment")}>
                {getFieldDecorator('c_add_payment')(
                  <InputNumber min={0} />
                )}
              </Form.Item>
            </Col>
            <Col className="gutter-row" lg={6}  md={12} xs={24}>
              <Form.Item label={i18n.t("local_transp")}>
                {getFieldDecorator('c_local_transp')(
                  <InputNumber min={0} />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col className="gutter-row" lg={6}  md={12} xs={24}>
              <Form.Item label="Sales_tax">
                {getFieldDecorator('c_fine_memo')(
                  <InputNumber min={0} />
                )}
              </Form.Item>
            </Col>
            <Col className="gutter-row" lg={6}  md={12} xs={24}>
              <Form.Item label={i18n.t("insurance")}>
                {getFieldDecorator('c_insurance')(
                  <InputNumber min={0} />
                )}
              </Form.Item>
            </Col>
            <Col className="gutter-row" lg={6}  md={12} xs={24}>
              <Form.Item label={i18n.t("ocean_transp")}>
                {getFieldDecorator('c_ocean_transp')(
                  <InputNumber min={0} />
                )}
              </Form.Item>
            </Col>
            <Col className="gutter-row" lg={6}  md={12} xs={24}>
              <Form.Item label={i18n.t("doc_tranfered")}>
                {getFieldDecorator('c_doc_tranfered')(
                  <InputNumber min={0} />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col className="gutter-row" lg={6}  md={12} xs={24}>
              <Form.Item label={i18n.t("office_fee")}>
                {getFieldDecorator('c_office_fee')(
                  <InputNumber min={0} />
                )}
              </Form.Item>
            </Col>
            <Col className="gutter-row" lg={6}  md={12} xs={24}>
              <Form.Item label={i18n.t("storage")}>
                {getFieldDecorator('c_storage')(
                  <InputNumber min={0} />
                )}
              </Form.Item>
            </Col>
          </Row>

        </Form>}

      </div>
      <div className="dialog-footer">
        <Button className="btn-primary gray" type="primary" onClick={props.onClose}>
          <img className="btn-icon icon-space" src={Images.cancel} alt="" />
          {i18n.t("cancel")}
        </Button>
        <div style={{ width: '16px' }} />
        <Button form="user-popup-form" htmlType="submit" onClick={handleSubmit} className="btn-primary" type="primary" >
          <img className="btn-icon icon-space" src={Images.check} alt="" />
          {i18n.t("save")}
        </Button>
      </div>
    </div>
  )
}

const WrappedUserEditForm = Form.create({ name: 'fee-popup-form' })(FeePopupForm);

export default WrappedUserEditForm
