import React from 'react'
import PaymentsTable from '../Components/Tables/PaymentsTable'

function AutoPayment(props) {
  const { data } = props;

  return (
    data &&
      <PaymentsTable
        {...props}
        isShot={true}
      />
  )
}

export default AutoPayment
