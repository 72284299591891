import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'antd'
import moment from 'moment'
import Images from '../../Images'
import PaginationItemRender from '../PaginationItemRender'
import { titleWithSortFilters } from '../titleWithSortFilters'
import i18n from '../../i18n'
import CustomIcon from '../../Icon'
import utils from '../../Utils/utils'

import './Table.css'

function CarsTable(props){
  const {
    page,
    pageSize,
    scale,
    isHide,
    data,
    history,
    role,
    setFocus,
    fetching
  } = props;

  const [dataSource, setDataSource] = useState(null);

  useEffect(() => {
    setDataSource(data && data.results ? data.results.asMutable({deep:true}) : []);
  }, [data]);

  function handleNavigate(record) {
    setFocus(record);
    history.push('/home/cars/edit-comment')
  }

  function getColumns() {
    return [
      {
        title: (i18n.t("photo")),
        dataIndex: 'image',
        key: 'image',
        className: isHide && 'hidden-column',
        render: (text, record) => {
          return (
            <span className="car-img" style={{backgroundImage: 'url(' + text + ')'}}></span>
          )
        },
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("purchase_date"), true, false, stateData, 'purchase_date'),
        dataIndex: 'purchase_date',
        key: 'purchase_date',
        sorter: true,
        className: isHide && 'hidden-column',
        render: (text, record) => {
          return (
              <span>{text && moment(text).format('DD.MM.YYYY')}</span>
          )
        },
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("auction"), true, false, stateData, 'auction'),
        dataIndex: 'auction',
        key: 'auction',
        sorter:true,
        className: isHide && 'hidden-column',
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("auto_description"), true, false, stateData, 'description'),
        dataIndex: 'description',
        key: 'description',
        sorter: true,
        className: isHide && 'hidden-column',
      },
      {
        title: (stateData)=>titleWithSortFilters("VIN", true, false, stateData, 'vin'),
        dataIndex: 'vin',
        key: 'vin',
        sorter: true,
        render: (text, record) => {
          return (
            <Link to={'/home/car/' + text}>{text}</Link>
          )
        },
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("subdealer"), true, false, stateData, 'sub_dealer'),
        dataIndex: 'sub_dealer',
        key: 'sub_dealer',
        sorter: true,
        className: (role !== 0 || isHide) && 'hidden-column'
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("lot_number"), true, false, stateData, 'lot_number'),
        dataIndex: 'lot_number',
        key: 'lot_number',
        sorter: true,
        className: isHide && 'hidden-column',
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("auction_location"), true, false, stateData, 'auction_location'),
        dataIndex: 'auction_location',
        key: 'auction_location',
        sorter: true,
        className: isHide && 'hidden-column',
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("btrim"), true, false, stateData, 'btrim'),
        dataIndex: 'btrim',
        key: 'btrim',
        sorter: true,
        className: isHide && 'hidden-column',
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("autoprice_sbor"), true, false, stateData, 'c_price_sbor'),
        dataIndex: 'c_price_sbor',
        key: 'c_price_sbor',
        sorter: true,
        className: isHide && 'hidden-column',
        render: text => <span>{utils.round10Value(text)}</span>
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("local_transp"), true, false, stateData, 'c_local_transp'),
        dataIndex: 'c_local_transp',
        key: 'c_local_transp',
        sorter: true,
        render: text => <span>{utils.round10Value(text)}</span>
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("ocean_transp"), true, false, stateData, 'c_ocean_transp'),
        dataIndex: 'c_ocean_transp',
        key: 'c_ocean_transp',
        sorter: true,
        render: text => <span>{utils.round10Value(text)}</span>
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("storage"), true, false, stateData, 'c_storage'),
        dataIndex: 'c_storage',
        key: 'c_storage',
        sorter: true,
        className: isHide && 'hidden-column',
        render: text => <span>{utils.round10Value(text)}</span>
      },
      {
        title: (stateData)=>titleWithSortFilters("Sales_tax", true, false, stateData, 'c_fine_memo'),
        dataIndex: 'c_fine_memo',
        key: 'c_fine_memo',
        sorter: true,
        className: isHide && 'hidden-column',
        render: text => <span>{utils.round10Value(text)}</span>
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("doc_tranfered"), true, false, stateData, 'c_doc_tranfered'),
        dataIndex: 'c_doc_tranfered',
        key: 'c_doc_tranfered',
        sorter: true,
        className: isHide && 'hidden-column',
        render: text => <span>{utils.round10Value(text)}</span>
      },
      {
        title: (stateData)=>titleWithSortFilters("Copart services", true, false, stateData, 'copart_service'),
        dataIndex: 'copart_service',
        key: 'copart_service',
        sorter: true,
        className: isHide && 'hidden-column',
        render: text => <span>{utils.round10Value(text)}</span>
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("add_payment"), true, false, stateData, 'c_add_payment'),
        dataIndex: 'c_add_payment',
        key: 'c_add_payment',
        sorter: true,
        className: isHide && 'hidden-column',
        render: text => <span>{utils.round10Value(text)}</span>
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("insurance"), true, false, stateData, 'c_insurance'),
        dataIndex: 'c_insurance',
        key: 'c_insurance',
        sorter: true,
        className: isHide && 'hidden-column',
        render: text => <span>{utils.round10Value(text)}</span>
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("office_fee"), true, false, stateData, 'c_office_fee'),
        dataIndex: 'c_office_fee',
        key: 'c_office_fee',
        sorter: true,
        className: isHide && 'hidden-column',
        render: text => <span>{utils.round10Value(text)}</span>
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("total_cost"), true, false, stateData, 'c_total_cost'),
        dataIndex: 'c_total_cost',
        key: 'c_total_cost',
        sorter: true,
        render: text => <span>{utils.round10Value(text)}</span>
      },
      // {
      //   title: (stateData)=>titleWithSortFilters(i18n.t("swift"), true, false, stateData, 'b_swift_exists'),
      //   dataIndex: 'b_swift_exists',
      //   key: 'b_swift_exists',
      //   sorter: true,
      //   className: isHide && 'hidden-column',
      // },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("cabinet"), true, false, stateData, 'total_payments_inter'),
        dataIndex: 'total_payments_inter',
        key: 'total_payments_inter',
        sorter: true,
        className: isHide && 'hidden-column',
        render: text => <span>{utils.round10Value(text)}</span>
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("payment_to_auction"), true, false, stateData, 'payment_auction'),
        dataIndex: 'payment_auction',
        key: 'payment_auction',
        sorter: true,
        className: isHide && 'hidden-column',
        render: text => <span>{utils.round10Value(text)}</span>
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("cabinet_2"), true, false, stateData, 'a_cabinet_cash'),
        dataIndex: 'a_cabinet_cash',
        key: 'a_cabinet_cash',
        sorter: true,
        className: isHide && 'hidden-column',
        render: text => <span>{utils.round10Value(text)}</span>
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("pc"), true, false, stateData, 'a_rc'),
        dataIndex: 'a_rc',
        key: 'a_rc',
        sorter: true,
        className: isHide && 'hidden-column',
        render: text => <span>{utils.round10Value(text)}</span>
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("client_paid_calc"), true, false, stateData, 'client_paid_calc'),
        dataIndex: 'client_paid_calc',
        key: 'client_paid_calc',
        sorter: true,
        render: text => <span>{utils.round10Value(text)}</span>
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("balance"), true, false, stateData, 'plus_minus_calc'),
        dataIndex: 'plus_minus_calc',
        key: 'plus_minus_calc',
        sorter: true,
        render: text => <span>{utils.round10Value(text)}</span>
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("carrier"), true, false, stateData, 'carrier'),
        dataIndex: 'carrier',
        key: 'carrier',
        sorter: true,
        className: isHide && 'hidden-column',
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("port_of_arrival"), true, false, stateData, 'port_of_arrival'),
        dataIndex: 'port_of_arrival',
        key: 'port_of_arrival',
        sorter: true,
        className: isHide && 'hidden-column',
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("line_booking_container"), true, false, stateData, 'line_booking_container'),
        dataIndex: 'line_booking_container',
        key: 'line_booking_container',
        sorter: true,
        className: isHide && 'hidden-column',
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("status"), true, false, stateData, 'status'),
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        className: isHide && 'hidden-column',
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("comments"), true, false, stateData, 'comments'),
        dataIndex: 'comments',
        key: 'comments',
        sorter: true,
        className: isHide && 'hidden-column',
      },
      {
        title: (""),
        dataIndex: 'id',
        key: 'id',
        render: (text, record) => {
          return (
            <span className="download-icon" onClick={() => handleNavigate(record)}><img src={Images.edit} alt=""/></span>
          )
        },
        className: isHide && 'hidden-column',
      }
    ];
  }

  function handleTableChange(pagination, filters, sorter){

    var sort_field = null;
    var sort_order = null;

    if(sorter && sorter.field && sorter.order){
      sort_field = sorter.field;
      sort_order = sorter.order //=== "ascend" ? 1 : 0;
    }

    props.onChangePage(pagination.current, pagination.pageSize, sort_field, sort_order)

  }

  const totalCount = data ? data.count : 0;

  return (
    <Table
      loading={{spinning:fetching, indicator:<div className="loader"><CustomIcon type="loader" /></div>}}
      pagination= {{itemRender:PaginationItemRender, current:page, pageSize:pageSize, total:totalCount}}
      className="main-table auto"
      style={{fontSize: (scale * 14 / 100) + 'px'}}
      rowKey={row => row.vin}
      rowClassName="process-table-row"
      dataSource={dataSource}
      columns={getColumns()}
      scrollToFirstRowOnChange
      onChange={handleTableChange}
      sortDirections={['descend', 'ascend']}
    />
   )


}

export default CarsTable
