import React from 'react';

const User = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity={props.opacity ? props.opacity : '0.4'}>
      <ellipse cx="12" cy="6.5" rx="4.5" ry="5" fill="#262F56" />
      <path d="M21 19C21 22.0376 16.4183 21 12 21C7.58172 21 3 22.0376 3 19C3 15.9624 7.58172 13.5 12 13.5C16.4183 13.5 21 15.9624 21 19Z" fill={props.color ? props.color : "#262F56"} />
    </g>
  </svg>

);

export default User;
