import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  ratesLocalRequest: ['page', 'page_size', 'sort_field', 'sort_order', 'search', 'filter'],
  ratesLocalSuccess: ['localList'],

  ratesOceanRequest: ['filter'],
  ratesOceanSuccess: ['oceanList'],

  rateLocalChoicesRequest: null,
  rateLocalChoicesSuccess: ['data'],

  rateOceanChoicesRequest: null,
  rateOceanChoicesSuccess: ['data'],

  ratesReset: null,
  rateFilterReset: null,
  rateSearchReset: null,

  rateFetchingReset: null
})

export const RatesTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  localList: null,
  localFetching: false,

  oceanList: null,
  oceanFetching: false,

  localPortList: null,
  stateList: null,
  auctionList: null,

  oceanPortList: null,
  containerSizeList: null,

  page: 1,
  sort_field: '',
  sort_order: 1,
  search: '',
  filter: {
    port: '',
    state: '',
    auction: '',
    port_arrival: 'Odessa, UA',
    per: 'unit',
    container_size: '',
    price_per_container_min: '',
    price_per_container_max: ''
  },

})

/* ------------- Selectors ------------- */
export const RatesSelectors = {
  getState: (state) => state.rates
}

/* ------------- Reducers ------------- */

export const ratesLocalRequest = (state: Object, {page, page_size, sort_field, sort_order, search, filter}: Object) => {
  const newState = {
    localFetching: true,
  };
  page && (newState.page = page);
  page_size && (newState.page_size = page_size);
  sort_field && (newState.sort_field = sort_field);
  sort_order && (newState.sort_order = sort_order);
  search != null && (newState.search = search);
  filter && (newState.filter = state.filter.merge(filter));
  return state.merge(newState)
}

export const ratesLocalSuccess = (state: Object, { localList }: Object) => {
  return state.merge({ localFetching: false, localList })
}

export const ratesOceanRequest = (state: Object, {filter}: Object) => {
  const newState = {
    oceanFetching: true,
  };
  filter && (newState.filter = state.filter.merge(filter));
  return state.merge(newState)
}

export const ratesOceanSuccess = (state: Object, { oceanList }: Object) => {
  return state.merge({ oceanFetching: false, oceanList })
}

export const rateLocalChoicesRequest = (state: Object) => {
  return state
}

export const rateLocalChoicesSuccess = (state: Object, { data }: Object) => {
  return state.merge({ localPortList: data.ports, stateList: data.states, auctionList: data.auctions })
}

export const rateOceanChoicesRequest = (state: Object) => {
  return state
}

export const rateOceanChoicesSuccess = (state: Object, { data }: Object) => {
  return state.merge({ oceanPortList: data.ports_arrival, containerSizeList: data.count_cars })
}

export const ratesReset = (state: Object) => {
  return INITIAL_STATE
}

export const rateFilterReset = (state: Object) => {
  const newState = {
    filter: {
      port: '',
      state: '',
      auction: '',
      port_arrival: 'Odessa, UA',
      per: 'unit',
      container_size: '',
      price_per_container_min: '',
      price_per_container_max: ''
    }
  };
  return state.merge(newState)
}

export const rateSearchReset = (state: Object) => {
  return state.merge({ search: '' })
}

export const rateFetchingReset = (state: Object) => {
  return state.merge({ localFetching:false, oceanFetching:false })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.RATES_LOCAL_REQUEST]: ratesLocalRequest,
  [Types.RATES_LOCAL_SUCCESS]: ratesLocalSuccess,

  [Types.RATES_OCEAN_REQUEST]: ratesOceanRequest,
  [Types.RATES_OCEAN_SUCCESS]: ratesOceanSuccess,

  [Types.RATE_LOCAL_CHOICES_REQUEST]: rateLocalChoicesRequest,
  [Types.RATE_LOCAL_CHOICES_SUCCESS]: rateLocalChoicesSuccess,

  [Types.RATE_OCEAN_CHOICES_REQUEST]: rateOceanChoicesRequest,
  [Types.RATE_OCEAN_CHOICES_SUCCESS]: rateOceanChoicesSuccess,

  [Types.RATES_RESET]: ratesReset,
  [Types.RATE_FILTER_RESET]: rateFilterReset,
  [Types.RATE_SEARCH_RESET]: rateSearchReset,

  [Types.RATE_FETCHING_RESET]: rateFetchingReset,
})
