import React, { useEffect} from 'react'
import { connect } from 'react-redux'
import {useHistory} from 'react-router-dom'
import { message } from 'antd'
import FeesActions from '../../Redux/FeesRedux'
import FeePopupForm from '../../Components/Forms/FeePopupForm'
import i18n from '../../i18n'
import utils from '../../Utils/utils'

function FeePopupPage (props) {
  const { result, customResult, edit, location, match } = props,
        {vin} = match.params;

  const isProfile = location.pathname.includes('/home/profile');

  let history = useHistory();
  function handleClose() {
    props.dataReset();
    props.customDataReset();
    let path = utils.cutUrlToPrevious(history.location.pathname);
    history.push(path);
  }

  function handleSubmit(data){
    if (isProfile) {
      props.saveFee(data);
    } else {
      data.car = edit.car;
      data.sub_dealer = edit.sub_dealer.id;
      props.saveCustomFee(data);
    }
  }

  useEffect(()=>{
    if (result) {
      message.success(i18n.t("successful_fee_template"), 2);
      handleClose();
    }
    if (customResult) {
      message.success(i18n.t("successful_fee_template"), 2);
      props.getCustomFee(vin);
      handleClose();
    }
  }, [result, customResult]);

  return (
      <div className="popup-dialog">
        <FeePopupForm
          edit={edit}
          isProfile={isProfile}
          onClose={handleClose}
          onSubmit={handleSubmit}
          />
      </div>
  )
}

const mapStateToProps = (state) => {
  return {
    result: state.fees.saveResult,
    customResult: state.fees.saveFeeCustomResult,
    edit: state.fees.focusRow,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getCustomFee: (vin) => dispatch(FeesActions.feeCustomRequest(vin)),
  saveFee: (data) => dispatch(FeesActions.feeSaveRequest(data)),
  saveCustomFee: (data) => dispatch(FeesActions.feeCustomSaveRequest(data)),
  dataReset: () => dispatch(FeesActions.feeSaveReset()),
  customDataReset: () => dispatch(FeesActions.feeCustomSaveReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(FeePopupPage);
