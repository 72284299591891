import React, { useState, useEffect} from 'react'
import { connect } from 'react-redux'
import {useHistory} from 'react-router-dom'

import InvoicesActions from '../../Redux/InvoicesRedux'
import CarsActions from '../../Redux/CarsRedux'
import InvoicePopupForm from '../../Components/Forms/InvoicePopupForm'

function InvoicePopupPage (props) {
  const {invoices, cars, isAdmin} = props;

  const [pages, setPages] = useState(null);

  let history = useHistory();

  useEffect(()=>{
    props.getCarChoices(1, cars.page_size_choice);
    return function cleanup() {
      props.resetCarChoices();
    }
  }, [0]);

  useEffect(()=>{
    if(cars.carChoices) {
      setPages(Math.floor(cars.carChoices.count / cars.page_size_choice));
    }
  }, [cars.carChoices]);

  useEffect(()=>{
    if(invoices.saveResult) {
      history.push('/home/invoices');
      props.invoiceSaveReset();

    }
  }, [invoices.saveResult]);

  function handleClose (e){
    props.invoiceSetFocusRow(null);
    props.invoiceSaveReset();
    history.push('/home/invoices');
  }

  function handleSubmit(data) {
    props.invoiceSaveRequest(data)
  }

  function handleDelete(row) {
    props.invoiceDeleteRequest(row.id);
  }

  function paginateCar() {
    if (cars.page_choice < pages) {
      props.getCarChoices(cars.page_choice + 1, cars.page_size_choice);
    }
  }

  function searchCar(search) {
    props.getCarChoices(1, cars.page_size_choice, search || '');
  }

  return (
    <div className="popup-dialog">
      <InvoicePopupForm
        cars={cars.carChoices}
        carSearch={cars.search_choice}
        auctions={invoices.auctions}
        banks={invoices.banks}
        companies={invoices.companies}
        isAdmin={isAdmin}
        fetching={cars.carChoicesFetching}
        onClose={handleClose}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        paginateCar={paginateCar}
        searchCar={searchCar}
        />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    invoices: state.invoices,
    cars: state.cars,
    isAdmin: state.auth.isAdmin,
  }
}

const mapDispatchToProps = (dispatch) => ({
  invoiceSaveRequest: (data) => dispatch(InvoicesActions.invoiceSaveRequest(data)),
  invoiceSaveReset: () => dispatch(InvoicesActions.invoiceSaveReset()),
  invoiceSetFocusRow: (row) => dispatch(InvoicesActions.invoiceSetFocusRow(row)),
  invoiceDeleteRequest:(id) => dispatch(InvoicesActions.invoiceDeleteRequest(id)),
  invoiceDeleteReset: () => dispatch(InvoicesActions.invoiceDeleteReset()),

  getCarChoices: (page, page_size, search) => dispatch(CarsActions.carChoicesRequest(page, page_size, search)),
  resetCarChoices: () => dispatch(CarsActions.carChoicesReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePopupPage);
