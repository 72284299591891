/**
 * Утилиты
 */
const AppUtils = {
  // Получить сообщение локализации
  getMes(context) {
    const ct = context.intl;
    return function res(mesId, values) {
      try {
        return ct.formatMessage(mesId, values);
      } catch (e) {
        return mesId;
      }
    };
  },

  // валидация и кориктеровка redirect uri
  controlRedirectUrl(redirectUrl, whitelist) {
    if (!redirectUrl) return '';
    // проверка по вайт листу, если он есть
    if (whitelist && whitelist.length > 0) {
      let isRedirect = false;
      whitelist.forEach(item => {
        if (redirectUrl.indexOf(item) !== -1) {
          isRedirect = true;
        }
      });
      if (!isRedirect) return '';
    }

    return redirectUrl.substring(0, 4) === 'http' ? redirectUrl : '';
  },

  createRequestTypes(base) {
    const REQUEST = 'REQUEST';
    const SUCCESS = 'SUCCESS';
    const FAILURE = 'FAILURE';
    return [REQUEST, SUCCESS, FAILURE].reduce((acc, type) => {
      acc[type] = `${base}_${type}`;
      return acc;
    }, {});
  },

  // Перевести строку в CamelCase из underscore стиля
  toCamelCase(str, firstUpper) {
    const camelCase = str.replace(/_([a-z])/g, (m, w) => w.toUpperCase());
    if (!firstUpper) return camelCase;
    return camelCase.charAt(0).toUpperCase() + camelCase.slice(1);
  },

  toPascalCase(str) {
    if (!str) return;
    let arr = str.split('_');
    arr = arr.map(i => (
      this.toUpperLatter(i.toLocaleLowerCase())
    ));
    return arr.join('');
  },

  // Перевести первую букву в верхний регистр
  toUpperLatter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  tryToBool(str) {
    if (str === 'false') return false;
    if (str === 'true') return true;
    return str;
  },

  random() {
    return Math.floor(Math.random() * 0xFFFF);
  },

  cutUrlToPrevious(str) {
    if (!str || typeof str !== 'string') return;
    let index = str.lastIndexOf('/');
    return str.slice(0, index);
  },

  formatValue(val) {
    if (val == null || isNaN(val)) {
      return;
    }
    val = Math.round(val)
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  },

  roundValue(val) {
    if (val == null || isNaN(val)) {
      return;
    }
    val = Math.round(val)
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  },

  round10Value(value) {
    value = +value;
    if (isNaN(value)) {
      return NaN;
    }

    value = value.toString().split('e');
    value = Math['round'](+(value[0] + 'e' + (value[1] ? (+value[1] + 2) : 2)));

    value = value.toString().split('e');
    return Number(value[0] + 'e' + (value[1] ? (+value[1] - 2) : -2)).toFixed(2);
  },
};

export default AppUtils;
