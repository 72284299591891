import React, { useState, useEffect } from 'react'
import { Layout, Button, Input, Upload, message } from 'antd'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PaymentsActions from '../../Redux/PaymentsRedux'
import NavActions from '../../Redux/NavRedux'
import i18n from '../../i18n'
import Images from '../../Images'

const { Header, Footer } = Layout;
const { Search } = Input;

function PaymentsHeader(props) {
  const {payments, settings, isAdmin, uploadFile, nav} = props;

  const [fileUid, setFileUid] = useState(null);

  let history = useHistory();
  function handleNavigate(path) {
    history.push(history.location.pathname + path);
  }

  function logoNavigate(path) {
    if (!isAdmin) {
      history.push(path);
    }
  }

  function handleSearch(val) {
    if (payments.search === val) {
      return;
    }
    props.getPayments(1, settings.page_size, null, null, val, payments.filter);
  }

  function onChange({ file, fileList }) {
    if (fileUid && fileUid === file.uid) {
      return;
    }
    setFileUid(file.uid);

    let formData = new FormData();

    if (fileList && fileList.length) {
      formData.set('file', fileList[0].originFileObj, fileList[0].originFileObj.name);
    }
    props.sendUploadFile(formData);
  }

  function handleOpenMobileMenu(e) {
    props.navSetProp('isShowMobileMenu', true)
  }

  useEffect(() => {
    if (uploadFile) {
      message.success(i18n.t("successful_data_upload"), 3);
      props.resetUploadData();
      props.getPayments(payments.page, settings.page_size, payments.sort_field, payments.sort_order, payments.search, payments.filter);
    }
  }, [uploadFile])

  return (
    <Header>
      {
        nav.isDesktop ?
          <>
            <div className="header-title-container">{i18n.t("payments")}</div>
            <div className="header-buttons-container">
              <Search
                placeholder={i18n.t("search")}
                allowClear
                className="content-search"
                onSearch={handleSearch}
              />
              {
                isAdmin &&
                <>
                  <Upload accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" method="get" onChange={onChange}>
                    <Button className="btn-primary" type="primary" style={{ width: 'auto' }}>
                    <img className="btn-icon icon-space" src={Images.document} alt="" />
                    {i18n.t("upload_payments")}
                    </Button>
                  </Upload>
                  <div style={{ width: '8px' }} />
                </>
              }
              <Button className="btn-primary" type="primary blue" style={{ width: 'auto' }}onClick={() => handleNavigate('/settings')}>
                <img className="btn-icon" src={Images.settings} alt="" />
              </Button>
              <div style={{ width: '8px' }} />
              <Button className="btn-primary" type="primary blue" style={{ width: 'auto' }} onClick={() => handleNavigate('/filters')}>
                <img className="btn-icon" src={Images.filters} alt="" />
              </Button>
            </div>
          </>
        :
        <div className="menu-btn">
          <img className="btn-icon" src={Images.logo} alt="" onClick={() => logoNavigate('/home/dashboard')}/>
          <div className="header-title-mobile">{i18n.t("payments")}</div>
          <div className="mobile-btn-wraper" onClick={handleOpenMobileMenu}>
            <img className="btn-icon" src={Images.menu} alt="" />
          </div>
        </div>
      }
    </Header>
  )
}

function PaymentsFooterComp(props) {
  const {payments, settings, isAdmin, uploadFile} = props;

  const [fileUid, setFileUid] = useState(null);
  const [showSearch, setShowSearch] = useState(false);

  let history = useHistory();
  function handleNavigate(path) {
    history.push(history.location.pathname + path);
  }

  function handleSearch(val) {
    if (payments.search === val) {
      return;
    }
    props.getPayments(1, settings.page_size, null, null, val, payments.filter);
  }

  function onChange({ file, fileList }) {
    if (fileUid && fileUid === file.uid) {
      return;
    }
    setFileUid(file.uid);

    let formData = new FormData();

    if (fileList && fileList.length) {
      formData.set('file', fileList[0].originFileObj, fileList[0].originFileObj.name);
    }
    props.sendUploadFile(formData);
  }

  useEffect(() => {
    if (uploadFile) {
      message.success(i18n.t("successful_data_upload"), 3);
      props.resetUploadData();
      props.getPayments(payments.page, settings.page_size, payments.sort_field, payments.sort_order, payments.search, payments.filter);
    }
  }, [uploadFile])

  return (
    <Footer>
      <div className="footer-buttons-container">
        <div className="mobile-btn-wraper" onClick={() => setShowSearch(!showSearch)}>
          <img className="btn-icon" src={Images.search} alt="" />
        </div>
        {
          showSearch &&
            <div className="mobile-search">
              <Search
                placeholder={i18n.t("search")}
                allowClear
                className="content-search"
                onSearch={handleSearch}
              />
            </div>
        }
        {
          isAdmin &&
          <>
            <Upload accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" method="get" onChange={onChange}>
              <Button className="btn-primary" type="primary" style={{ width: '40px' }}>
                <img className="btn-icon" src={Images.download} alt="" />
              </Button>
            </Upload>
            <div style={{ width: '8px' }} />
          </>
        }
        <Button className="btn-primary" type="primary blue" style={{ width: '40px' }}onClick={() => handleNavigate('/settings')}>
          <img className="btn-icon" src={Images.settings} alt="" />
        </Button>
        <div style={{ width: '8px' }} />
        <Button className="btn-primary" type="primary blue" style={{ width: '40px' }} onClick={() => handleNavigate('/filters')}>
          <img className="btn-icon" src={Images.filters} alt="" />
        </Button>
      </div>
    </Footer>
  )
}

const mapStateToProps = (state) => {
  return {
    payments: state.payments,
    uploadFile: state.payments.uploadFile,
    settings: state.settings,
    isAdmin: state.auth.isAdmin,
    nav: state.nav,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getPayments: (page, page_size, sort_field, sort_order, search, filter) => dispatch(PaymentsActions.paymentsRequest(page, page_size, sort_field, sort_order, search, filter)),
  sendUploadFile: (data) => dispatch(PaymentsActions.paymentSendFileRequest(data)),
  resetUploadData: () => dispatch(PaymentsActions.paymentSendFileReset()),
  navSetProp: (key, value) => dispatch(NavActions.navSetProp(key, value)),
})

export const PaymentsFooter = connect(mapStateToProps, mapDispatchToProps)(PaymentsFooterComp);
export default connect(mapStateToProps, mapDispatchToProps)(PaymentsHeader)
