import React, { useEffect} from 'react'
import { connect } from 'react-redux'
import { message } from 'antd'
import {useHistory} from 'react-router-dom'
import UserActions from '../../Redux/UserRedux'
import FeesActions from '../../Redux/FeesRedux'
import AssignSubdealerPopupForm from '../../Components/Forms/AssignSubdealerPopupForm'
import i18n from '../../i18n'
import utils from '../../Utils/utils'

function AssignSubdealerPopupPage (props) {

  const { subdealers, result, match } = props,
        {vin} = match.params;

  let history = useHistory();
  function handleClose() {
    props.dataReset();
    let path = utils.cutUrlToPrevious(history.location.pathname);
    history.push(path);
  }

  function handleSubmit(data) {
    data.car = vin;
    data.standard_fee = null;
    const res = subdealers.results.find(el => el.id === data.sub_dealer);
    if (res && res.fee_template) {
      data.standard_fee = res.fee_template.id;
    }
    props.assignSubdealer(data);
  }

  useEffect(()=>{
    props.getUsers(null, null, null, null, 2, null);
    return () => {
    }
  }, [0]);

  useEffect(()=>{
    if(result){
      message.success(i18n.t("successful_subdealer_assign"), 2);
      props.getCustomFee(vin);
      handleClose();
    }
  }, [result]);

  return (
      <div className="popup-dialog">
        <AssignSubdealerPopupForm
          data={subdealers}
          onClose={handleClose}
          onSubmit={handleSubmit}
          />
      </div>
  )
}

const mapStateToProps = (state) => {
  return {
    subdealers: state.users.list,
    result: state.fees.saveFeeCustomResult,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUsers: (page, page_size, sort_field, sort_order, role, search) => dispatch(UserActions.usersRequest(page, page_size, sort_field, sort_order, role, search)),
  getCustomFee: (vin) => dispatch(FeesActions.feeCustomRequest(vin)),
  assignSubdealer: (data) => dispatch(FeesActions.feeCustomSaveRequest(data)),
  dataReset: () => dispatch(FeesActions.feeCustomSaveReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AssignSubdealerPopupPage);
