import React, { useState, useEffect } from 'react'
import { Col, Row, Form, Select, InputNumber } from 'antd'
import moment from 'moment'
import IconCustom from '../../Icon'
import i18n from '../../i18n'
import utils from '../../Utils/utils'

import './CalculatorForm.css'

const { Option } = Select;

export const calculatorFormFields = {
  car_size: null,
  auction_location: '',
  port_of_arrival: '',
  price: null,
  auction: null,
  category: null,
  engine_type: null,
  capacity: null,
  year: null,
  eksbro: null,
  parking: null,
  repair: null,
  other: null
}

function CalculatorForm(props) {
  const [backup, setBackup] = useState(null);
  const [comissionError, setComissionError] = useState(false);
  const [customError, setCustomError] = useState([false, '']);
  const [isElectro, setIsElectro] = useState(false);
  const [addCosts, setAddCosts] = useState(null);

  const { form, data, calcDelivery, calcComission, calcCustom, calcCustomReset } = props;

  const {
    getFieldDecorator,
    getFieldsValue,
    setFieldsValue,
  } = form;

  const engines = [],
        auctions = [],
        categories = [],
        bodyTypes = [],
        locations = [],
        ports = [],
        volume = [],
        years = [];

  if (data.engines) {
    for (let i = 0; i < data.engines.length; i++) {
      engines.push(<Option key={data.engines[i].id}>{data.engines[i].name}</Option>);
    }
  }
  if (data.auctions) {
    for (let i = 0; i < data.auctions.length; i++) {
      auctions.push(<Option key={data.auctions[i].id}>{data.auctions[i].name}</Option>);
    }
  }
  if (data.categories) {
    for (let i = 0; i < data.categories.length; i++) {
      categories.push(<Option key={data.categories[i].id}>{data.categories[i].name}</Option>);
    }
  }
  if (data.bodyTypes) {
    for (let i = 0; i < data.bodyTypes.length; i++) {
      bodyTypes.push(<Option key={data.bodyTypes[i].id}>{data.bodyTypes[i].name}</Option>);
    }
  }
  if (data.locations) {
    for (let i = 0; i < data.locations.length; i++) {
      locations.push(<Option key={data.locations[i]}>{data.locations[i]}</Option>);
    }
  }
  if (data.ports) {
    for (let i = 0; i < data.ports.length; i++) {
      ports.push(<Option key={data.ports[i]}>{data.ports[i]}</Option>);
    }
  }
  for (let i = 1; i < 100; i++) {
    volume.push(<Option key={i}>{i / 10}</Option>);
  }
  for (let i = moment().year(); i > 2003; i--) {
    years.push(<Option key={i}>{i === 2004 ? i18n.t("2004_and_before") : i}</Option>);
  }

  useEffect(() => {
    setFieldsValue({ ...calculatorFormFields });

    return () => {
    }
  }, [0])

  useEffect(() => {
    if (data.comissionCalcResult) {
      customChange();
    }
  }, [data.comissionCalcResult])

  function deliveryChange() {
    const data = getFieldsValue();
    let { car_size, auction_location, port_of_arrival } = data;
    if (car_size && auction_location && port_of_arrival) {
      car_size = +car_size;
      calcDelivery({ car_size, auction_location, port_of_arrival });
    }
  }

  function comissionChange() {
    const data = getFieldsValue();
    let { price, auction, category } = data;
    if (price && typeof price === 'number' && auction && category) {
      auction = +auction;
      category = +category;
      calcComission({ price, auction, category });
      setComissionError(false);
    } else if (price && typeof price === 'number') {
      setComissionError(false);
    } else {
      setComissionError(true);
    }
    customChange();
  }

  function customChange(val, isEngineType) {
    if (isEngineType && typeof isEngineType === 'boolean') {
      val === '3' ?
        setIsElectro(true)
        :
        setIsElectro(false)
    }
    const formData = getFieldsValue();
    let { price, auction, category, engine_type, capacity, year } = formData;
    if (engine_type && capacity && year && data.comissionCalcResult) {
      if (price && typeof price === 'number' && auction && category) {
        engine_type = +engine_type;
        year = +year;
        auction = +auction;
        category = +category;
        calcCustom({ price: price + data.comissionCalcResult, engine_type, capacity, year, auction, category });
        setCustomError([false, '']);
      } else if (!price) {
        setCustomError([true, i18n.t("enter_car_price")]);
      } else if (!auction) {
        setCustomError([true, i18n.t("select_auction")]);
      } else {
        setCustomError([true, i18n.t("select_category")]);
      }
    } else {
      if (price && typeof price === 'number' && auction && category) {
        calcCustomReset();
      }
      setCustomError([false, '']);
    }
  }

  function changePrice(e) {
    const data = getFieldsValue();
    let { price, auction, category } = data;
    if (price === backup) {
      return;
    }
    if (typeof price === 'number') {
      setBackup(price);
      if (auction && category) {
        comissionChange();
      } else if (!auction || !category || !price) {
        customChange();
      }
      props.onPriceChange(price);
      setComissionError(false);
    } else {
      setBackup(null);
      props.onPriceChange(null);
      comissionChange();
    }
    e.target.blur();
  }

  function addCostsChange(val) {
    const data = getFieldsValue();
    const { eksbro, parking, repair, other} = data;
    const newData = eksbro + parking + repair + other;
    setAddCosts(newData);
    props.changeAddCosts(newData);
  }

  return (
    <div>
      {<Form id="calculator-form" disabled>
        <Row gutter={16} className="row-calc">
          <Col className="gutter-row calc" lg={6} xs={24}>
            <div className="calc-block first">
              <div className="calc-block-title">{i18n.t("delivery_cost")}</div>
              <Row gutter={48} className="flex-row">
                <Col className="gutter-row" xl={24} md={12} xs={24}>
                  <Form.Item label={i18n.t("body_type")}>
                    {getFieldDecorator('car_size')(
                      <Select
                        size="large"
                        showArrow={true}
                        suffixIcon={<IconCustom type="select" />}
                        onSelect={deliveryChange}
                      >
                        {bodyTypes}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>

                <Col className="gutter-row" xl={24} md={12} xs={24}>
                  <Form.Item label={i18n.t("auction_location")}>
                    {getFieldDecorator('auction_location')(
                      <Select
                        size="large"
                        showSearch
                        dropdownClassName="auction-location"
                        optionFilterProp="children"
                        suffixIcon={<IconCustom type="select" />}
                        onSelect={deliveryChange}
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {locations}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>

                <Col className="gutter-row" xl={24} md={12} xs={24}>
                  <Form.Item label={i18n.t("port_of_arrival")}>
                    {getFieldDecorator('port_of_arrival')(
                      <Select
                        size="large"
                        showArrow={true}
                        suffixIcon={<IconCustom type="select" />}
                        onSelect={deliveryChange}
                      >
                        {ports}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>

                <Col className="gutter-row" xl={24} md={12} xs={24}>
                  <div className="calc-result first">
                    <div className="amount">{data.deliveryCalcResult ? utils.formatValue(data.deliveryCalcResult) : '-'}</div>
                    <div className="amount-title">{i18n.t("delivery")}</div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>

          <Col className="gutter-row calc" lg={6} md={12} xs={24}>
            <div className="auto-price">
              <Form.Item label={i18n.t("car_price")}>
                {getFieldDecorator('price')(
                  <InputNumber
                    min={0}
                    placeholder={i18n.t("enter_cost")}
                    onPressEnter={changePrice}
                    onBlur={changePrice} />,
                )}
              </Form.Item>
            </div>
            <div className="calc-block comission-block">
              <div className="calc-block-title">{i18n.t("auction_comission")}</div>
              <Form.Item label={i18n.t("auction")}>
                {getFieldDecorator('auction')(
                  <Select
                    size="large"
                    showArrow={true}
                    suffixIcon={<IconCustom type="select" />}
                    onSelect={comissionChange}
                  >
                    {auctions}
                  </Select>,
                )}
              </Form.Item>
              <Form.Item label={i18n.t("category")}>
                {getFieldDecorator('category')(
                  <Select
                    size="large"
                    showArrow={true}
                    suffixIcon={<IconCustom type="select" />}
                    onSelect={comissionChange}
                  >
                    {categories}
                  </Select>,
                )}
              </Form.Item>
              <div className="calc-result">
                {
                  comissionError ?
                    <div className="calc-error">{i18n.t("enter_car_price")}</div>
                    :
                    <div className="amount">{data.comissionCalcResult ? utils.formatValue(data.comissionCalcResult) : '-'}</div>
                }
                <div className="amount-title">{i18n.t("auction_comission")}</div>
              </div>
            </div>
          </Col>

          <Col className="gutter-row calc clearance" lg={6} md={12} xs={24}>
            <div className="calc-block first">
              <div className="calc-block-title">{i18n.t("customs_clearance")}</div>
              <Row gutter={48} className="flex-row">
                <Col className="gutter-row" span={24}>
                  <Form.Item label={i18n.t("engine_type")}>
                    {getFieldDecorator('engine_type')(
                      <Select
                        size="large"
                        showArrow={true}
                        suffixIcon={<IconCustom type="select" />}
                        onSelect={(val) => customChange(val, true)}
                      >
                        {engines}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>

                <Col className="gutter-row" span={24}>
                  <Form.Item label={i18n.t(isElectro ? "battery_capacity" : "engine_volume")}>
                    {getFieldDecorator('capacity')(
                      <InputNumber
                        min={0}
                        step={isElectro ? 1 : 100}
                        placeholder={i18n.t(isElectro ? "kilowatt_hour" : "cubic_centimeters")}
                        onBlur={customChange} />,
                    )}
                  </Form.Item>
                </Col>

                <Col className="gutter-row" span={24}>
                  <Form.Item label={i18n.t("issue_year")}>
                    {getFieldDecorator('year')(
                      <Select
                        size="large"
                        showArrow={true}
                        suffixIcon={<IconCustom type="select" />}
                        onSelect={customChange}
                      >
                        {years}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>

                <Col className="gutter-row" span={24}>
                  <div className="calc-result first">
                    {
                      customError[0] ?
                        <div className="calc-error">{customError[1]}</div>
                        :
                        <div className="amount">{data.customCalcResult ? utils.formatValue(data.customCalcResult) : '-'}</div>
                    }
                    <div className="amount-title">{i18n.t("customs_clearance")}</div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>

          <Col className="gutter-row calc" lg={6} xs={24}>
            <div className="calc-block first">
              <div className="calc-block-title">{i18n.t("associated_costs")}</div>
              <Row gutter={24} className="flex-row">
                <Col className="gutter-row" xl={24} md={12} xs={24}>
                  <Form.Item label={i18n.t("eksbro")}>
                    {getFieldDecorator('eksbro')(
                      <InputNumber
                        min={0}
                        parser={value => isNaN(value) ? 0 : Number(value).toFixed()}
                        onBlur={addCostsChange}
                      />,
                    )}
                  </Form.Item>
                </Col>

                <Col className="gutter-row" lg={12} md={6} xs={24}>
                  <Form.Item label={i18n.t("parking")}>
                    {getFieldDecorator('parking')(
                      <InputNumber
                        min={0}
                        parser={value => isNaN(value) ? 0 : Number(value).toFixed()}
                        onBlur={addCostsChange}
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col className="gutter-row" lg={12} md={6} xs={24}>
                  <Form.Item label={i18n.t("repair")}>
                    {getFieldDecorator('repair')(
                      <InputNumber
                        min={0}
                        parser={value => isNaN(value) ? 0 : Number(value).toFixed()}
                        onBlur={addCostsChange}
                      />,
                    )}
                  </Form.Item>
                </Col>

                <Col className="gutter-row" xl={24} md={12} xs={24}>
                  <Form.Item label={i18n.t("other")}>
                    {getFieldDecorator('other')(
                      <InputNumber
                        min={0}
                        parser={value => isNaN(value) ? 0 : Number(value).toFixed()}
                        onBlur={addCostsChange}
                      />,
                    )}
                  </Form.Item>
                </Col>

                <Col className="gutter-row" xl={24} md={12} xs={24}>
                  <div className="calc-result first">
                    <div className="amount">{addCosts ? addCosts : '-'}</div>
                    <div className="amount-title">{i18n.t("costs")}</div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form>}
    </div>
  )
}

const WrappedCalculatorForm = Form.create({ name: 'calculator-form' })(CalculatorForm);

export default WrappedCalculatorForm
