import React from 'react';

const Logout = (props) => (
  <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity={props.opacity ? props.opacity : '0.4'}>
      <path d="M12 3.56155C12 2.38242 12 1.79285 12.3846 1.49257C12.7692 1.19229 13.3411 1.33529 14.4851 1.62127L18.9701 2.74254C20.4253 3.10632 21.1528 3.28821 21.5764 3.83073C22 4.37325 22 5.1232 22 6.62311V17.3769C22 18.8768 22 19.6267 21.5764 20.1693C21.1528 20.7118 20.4253 20.8937 18.9701 21.2575L14.4851 22.3787C13.3411 22.6647 12.7692 22.8077 12.3846 22.5074C12 22.2072 12 21.6176 12 20.4384V3.56155Z" fill={props.color ? props.color : '#262F56'} />
      <path d="M10 4L9.5 4C8.55719 4 8.08579 4 7.79289 4.29289C7.5 4.58579 7.5 5.05719 7.5 6V8M10 20H9.5C8.55719 20 8.08579 20 7.79289 19.7071C7.5 19.4142 7.5 18.9428 7.5 18V16" stroke="#262F56" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M1 12H9.5M1 12L4 9M1 12L4 15" stroke="#262F56" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </svg>
);

export default Logout;
