import React from 'react';

const Broker = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity={props.opacity ? props.opacity : '0.4'}>
      <rect x="1" y="4" width="14" height="14" rx="3" stroke="#262F56" strokeWidth="1.8" />
      <rect x="10" y="8" width="13" height="13" rx="2" fill="#262F56" stroke="#262F56" strokeWidth="1.8" />
      <path d="M6.66667 10L4 12.5L11 12.5" stroke="#262F56" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.5 12.5L18.1015 13.1695C18.3788 12.9203 18.4738 12.526 18.3404 12.1779C18.207 11.8298 17.8728 11.6 17.5 11.6L17.5 12.5ZM14.1159 14.3305C13.7462 14.6627 13.7157 15.2317 14.0479 15.6015C14.3801 15.9712 14.9491 16.0017 15.3189 15.6695L14.1159 14.3305ZM16.8985 11.8305L14.1159 14.3305L15.3189 15.6695L18.1015 13.1695L16.8985 11.8305ZM17.5 11.6L9 11.6L9 13.4L17.5 13.4L17.5 11.6Z" fill={props.color ? props.color : "#262F56"} />
    </g>
  </svg>

);

export default Broker;
