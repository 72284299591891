import React from 'react'
import { Form, Input, Button } from 'antd'
import i18n from '../../i18n'

function LoginForm(props) {

  const {form} = props;

  const {getFieldDecorator} = form;

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        props.onSubmit(values)
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit} className="login-form">

      <Form.Item label={i18n.t("username")}>
        {getFieldDecorator('login', {
          rules: [{ required: true, message: i18n.t("required_field") }],
        })(
          <Input
          />,
        )}
      </Form.Item>
      <Form.Item label={i18n.t("password")}>
        {getFieldDecorator('password', {
          rules: [{ required: true, message: i18n.t("required_field") }],
        })(
          <Input.Password
            size="large"
            type="password"
            autoComplete="new-password"
          />,
        )}
      </Form.Item>

      <div style={{display:'flex', justifyContent:'center'}}>
        <Button style={{marginTop:'12px', width:'100%', height:'60px', textTransform:'uppercase'}} block size="large" type="primary" htmlType="submit" className="btn-primary">
          {i18n.t("submit")}
        </Button>
      </div>

    </Form>
  );
}

const WrappedLoginForm = Form.create({ name: 'login_form' })(LoginForm);

export default WrappedLoginForm
