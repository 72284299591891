import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  signInRequest: ['login', 'password'],
  signInSuccess: ['userInfo', 'accessToken', 'refreshToken'],
  signInFailure: ['error'],
  signInReset: null,
  updateTokens: ['accessToken', 'refreshToken'],

  resetPasswordRequest: ['login'],
  resetPasswordSuccess: null,
  resetPasswordFailure: ['resetPasswordError'],

  logout: null,

  updateProfile: ['userInfo']
})

export const AuthTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  userInfo: null,
  accessToken: null,
  refreshToken: null,
  error: null,
  resetPasswordError: null,
  fetching: false,
  isAdmin: false,
  role: null
})

/* ------------- Selectors ------------- */

export const getUserInfo = (state) => state.auth.userInfo

export const AuthSelectors = {
  getUserInfo: state => state.auth.userInfo,
  getAccessToken: state => state.auth.accessToken,
  getRefreshToken: state => state.auth.refreshToken
}

/* ------------- Reducers ------------- */

export const resetPasswordRequest = (state: Object, { login }: Object) => {
  return state.merge({ fetching: true, resetPasswordError:null })
}

export const resetPasswordSuccess = (state: Object) => {
  return state.merge({ fetching: false, resetPasswordError:null })
}

export const resetPasswordFailure = (state: Object, { resetPasswordError }:Object) => {
  return state.merge({ fetching: false, resetPasswordError:resetPasswordError })
}

export const signInRequest = (state: Object, {  }: Object) => {
  return state.merge({ fetching: true, error:null })
}

export const signInSuccess = (state: Object, { userInfo, accessToken, refreshToken }: Object) => {
  const isAdmin = userInfo.role.id === 1,
        role = userInfo.role.id;
  return state.merge({ fetching: false, error:null, userInfo , accessToken, refreshToken, isAdmin, role })
}

export const signInFailure = (state: Object, { error }: Object) =>{
  return state.merge({ error, fetching: false })
}

export const signInReset = (state: Object, {}: Object) =>{
  return state.merge({ error:null, fetching: false })
}

export const updateTokens = (state: Object, {accessToken, refreshToken}: Object) =>{
  return state.merge({ accessToken, refreshToken })
}

export const logout = (state: Object) =>{
  return INITIAL_STATE
}

export const updateProfile = (state: Object, { userInfo }) =>{
  return state.merge({ userInfo })
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SIGN_IN_REQUEST]: signInRequest,
  [Types.SIGN_IN_SUCCESS]: signInSuccess,
  [Types.SIGN_IN_FAILURE]: signInFailure,
  [Types.SIGN_IN_RESET]: signInReset,
  [Types.UPDATE_TOKENS]: updateTokens,

  [Types.RESET_PASSWORD_REQUEST]: resetPasswordRequest,
  [Types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [Types.RESET_PASSWORD_FAILURE]: resetPasswordFailure,
  [Types.LOGOUT]: logout,

  [Types.UPDATE_PROFILE]: updateProfile,
})
