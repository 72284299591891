import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  brokerRequest: ['page', 'page_size', 'sort_field', 'sort_order', 'search', 'filter'],
  brokerSuccess: ['list'],

  brokerSendFileRequest: ['data'],
  brokerSendFileSuccess: ['uploadFile'],
  brokerSendFileReset: null,

  brokerReset: null,
  brokerFilterReset: null,
  brokerSearchReset: null,

  brokerFetchingReset: null

})

export const BrokerTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: null,
  fetching: false,

  uploadFile: null,
  uploadFileFetching: false,

  page: 1,
  sort_field: '',
  sort_order: 1,
  search: '',
  filter: {
    vin: '',
    purchase_date: [],
    arrival_date: [],
    port_of_arrival: ''
  },

})

/* ------------- Selectors ------------- */
export const BrokerSelectors = {
  getState: (state) => state.broker
}

/* ------------- Reducers ------------- */

export const brokerRequest = (state: Object, {page, page_size, sort_field, sort_order, search, filter}: Object) => {
  const newState = {
    fetching: true,
  };
  page && (newState.page = page);
  page_size && (newState.page_size = page_size);
  sort_field && (newState.sort_field = sort_field);
  sort_order && (newState.sort_order = sort_order);
  search != null && (newState.search = search);
  filter && (newState.filter = filter);
  return state.merge(newState)
}

export const brokerSuccess = (state: Object, { list }: Object) => {
  return state.merge({ fetching: false, list })
}

export const brokerSendFileRequest = (state: Object, { data }: Object) => {
  return state.merge({ uploadFileFetching: true })
}

export const brokerSendFileSuccess = (state: Object, { uploadFile }: Object) => {
  return state.merge({ uploadFileFetching: false, uploadFile })
}

export const brokerSendFileReset = (state: Object) =>{
  return state.merge({ uploadFileFetching: false, uploadFile:null })
}

export const brokerReset = (state: Object) =>{
  return INITIAL_STATE
}

export const brokerFilterReset = (state: Object) => {
  const newState = {
    filter: {
      vin: '',
      purchase_date: [],
      arrival_date: [],
      port_of_arrival: ''
    }
  };
  return state.merge(newState)
}

export const brokerSearchReset = (state: Object) => {
  return state.merge({ search: '' })
}

export const brokerFetchingReset = (state: Object) => {
  return state.merge({ fetching:false, uploadFileFetching:false })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.BROKER_REQUEST]: brokerRequest,
  [Types.BROKER_SUCCESS]: brokerSuccess,

  [Types.BROKER_SEND_FILE_REQUEST]: brokerSendFileRequest,
  [Types.BROKER_SEND_FILE_SUCCESS]: brokerSendFileSuccess,
  [Types.BROKER_SEND_FILE_RESET]: brokerSendFileReset,

  [Types.BROKER_RESET]: brokerReset,
  [Types.BROKER_FILTER_RESET]: brokerFilterReset,
  [Types.BROKER_SEARCH_RESET]: brokerSearchReset,

  [Types.BROKER_FETCHING_RESET]: brokerFetchingReset,

})
