import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  useHistory,
  Switch,
  Route
} from 'react-router-dom'
import CarsActions from '../../Redux/CarsRedux'
import PaymentsActions from '../../Redux/PaymentsRedux'
import BrokerActions from '../../Redux/BrokerRedux'
import RatesActions from '../../Redux/RatesRedux'
import UserActions from '../../Redux/UserRedux'
import CarFiltersPopupForm from '../../Components/Forms/CarFiltersPopupForm'
import PaymentFiltersPopupForm from '../../Components/Forms/PaymentFiltersPopupForm'
import BrokerFiltersPopupForm from '../../Components/Forms/BrokerFiltersPopupForm'
import RateLocalFiltersPopupForm from '../../Components/Forms/RateLocalFiltersPopupForm'
import RateOceanFiltersPopupForm from '../../Components/Forms/RateOceanFiltersPopupForm'
import i18n from '../../i18n'
import utils from '../../Utils/utils'

import './PopupPage.css'

function FiltersPopupPage(props) {
  const { cars, payments, broker, rates, page_size, location, isAdmin, role, subdealers } = props;

  useEffect(() => {
    switch (location.pathname) {
      case '/home/cars/filters':
      case '/home/broker/filters': {
        props.getCarPorts();
        if (role === 0) {
          props.getSubdealers(null, null, null, null, 2, null);
        }
        break;
      }
      default: break;
    }

  }, [0])

  let history = useHistory();
  function handleClose(e) {
    let path = utils.cutUrlToPrevious(history.location.pathname);
    history.push(path);
  }

  function handleSubmit(filterData) {
    switch (location.pathname) {
      case '/home/cars/filters': {
        props.getCars(1, page_size, cars.sort_field, cars.sort_order, cars.search, filterData);
        break;
      }
      case '/home/payments/filters': {
        props.getPayments(1, page_size, payments.sort_field, payments.sort_order, payments.search, filterData);
        break;
      }
      case '/home/broker/filters': {
        props.getBrokerData(1, page_size, broker.sort_field, broker.sort_order, broker.search, filterData);
        break;
      }
      case '/home/rates/local/filters': {
        props.getLocalRates(1, page_size, rates.sort_field, rates.sort_order, rates.search, filterData);
        break;
      }
      case '/home/rates/ocean/filters': {
        props.getOceanRates(filterData);
        break;
      }
      default: break;
    }
  }

  function handleReset() {
    switch (location.pathname) {
      case '/home/cars/filters': {
        props.resetCatFilter();
        break;
      }
      case '/home/payments/filters': {
        props.resetPaymentFilter();
        break;
      }
      case '/home/broker/filters': {
        props.resetBrokerFilter();
        break;
      }
      case '/home/rates/local/filters':
      case '/home/rates/ocean/filters': {
        props.resetRateFilter();
        break;
      }
      default: break;
    }
    handleSubmit();
  }

  return (
    <div className="popup-dialog">
      <div className="dialog-container settings-popup-dialog">
        <div className="dialog-close-container">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClose}>
            <path d="M1.66663 1.66667L11 11M11 11L20.3333 20.3333M11 11L20.3333 1.66667M11 11L1.66663 20.3333" stroke="#262F56" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <div className="popup-title">{i18n.t("filter")}</div>
        <div className="popup-content">
          <Switch>
            <Route path="/home/cars/filters" render={
              () => (
                <CarFiltersPopupForm
                  initialFilter={cars.filter}
                  arr_ports={cars.arr_ports}
                  dep_ports={cars.dep_ports}
                  role={role}
                  subdealers={subdealers}
                  onSubmit={handleSubmit}
                  onReset={handleReset}
                />)}
            />
            <Route path="/home/payments/filters" render={
              () => (
                <PaymentFiltersPopupForm
                  initialFilter={payments.filter}
                  onSubmit={handleSubmit}
                  onReset={handleReset}
                />)}
            />
            {/* <Route path="/home/broker/filters" render={
              () => (
                <BrokerFiltersPopupForm
                  initialFilter={broker.filter}
                  arr_ports={cars.arr_ports}
                  onSubmit={handleSubmit}
                  onReset={handleReset}
                />)}
            /> */}
            {
              isAdmin &&
              <Route path="/home/rates/local/filters" render={
                () => (
                  <RateLocalFiltersPopupForm
                    initialFilter={rates.filter}
                    ports={rates.localPortList}
                    states={rates.stateList}
                    auctions={rates.auctionList}
                    onSubmit={handleSubmit}
                    onReset={handleReset}
                  />)}
              />
            }
            <Route path="/home/rates/ocean/filters" render={
              () => (
                <RateOceanFiltersPopupForm
                  initialFilter={rates.filter}
                  container_size={rates.containerSizeList}
                  onSubmit={handleSubmit}
                  onReset={handleReset}
                />)}
            />
          </Switch>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    cars: state.cars,
    payments: state.payments,
    broker: state.broker,
    rates: state.rates,
    page_size: state.settings.page_size,
    isAdmin: state.auth.isAdmin,
    role: state.auth.role,
    subdealers: state.users.list,
  }
}

// wraps dispatch to create nicer functions to call within our component
const mapDispatchToProps = (dispatch) => ({
  getCars: (page, page_size, sort_field, sort_order, search, filter) => dispatch(CarsActions.carsRequest(page, page_size, sort_field, sort_order, search, filter)),
  resetCatFilter: () => dispatch(CarsActions.carFilterReset()),
  getCarPorts: () => dispatch(CarsActions.carPortsRequest()),

  getPayments: (page, page_size, sort_field, sort_order, search, filter) => dispatch(PaymentsActions.paymentsRequest(page, page_size, sort_field, sort_order, search, filter)),
  resetPaymentFilter: () => dispatch(PaymentsActions.paymentFilterReset()),
  getBrokerData: (page, page_size, sort_field, sort_order, search, filter) => dispatch(BrokerActions.brokerRequest(page, page_size, sort_field, sort_order, search, filter)),
  resetBrokerFilter: () => dispatch(BrokerActions.brokerFilterReset()),
  getLocalRates: (page, page_size, sort_field, sort_order, search, filter) => dispatch(RatesActions.ratesLocalRequest(page, page_size, sort_field, sort_order, search, filter)),
  getOceanRates: (filter) => dispatch(RatesActions.ratesOceanRequest(filter)),
  getLocalRateChoices: () => dispatch(RatesActions.rateLocalChoicesRequest()),
  getOceanRateChoices: () => dispatch(RatesActions.rateOceanChoicesRequest()),
  resetRateFilter: () => dispatch(RatesActions.rateFilterReset()),
  getSubdealers: (page, page_size, sort_field, sort_order, role, search) => dispatch(UserActions.usersRequest(page, page_size, sort_field, sort_order, role, search)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FiltersPopupPage);
