import React from 'react';

const Calculator = (props) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity={props.opacity ? props.opacity : '0.4'}>
      <path d="M20.4444 6.55556H1.55556M4 21H18C19.6569 21 21 19.6569 21 18V4C21 2.34315 19.6569 1 18 1H4C2.34315 1 1 2.34315 1 4V18C1 19.6569 2.34315 21 4 21Z" stroke="#262F56" strokeWidth="1.8" strokeLinecap="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1 6.55548H21V17.9999C21 19.6568 19.6569 20.9999 18 20.9999H4C2.34315 20.9999 1 19.6568 1 17.9999V6.55548ZM4.2666 10.4446C4.2666 9.94754 4.66954 9.5446 5.1666 9.5446H6.83326C7.33032 9.5446 7.73326 9.94754 7.73326 10.4446C7.73326 10.9417 7.33032 11.3446 6.83326 11.3446H5.1666C4.66954 11.3446 4.2666 10.9417 4.2666 10.4446ZM9.2666 10.4446C9.2666 9.94754 9.66954 9.5446 10.1666 9.5446H11.8333C12.3303 9.5446 12.7333 9.94754 12.7333 10.4446C12.7333 10.9417 12.3303 11.3446 11.8333 11.3446H10.1666C9.66954 11.3446 9.2666 10.9417 9.2666 10.4446ZM4.2666 13.7779C4.2666 13.2809 4.66954 12.8779 5.1666 12.8779H6.83326C7.33032 12.8779 7.73326 13.2809 7.73326 13.7779C7.73326 14.275 7.33032 14.6779 6.83326 14.6779H5.1666C4.66954 14.6779 4.2666 14.275 4.2666 13.7779ZM9.2666 13.7779C9.2666 13.2809 9.66954 12.8779 10.1666 12.8779H11.8333C12.3303 12.8779 12.7333 13.2809 12.7333 13.7779C12.7333 14.275 12.3303 14.6779 11.8333 14.6779H10.1666C9.66954 14.6779 9.2666 14.275 9.2666 13.7779ZM4.2666 17.1113C4.2666 16.6142 4.66954 16.2113 5.1666 16.2113H6.83326C7.33032 16.2113 7.73326 16.6142 7.73326 17.1113C7.73326 17.6083 7.33032 18.0113 6.83326 18.0113H5.1666C4.66954 18.0113 4.2666 17.6083 4.2666 17.1113ZM9.2666 17.1113C9.2666 16.6142 9.66954 16.2113 10.1666 16.2113H11.8333C12.3303 16.2113 12.7333 16.6142 12.7333 17.1113C12.7333 17.6083 12.3303 18.0113 11.8333 18.0113H10.1666C9.66954 18.0113 9.2666 17.6083 9.2666 17.1113ZM15.6555 17.6666C15.6555 18.1637 16.0585 18.5666 16.5555 18.5666C17.0526 18.5666 17.4555 18.1637 17.4555 17.6666V9.88885C17.4555 9.3918 17.0526 8.98885 16.5555 8.98885C16.0585 8.98885 15.6555 9.3918 15.6555 9.88885V17.6666Z" fill={props.color ? props.color : "#262F56"} />
    </g>
  </svg>

);

export default Calculator;
