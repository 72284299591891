import React, { useEffect } from 'react'
import { Col, Row, Button, Form, InputNumber, Select } from 'antd'
import Images from '../../Images'
import IconCustom from '../../Icon'
import i18n from '../../i18n'

import './FiltersPopupForm.css'

const { Option } = Select;

function RateOceanFiltersPopupForm(props) {

  const { form, initialFilter, container_size, onReset, onSubmit } = props;

  const {
    getFieldDecorator,
    getFieldsValue,
    setFieldsValue,
    resetFields
  } = form;

  const sizeList = [];
  if (container_size){
    for (let i = 0; i < container_size.length; i++) {
      sizeList.push(<Option key={container_size[i]}>{container_size[i]}</Option>);
    }
  }

  useEffect(() => {
    let initialData = initialFilter.asMutable ? initialFilter.asMutable({ deep: true }) : { ...initialFilter };

    setFieldsValue(initialData);

    return () => {
    }
  }, [0])


  function handleSubmit(e) {
    e.preventDefault();

    let fields = getFieldsValue();
    const data = {
      ...initialFilter,
      ...fields
    }
    onSubmit(data);
  }

  function resetData() {
    resetFields();
    onReset();
  }

  return (
    <div className="filter-content">

      {<Form id="filters-popup-form" onSubmit={handleSubmit} disabled>

        <Row gutter={{xl: 16, md: 24, xs: 48}} className="flex-row">
          <Col className="gutter-row" xl={{span: 8, offset: 4}} md={{span: 10, offset: 2}} xs={24}>
            <Form.Item label={i18n.t("container")}>
              {getFieldDecorator('container_size')(
                <Select
                  size="large"
                  showArrow={true}
                  suffixIcon={<IconCustom type="select"/>}
                >
                  {sizeList}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col className="gutter-row" xl={8} md={10} xs={24}>
            <Row gutter={{xl: 16, md: 24, xs: 48}} className="flex-row">
              <Col className="gutter-row" span={12}>
                <Form.Item label={i18n.t("price")}>
                  {getFieldDecorator('price_per_container_min')(
                    <InputNumber
                      min={0}
                      placeholder={i18n.t("from")}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item label=" ">
                  {getFieldDecorator('price_per_container_max')(
                    <InputNumber
                      min={0}
                      placeholder={i18n.t("to")}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

      </Form>}

      <div className="dialog-footer">
        <Button className="btn-primary gray" type="primary" style={{ width: 'auto' }} onClick={resetData}>
          <img className="btn-icon icon-space" src={Images.cross} alt="" />
          {i18n.t("filter_discard")}
        </Button>
        <Button form="user-popup-form" htmlType="submit" onClick={handleSubmit} className="btn-primary blue" type="primary" style={{ width: 'auto' }}>
          <img className="btn-icon icon-space" src={Images.filters} alt="" />
          {i18n.t("filter_use")}
        </Button>
      </div>
    </div>
  )
}

const WrappedUserEditForm = Form.create({ name: 'filters-popup-form' })(RateOceanFiltersPopupForm);

export default WrappedUserEditForm
