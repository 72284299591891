import { call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import CalcActions from '../Redux/CalcRedux'
import AuthActions from '../Redux/AuthRedux'
import ErrorsActions from '../Redux/ErrorsRedux'
import ErrorHandler from '../Services/ErrorHandler'

export function * getCalcEngines(api, authApi, {}) {
  try {
    const response = yield call(api.getCalcEngines);
    if (response && response.ok) {
      yield put(CalcActions.engineSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(CalcActions.calcFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getCalcEngines(api, authApi, {});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(CalcActions.calcFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getCalcAuctions(api, authApi, {}) {
  try {
    const response = yield call(api.getCalcAuctions);
    if (response && response.ok) {
      yield put(CalcActions.auctionSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(CalcActions.calcFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getCalcAuctions(api, authApi, {});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(CalcActions.calcFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getCalcDelivery(api, authApi, {}) {
  try {
    const response = yield call(api.getCalcDelivery);
    if (response && response.ok) {
      yield put(CalcActions.deliverySuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(CalcActions.calcFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getCalcDelivery(api, authApi, {});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(CalcActions.calcFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * calcDelivery(api, authApi, { data }: Object) {
  try {
    const response = yield call(api.calcDelivery, data);
    if (response && response.ok) {
      yield put(CalcActions.deliveryCalcSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(CalcActions.calcFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * calcDelivery(api, authApi, { data });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(CalcActions.calcFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * calcComission(api, authApi, { data }: Object) {
  try {
    const response = yield call(api.calcComission, data);
    //yield * ErrorHandler(response, CalcActions.comissionCalcSuccess, CalcActions.calcFetchingReset)
    if (response && response.ok) {
      yield put(CalcActions.comissionCalcSuccess(response.data))
    } else  {
      if (response && response.data && response.status !== 500) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(CalcActions.calcFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * calcComission(api, authApi, { data });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(CalcActions.calcFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * calcCustom(api, authApi, { data }: Object) {
  try {
    const response = yield call(api.calcCustom, data);
    if (response && response.ok) {
      yield put(CalcActions.customCalcSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(CalcActions.calcFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * calcCustom(api, authApi, { data });
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }
  } catch(e) {
    yield put(CalcActions.calcFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}
