import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import PaginationItemRender from '../PaginationItemRender'
import { titleWithSortFilters } from '../titleWithSortFilters'
import i18n from '../../i18n'
import CustomIcon from '../../Icon'
import utils from '../../Utils/utils'

import './Table.css'

function RateOceanTable(props){
  const {
    page,
    pageSize,
    scale,
    data,
    fetching
  } = props;

  const [dataSource, setDataSource] = useState(null);

  useEffect(() => {
    setDataSource(data ? data.asMutable({deep:true}) : []);
  }, [data]);

  function getColumns() {
    return [
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("shipping_line"), true, false, stateData, 'shipping_line'),
        dataIndex: 'shipping_line',
        key: 'shipping_line',
      },
      {
        title: (stateData)=>titleWithSortFilters(i18n.t("container"), true, false, stateData, 'container'),
        dataIndex: 'container',
        key: 'container',
      },
      {
        title: (stateData)=>titleWithSortFilters("Houston, TX", true, false, stateData, 'Houston, TX'),
        dataIndex: 'Houston, TX',
        key: 'Houston, TX',
        render: text => <span>{utils.round10Value(text)}</span>
      },
      {
        title: (stateData)=>titleWithSortFilters("Indianapolis, IN", true, false, stateData, 'Indianapolis, IN'),
        dataIndex: 'Indianapolis, IN',
        key: 'Indianapolis, IN',
        render: text => <span>{utils.round10Value(text)}</span>
      },
      {
        title: (stateData)=>titleWithSortFilters("Los Angeles, CA", true, false, stateData, 'Los Angeles, CA'),
        dataIndex: 'Los Angeles, CA',
        key: 'Los Angeles, CA',
        render: text => <span>{utils.round10Value(text)}</span>
      },
      {
        title: (stateData)=>titleWithSortFilters("Elizabeth, NJ", true, false, stateData, 'Elizabeth, NJ / Newark, NJ'),
        dataIndex: 'Elizabeth, NJ / Newark, NJ',
        key: 'Elizabeth, NJ / Newark, NJ',
        render: text => <span>{utils.round10Value(text)}</span>
      },
      {
        title: (stateData)=>titleWithSortFilters("Savannah, GA", true, false, stateData, 'Savannah, GA'),
        dataIndex: 'Savannah, GA',
        key: 'Savannah, GA',
        render: text => <span>{utils.round10Value(text)}</span>
      },
      // {
      //   title: (stateData)=>titleWithSortFilters("Seattle, WA", true, false, stateData, 'Seattle, WA'),
      //   dataIndex: 'Seattle, WA',
      //   key: 'Seattle, WA',
      // }
    ];
  }

  function handleTableChange(pagination, filters, sorter){

    var sort_field = null;
    var sort_order = null;

    if(sorter && sorter.field && sorter.order){
      sort_field = sorter.field;
      sort_order = sorter.order //=== "ascend" ? 1 : 0;
    }

    props.onChangePage(pagination.current, pagination.pageSize, sort_field, sort_order)

  }

  const totalCount = data ? data.count : 0;

  return (
    <Table
      loading={{spinning:fetching, indicator:<div className="loader"><CustomIcon type="loader" /></div>}}
      pagination= {{itemRender:PaginationItemRender, current:page, pageSize:pageSize, total:totalCount}}
      className="main-table spec-table rates-ocean-table"
      style={{fontSize: (scale * 14 / 100) + 'px'}}
      rowKey={row => row.id}
      rowClassName="process-table-row"
      dataSource={dataSource}
      columns={getColumns()}
      scrollToFirstRowOnChange
      onChange={handleTableChange}
      sortDirections={['descend', 'ascend']}
    />
   )


}

export default RateOceanTable
