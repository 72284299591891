import React from 'react'
import { Layout, Button, Breadcrumb } from 'antd'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import NavActions from '../../Redux/NavRedux'
import i18n from '../../i18n'
import Images from '../../Images'

const { Header, Footer } = Layout;

function DealerHeader(props) {
  const {users, isAdmin, nav} = props;

  let history = useHistory();

  function goBack() {
    isAdmin ?
      history.push('/home/dealers')
    :
      history.push('/home/subdealers')
  }

  function handleNavigate(path) {
    history.push(history.location.pathname + path);
  }

  function handleOpenMobileMenu(e) {
    props.navSetProp('isShowMobileMenu', true)
  }

  return (
    <Header>
      {
        nav.isDesktop ?
          <>
            <div className="header-title-container">
              <Breadcrumb separator=">">
                <Breadcrumb.Item className="root-breadcrumb" onClick={() => goBack()}>{i18n.t(isAdmin ? "dealers" : "subdealers")}</Breadcrumb.Item>
                <Breadcrumb.Item className="child-breadcrumb">{users && users.userData && users.userData.login}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="header-buttons-container">
              <Button className="btn-primary" type="primary" style={{ width: 'auto' }} onClick={() => handleNavigate('/change-password')}>
                <img className="btn-icon icon-space" src={Images.lock} alt="" />
                {i18n.t("change_password")}
              </Button>
              <div style={{ width: '8px' }} />
              <Button className="btn-primary" type="primary" style={{ width: 'auto' }} onClick={() => handleNavigate('/edit')}>
                <img className="btn-icon icon-space" src={Images.editWhite} alt="" />
                {i18n.t("edit")}
              </Button>
            </div>
          </>
        :
          <div className="menu-btn">
            <div className="mobile-btn-wraper back-btn" onClick={() => goBack()}>
              <img className="btn-icon" src={Images.back} alt="" />
            </div>
            <div className="car-desc">{users && users.userData && users.userData.login}</div>
            <div className="mobile-btn-wraper" onClick={handleOpenMobileMenu}>
              <img className="btn-icon" src={Images.menu} alt="" />
            </div>
          </div>
      }
    </Header>
  )
}

function DealerFooterComp(props) {
  const {users, isAdmin} = props;

  let history = useHistory();

  function handleNavigate(path) {
    history.push(history.location.pathname + path);
  }

  return (
    <Footer className="no-table-page">
      <div className="footer-buttons-container">
        <Button className="btn-primary" type="primary" style={{ width: '40px' }} onClick={() => handleNavigate('/change-password')}>
          <img className="btn-icon" src={Images.lock} alt="" />
        </Button>
        <div style={{ width: '8px' }} />
        <Button className="btn-primary" type="primary" style={{ width: '40px' }} onClick={() => handleNavigate('/edit')}>
          <img className="btn-icon" src={Images.editWhite} alt="" />
        </Button>
      </div>
    </Footer>
  )
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    isAdmin: state.auth.isAdmin,
    nav: state.nav,
  }
}

const mapDispatchToProps = (dispatch) => ({
  navSetProp: (key, value) => dispatch(NavActions.navSetProp(key, value)),
})

export const DealerFooter = connect(mapStateToProps, mapDispatchToProps)(DealerFooterComp);
export default connect(mapStateToProps, mapDispatchToProps)(DealerHeader)
