import { call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import RatesActions from '../Redux/RatesRedux'
import AuthActions from '../Redux/AuthRedux'
import ErrorsActions from '../Redux/ErrorsRedux'

export function * getLocalRates(api, authApi, {page, page_size, sort_field, sort_order, search, filter}) {
  try {
    const response = yield call(api.getLocalRates, page, page_size, sort_field, sort_order, search, filter);
    if (response && response.ok) {
      yield put(RatesActions.ratesLocalSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(RatesActions.rateFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getLocalRates(api, authApi, {page, page_size, sort_field, sort_order, search, filter});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(RatesActions.rateFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getOceanRates(api, authApi, {filter}) {
  try {
    const response = yield call(api.getOceanRates, filter);
    if (response && response.ok) {
      yield put(RatesActions.ratesOceanSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(RatesActions.rateFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getOceanRates(api, authApi, {filter});
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(RatesActions.rateFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getLocalRateChoices(api, authApi) {
  try {
    const response = yield call(api.getLocalRateChoices);
    if (response && response.ok) {
      yield put(RatesActions.rateLocalChoicesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(RatesActions.rateFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getLocalRateChoices(api, authApi);
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(RatesActions.rateFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}

export function * getOceanRateChoices(api, authApi) {
  try {
    const response = yield call(api.getOceanRateChoices);
    if (response && response.ok) {
      yield put(RatesActions.rateOceanChoicesSuccess(response.data))
    } else  {
      if (response && response.data) {
        if (response.status === 401) {
          yield put(ErrorsActions.errorSave(null))
        } else {
          yield put(ErrorsActions.errorSave(response.data))
        }
      } else {
        yield put(ErrorsActions.errorSave("Server response error"))
      }
      yield put(RatesActions.rateFetchingReset())
    }

    if (response && response.status === 401) {
      const res = yield call(authApi.tokenRefresh);
      if (res && res.access) {
        yield put(AuthActions.updateTokens(res.access, res.refresh));
        yield * getOceanRateChoices(api, authApi);
      } else {
        yield put(AuthActions.logout());
        yield put(push('/login'))
      }
    }

  } catch(e) {
    yield put(RatesActions.rateFetchingReset())
    yield put(ErrorsActions.errorSave('Server response error: '+e))
  }
}
