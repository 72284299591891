import React from 'react'
import { Row, Col } from 'antd'
import moment from 'moment'
import i18n from '../i18n'

function AutoTrans(props) {
  const { data } = props;

  return (
    data &&
    <div className="auto-info-section">
      <Row gutter={[16, 14]}>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("carrier_sea")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.shipping_line}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("btrim")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.btrim}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("dispatched_date")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.dispatched_date && moment(data.dispatched_date).format('DD.MM.YYYY')}
          </Col>
        </Col>
      </Row>
      <Row gutter={[16, 14]}>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("booking_number")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.booking_no}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("port_of_arrival")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.port_of_arrival}
          </Col>
        </Col>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("arrival_date")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.arrival_date && moment(data.arrival_date).format('DD.MM.YYYY')}
          </Col>
        </Col>
      </Row>
      <Row gutter={[16, 14]}>
        <Col className="gutter-row" lg={8} xs={24}>
          <Col className="gutter-row auto-info-title" lg={10} xs={12}>
            <div className="flex-block">
              <span>{i18n.t("container_number")}</span>
              <span className="dots"></span>
            </div>
          </Col>
          <Col className="gutter-row auto-info-value" lg={14} xs={12}>
            {data.container_no}
          </Col>
        </Col>
      </Row>
    </div>
  )
}

export default AutoTrans
