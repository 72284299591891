import React, { useEffect } from 'react'
import { Col, Row, Button, Form, Input, Popconfirm } from 'antd'
import Images from '../../Images'
import i18n from '../../i18n'


export const accountFormFields = {
  account: '',
  login: '',
  password: '',
}

function AccountForm(props) {

  const { form,  accounts, accountSaveResult, user } = props;

  const {
    getFieldDecorator,
    resetFields,
    setFieldsValue,
  } = form;

  useEffect(() => {
    let initialData = { ...accountFormFields };

    setFieldsValue(initialData);

    return () => {
    }
  }, [0])

  useEffect(() => {
    if (accountSaveResult) {
      resetFields();
    }
  }, [accountSaveResult])

  function addAccount(e) {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const data = { ...values }

        props.addAccount(data, user.id);
      }
    });

  }

  function removeAccount(id) {
    props.deleteAccount(id, user.id);
  }

  return (
      <div className="account-block">
        <Row gutter={16} className="xs-hidden">
          <Col className="gutter-row" span={8}>
            <div className="account-title">{i18n.t("account")}</div>
          </Col>
          <Col className="gutter-row" span={8}>
            <div className="account-title">{i18n.t("login")}</div>
          </Col>
          <Col className="gutter-row" span={8}>
            <div className="account-title">{i18n.t("password")}</div>
          </Col>
        </Row>
        {
          accounts && accounts.results && accounts.results.map(account => (
            <Row gutter={16} className="flex-row" key={account.id}>
              <Col className="gutter-row xs-transform" sm={8} xs={24}>
                <label>{i18n.t("account")}</label>
                <Input value={account.account} disabled/>
              </Col>
              <Col className="gutter-row xs-transform" sm={8} xs={24}>
                <label>{i18n.t("login")}</label>
                <Input value={account.login} disabled />
              </Col>
              <Col className="gutter-row xs-transform" sm={6} xs={24}>
                <label>{i18n.t("password")}</label>
                <Input.Password
                  size="large"
                  type="password"
                  value={account.password}
                  visibilityToggle={false}
                  disabled
                />
              </Col>
              <Col className="gutter-row xs-transform" sm={2} xs={24}>
                <Popconfirm
                  title={i18n.t("account_delete_confirm")}
                  onConfirm={() => removeAccount(account.id)}
                  okText={i18n.t("yes")}
                  cancelText={i18n.t("no")}
                >
                   <Button className="btn-primary gray" type="primary">
                    <img className="btn-icon" src={Images.cancel} alt="" />
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          ))
        }
        {<Form id="account-form" onSubmit={addAccount} disabled>
          <Row gutter={16} className="flex-row">
            <Col className="gutter-row xs-transform" sm={8} xs={24}>
              <label>{i18n.t("account")}</label>
              <Form.Item>
                {getFieldDecorator('account', {
                  rules: [{ required: true, message: i18n.t("required_field") }],
                })(
                  <Input />,
                )}
              </Form.Item>
            </Col>
            <Col className="gutter-row xs-transform" sm={8} xs={24}>
              <label>{i18n.t("login")}</label>
              <Form.Item>
                {getFieldDecorator('login', {
                  rules: [{ required: true, message: i18n.t("required_field") }],
                })(
                  <Input />,
                )}
              </Form.Item>
            </Col>
            <Col className="gutter-row xs-transform" sm={6} xs={24}>
              <label>{i18n.t("password")}</label>
              <Form.Item>
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: i18n.t("required_field") }],
                })(
                  <Input.Password
                    size="large"
                    type="password"
                    autoComplete="new-password"
                  />,
                )}
              </Form.Item>
            </Col>
            <Col className="gutter-row xs-transform" sm={2} xs={24}>
              <Button className="btn-primary" type="primary" htmlType="submit">
                <img className="btn-icon" src={Images.plus} alt="" />
              </Button>
            </Col>
          </Row>
        </Form>}
      </div>
  )
}

const WrappedAccountForm = Form.create({ name: 'account-form' })(AccountForm);

export default WrappedAccountForm
