import React, { useEffect } from 'react'
import { Col, Row, Button, Form, Select } from 'antd'
import Images from '../../Images'
import IconCustom from '../../Icon'
import i18n from '../../i18n'

import './FiltersPopupForm.css'

const { Option } = Select;

function RateLocalFiltersPopupForm(props) {

  const { form, initialFilter, ports, states, auctions, onReset, onSubmit } = props;

  const {
    getFieldDecorator,
    getFieldsValue,
    setFieldsValue,
    resetFields
  } = form;

  const portList = [],
        stateList = [],
        auctionList = [];
  if (ports){
    for (let i = 0; i < ports.length; i++) {
      portList.push(<Option key={ports[i]}>{ports[i]}</Option>);
    }
  }
  if (states){
    for (let i = 0; i < states.length; i++) {
      stateList.push(<Option key={states[i]}>{states[i]}</Option>);
    }
  }
  if (auctions){
    for (let i = 0; i < auctions.length; i++) {
      auctionList.push(<Option key={auctions[i]}>{auctions[i]}</Option>);
    }
  }

  useEffect(() => {
    let initialData = initialFilter.asMutable ? initialFilter.asMutable({ deep: true }) : { ...initialFilter };

    setFieldsValue(initialData);

    return () => {
    }
  }, [0])


  function handleSubmit(e) {
    e.preventDefault();

    let data = getFieldsValue();

    onSubmit(data);
  }

  function resetData() {
    resetFields();
    onReset();
  }

  return (
    <div className="filter-content">

      {<Form id="filters-popup-form" onSubmit={handleSubmit} disabled>

        <Row gutter={{xl: 16, md: 24, xs: 48}} className="flex-row">
          <Col className="gutter-row" xl={8} md={12} xs={24} >
            <Form.Item label={i18n.t("port")}>
              {getFieldDecorator('port')(
                <Select
                  size="large"
                  showArrow={true}
                  suffixIcon={<IconCustom type="select"/>}
                >
                  {portList}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col className="gutter-row" xl={8} md={12} xs={24} >
            <Form.Item label={i18n.t("state")}>
              {getFieldDecorator('state')(
                <Select
                  size="large"
                  showArrow={true}
                  suffixIcon={<IconCustom type="select"/>}
                >
                  {stateList}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col className="gutter-row" xl={8} md={12} xs={24} >
            <Form.Item label={i18n.t("auction")}>
              {getFieldDecorator('auction')(
                <Select
                  size="large"
                  showArrow={true}
                  suffixIcon={<IconCustom type="select"/>}
                >
                  {auctionList}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

      </Form>}

      <div className="dialog-footer">
        <Button className="btn-primary gray" type="primary" style={{ width: 'auto' }} onClick={resetData}>
          <img className="btn-icon icon-space" src={Images.cross} alt="" />
          {i18n.t("filter_discard")}
        </Button>
        <Button form="user-popup-form" htmlType="submit" onClick={handleSubmit} className="btn-primary blue" type="primary" style={{ width: 'auto' }}>
          <img className="btn-icon icon-space" src={Images.filters} alt="" />
          {i18n.t("filter_use")}
        </Button>
      </div>
    </div>
  )
}

const WrappedUserEditForm = Form.create({ name: 'filters-popup-form' })(RateLocalFiltersPopupForm);

export default WrappedUserEditForm
