import React, { useEffect} from 'react'
import { connect } from 'react-redux'
import {useHistory} from 'react-router-dom'
import { message } from 'antd'
import UserActions from '../../Redux/UserRedux'
import ChangePasswordPopupForm from '../../Components/Forms/ChangePasswordPopupForm'
import utils from '../../Utils/utils'

function ChangePasswordPopupPage (props) {

  const { result, match } = props;

  const {id} = match.params;

  let history = useHistory();
  function handleClose() {
    props.dataReset();
    let path = utils.cutUrlToPrevious(history.location.pathname);
    history.push(path);
  }

  function handleSubmit(data){
    if (data.old_password) {
      props.changeProfilePassword(data);
    } else {
      data.dealer = id;
      props.changeUserPassword(data);
    }
  }

  useEffect(()=>{
    props.dataReset();
    return () => {
      props.dataReset();
    }
  }, [0]);

  useEffect(()=>{
    if(result){
      message.success(result, 2);
      handleClose();
    }
  }, [result]);

  return (
      <div className="popup-dialog">
        <ChangePasswordPopupForm
          match={match}
          onClose={handleClose}
          onSubmit={handleSubmit}
          />
      </div>
  )
}

const mapStateToProps = (state) => {
  return {
    result: state.users.changePasswordResult,
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeProfilePassword: (data) => dispatch(UserActions.profileChangePasswordRequest(data)),
  changeUserPassword: (data) => dispatch(UserActions.userChangePasswordRequest(data)),
  dataReset: () => dispatch(UserActions.changePasswordReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPopupPage);
