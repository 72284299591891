import React from 'react';

const Download = (props) => (
  <svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
  <path d="M0.376952 17.5L0.376952 16.5625C0.376952 16.2531 0.630077 16 0.939452 16L2.81445 16C3.12383 16 3.37695 16.2531 3.37695 16.5625L3.37695 17.5C3.37695 18.3297 4.04727 19 4.87695 19L13.877 19C14.7066 19 15.377 18.3297 15.377 17.5L15.377 16.5625C15.377 16.2531 15.6301 16 15.9395 16L17.8145 16C18.1238 16 18.377 16.2531 18.377 16.5625L18.377 17.5C18.377 19.9844 16.3613 22 13.877 22L4.87695 22C2.39258 22 0.376952 19.9844 0.376952 17.5ZM9.79883 17.2969L17.6738 9.42187C18.377 8.71875 17.8848 7.5 16.877 7.5L12.377 7.5L12.377 1.125C12.377 0.501562 11.8754 -2.84192e-07 11.252 -3.11444e-07L6.75195 -5.08145e-07C6.12852 -5.35396e-07 5.62695 0.501562 5.62695 1.125L5.62695 7.5L1.12695 7.5C0.11914 7.5 -0.373047 8.71875 0.330078 9.42187L8.20508 17.2969C8.6457 17.7328 9.3582 17.7328 9.79883 17.2969Z" fill={props.color ? props.color : "#898793"}/>
  </g>
  <defs>
  
  </defs>
  </svg>

);

export default Download;
