import React from 'react';

const Title = (props) => (
  <svg width="17" height="24" viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity={props.opacity ? props.opacity : '0.4'}>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.32 1C7.32 0.447715 6.87228 0 6.32 0H2.4C1.07452 0 0 1.07452 0 2.4V21.6C0 22.9255 1.07452 24 2.4 24H14.4C15.7255 24 16.8 22.9255 16.8 21.6V10.48C16.8 9.92772 16.3523 9.48 15.8 9.48H10.8C8.87805 9.48 7.32 7.92195 7.32 6V1ZM5.4 13.32C4.80353 13.32 4.32 13.8035 4.32 14.4C4.32 14.9965 4.80353 15.48 5.4 15.48H8.4C8.99647 15.48 9.48 14.9965 9.48 14.4C9.48 13.8035 8.99647 13.32 8.4 13.32H5.4ZM5.4 17.52C4.80353 17.52 4.32 18.0035 4.32 18.6C4.32 19.1965 4.80353 19.68 5.4 19.68H11.4C11.9965 19.68 12.48 19.1965 12.48 18.6C12.48 18.0035 11.9965 17.52 11.4 17.52H5.4Z" fill="#262F56" />
      <path d="M14.5129 7.32C14.9583 7.32 15.1814 6.78143 14.8664 6.46645L10.3336 1.93355C10.0186 1.61857 9.48 1.84165 9.48 2.28711V6C9.48 6.72902 10.071 7.32 10.8 7.32H14.5129Z" fill={props.color ? props.color : "#262F56"} />
    </g>
  </svg>

);

export default Title;
