import React from 'react'
import { Row, Col, Button, Popconfirm } from 'antd'
import i18n from '../i18n'
import Images from '../Images'
import utils from '../Utils/utils'

function AutoSubdealer(props) {
  const { data, onNavigate, onDelete, editTemplate } = props;

  return (
    data ?
      <div className="auto-info-section">
        <Row gutter={[16, 14]} className="subdealer-control-panel">
          <Col className="gutter-row" lg={8} xs={24}>
            <Col className="gutter-row auto-info-title" lg={10} xs={12}>
              <div className="flex-block">
                <span>{i18n.t("subdealer")}</span>
                <span className="dots"></span>
              </div>
            </Col>
            <Col className="gutter-row auto-info-value" lg={14} xs={12}>
              {data.sub_dealer && data.sub_dealer.name}
            </Col>
          </Col>
          <Col className="gutter-row" lg={12} xs={24}>
            <Col className="gutter-row auto-info-title" lg={10} xs={12}>
              <div className="flex-block">
                <span>{i18n.t("fees_template")}</span>
                <span className="dots"></span>
              </div>
            </Col>
            <Col className="gutter-row auto-info-value" lg={14} xs={12}>
              {data.standard_fee && data.standard_fee.name}
            </Col>
          </Col>
          <Col className="gutter-row" lg={4} xs={24}>
            <div className="subdealer-btn-block">
              <Button className="btn-primary" type="primary" onClick={editTemplate} >
                <img className="btn-icon" src={Images.editWhite} alt="" />
              </Button>
              <div style={{ width: '8px' }} />
              <Popconfirm
                title={i18n.t("account_delete_confirm")}
                onConfirm={() => onDelete()}
                okText={i18n.t("yes")}
                cancelText={i18n.t("no")}
              >
                <Button className="btn-primary gray" type="primary">
                  <img className="btn-icon" src={Images.cancel} alt="" />
                </Button>
              </Popconfirm>
            </div>
          </Col>
        </Row>

        <Row gutter={[16, 14]}>
          <Col className="gutter-row" lg={8} xs={24}>
            <Col className="gutter-row auto-info-title" lg={10} xs={12}>
              <div className="flex-block">
                <span>{i18n.t("auto_sbor")}</span>
                <span className="dots"></span>
              </div>
            </Col>
            <Col className="gutter-row auto-info-value" lg={14} xs={12}>
              {data.c_price_sbor && `$${utils.formatValue(data.c_price_sbor)}`}
            </Col>
          </Col>
          <Col className="gutter-row" lg={8} xs={24}>
            <Col className="gutter-row auto-info-title" lg={10} xs={12}>
              <div className="flex-block">
                <span>{i18n.t("parking")}</span>
                <span className="dots"></span>
              </div>
            </Col>
            <Col className="gutter-row auto-info-value" lg={14} xs={12}>
              {data.c_parking && `$${utils.formatValue(data.c_parking)}`}
            </Col>
          </Col>
          <Col className="gutter-row" lg={8} xs={24}>
            <Col className="gutter-row auto-info-title" lg={10} xs={12}>
              <div className="flex-block">
                <span>{i18n.t("add_payment")}</span>
                <span className="dots"></span>
              </div>
            </Col>
            <Col className="gutter-row auto-info-value" lg={14} xs={12}>
              {data.c_add_payment && `$${utils.formatValue(data.c_add_payment)}`}
            </Col>
          </Col>
        </Row>
        <Row gutter={[16, 14]}>
          <Col className="gutter-row" lg={8} xs={24}>
            <Col className="gutter-row auto-info-title" lg={10} xs={12}>
              <div className="flex-block">
                <span>{i18n.t("local_transp")}</span>
                <span className="dots"></span>
              </div>
            </Col>
            <Col className="gutter-row auto-info-value" lg={14} xs={12}>
              {data.c_local_transp && `$${utils.formatValue(data.c_local_transp)}`}
            </Col>
          </Col>
          <Col className="gutter-row" lg={8} xs={24}>
            <Col className="gutter-row auto-info-title" lg={10} xs={12}>
              <div className="flex-block">
                <span>Sales_tax</span>
                <span className="dots"></span>
              </div>
            </Col>
            <Col className="gutter-row auto-info-value" lg={14} xs={12}>
              {data.c_fine_memo && `$${utils.formatValue(data.c_fine_memo)}`}
            </Col>
          </Col>
          <Col className="gutter-row" lg={8} xs={24}>
            <Col className="gutter-row auto-info-title" lg={10} xs={12}>
              <div className="flex-block">
                <span>{i18n.t("insurance")}</span>
                <span className="dots"></span>
              </div>
            </Col>
            <Col className="gutter-row auto-info-value" lg={14} xs={12}>
              {data.c_insurance && `$${utils.formatValue(data.c_insurance)}`}
            </Col>
          </Col>
        </Row>
        <Row gutter={[16, 14]}>
          <Col className="gutter-row" lg={8} xs={24}>
            <Col className="gutter-row auto-info-title" lg={10} xs={12}>
              <div className="flex-block">
                <span>{i18n.t("ocean_transp")}</span>
                <span className="dots"></span>
              </div>
            </Col>
            <Col className="gutter-row auto-info-value" lg={14} xs={12}>
              {data.c_ocean_transp && `$${utils.formatValue(data.c_ocean_transp)}`}
            </Col>
          </Col>
          <Col className="gutter-row" lg={8} xs={24}>
            <Col className="gutter-row auto-info-title" lg={10} xs={12}>
              <div className="flex-block">
                <span>{i18n.t("doc_tranfered")}</span>
                <span className="dots"></span>
              </div>
            </Col>
            <Col className="gutter-row auto-info-value" lg={14} xs={12}>
              {data.c_doc_tranfered && `$${utils.formatValue(data.c_doc_tranfered)}`}
            </Col>
          </Col>
          <Col className="gutter-row" lg={8} xs={24}>
            <Col className="gutter-row auto-info-title" lg={10} xs={12}>
              <div className="flex-block">
                <span>{i18n.t("office_fee")}</span>
                <span className="dots"></span>
              </div>
            </Col>
            <Col className="gutter-row auto-info-value" lg={14} xs={12}>
              {data.c_office_fee && `$${utils.formatValue(data.c_office_fee)}`}
            </Col>
          </Col>
        </Row>
        <Row gutter={[16, 14]}>
          <Col className="gutter-row" lg={8} xs={24}>
            <Col className="gutter-row auto-info-title" lg={10} xs={12}>
              <div className="flex-block">
                <span>{i18n.t("storage")}</span>
                <span className="dots"></span>
              </div>
            </Col>
            <Col className="gutter-row auto-info-value" lg={14} xs={12}>
              {data.c_storage && `$${utils.formatValue(data.c_storage)}`}
            </Col>
          </Col>
        </Row>
      </div>
      :
      <>
        <div className="no-data-block">{i18n.t("unassign_car_subdealer")}</div>
        <div className="btn-assign">
          <Button className="btn-primary" type="primary" style={{ width: 'auto' }} onClick={() => onNavigate && onNavigate('/assign-subdealer')}>
            <img className="btn-icon icon-space" src={Images.users} alt="" />
            {i18n.t("subdealer_assign")}
          </Button>
        </div>
      </>
  )
}

export default AutoSubdealer
