import React from 'react';

const PaginationPrev = (props) => (
  <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 0.780762L1 7.61003L8 14.4393" stroke="#262F56" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>


);

export default PaginationPrev;
