import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'antd'
import DashboardActions from '../Redux/DashboardRedux'
import NavActions from '../Redux/NavRedux'
import Images from '../Images'
import i18n from '../i18n'
import utils from '../Utils/utils'

import './DashboardPage.css'

function DashboardPage(props) {
  const { data, isDesktop, isAdmin, role } = props;

  let history = useHistory();

  function handleNavigate(path) {
    isAdmin ?
      history.push('/home/cars')
      :
      history.push('/home/dashboard')
  }

  useEffect(() => {
    props.getInfo();

    return function cleanup() {
      props.dashboardReset();
    }
  }, [0]);

  function formatValue(val, addCurrency) {
    if (!val) {
      return (addCurrency ? '0$' : '0');
    }
    return utils.formatValue(val) + (addCurrency ? '$' : '');
  }

  function handleOpenMobileMenu(e) {
    props.navSetProp('isShowMobileMenu', true)
  }

  function setClass(val) {
    if (!val) {
      return 'data-value'
    } else if (val > 0) {
      return 'data-value green';
    } else {
      return 'data-value red'
    }
  }

  return (
    <div className="content-page dashboard" style={{ backgroundImage: 'url(' + Images.dashboard + ')' }}>
      {
        !isDesktop &&
        <>
          <img className="dashboard-logo" src={Images.logo} onClick={handleNavigate} alt="" />
          <div className="menu-btn">
            <div className="mobile-btn-wraper" onClick={handleOpenMobileMenu}>
              <img className="btn-icon" src={Images.menu} alt="" />
            </div>
          </div>
        </>
      }
      {
        data &&
        <>
          <div className="top-section">
            <div className="header-section">
              <span>{i18n.t("car_bought")}</span>
              <div className="data-section">
                <div className="total-cars">{data.cars && formatValue(data.cars.total_cars)}</div>
                <div className="total-per-month"><b>{data.cars && formatValue(data.cars.total_this_month)}</b> / {i18n.t("month")}</div>
              </div>
            </div>
            <div className="content-section">
              <div className="card-section">
                <div className="card-logo">
                  <img className="btn-icon" src={isDesktop ? Images.iaai : Images.iaaiSm} alt="" />
                </div>
                <div className="total-count">{data.auctions && formatValue(data.auctions.total_IAAI)}</div>
                <div className="total-per-days"><b>{data.auctions && formatValue(data.auctions.last_3_days_IAAI)}</b> / {i18n.t("3_days")}</div>
              </div>
              <div className="card-section">
                <div className="card-logo">
                  <img className="btn-icon" src={isDesktop ? Images.manheim : Images.manheimSm} alt="" />
                </div>
                <div className="total-count">{data.auctions && formatValue(data.auctions.total_Manheim)}</div>
                <div className="total-per-days"><b>{data.auctions && formatValue(data.auctions.last_3_days_Manheim)}</b> / {i18n.t("3_days")}</div>
              </div>
              <div className="card-section">
                <div className="card-logo">
                  <img className="btn-icon" src={isDesktop ? Images.copart : Images.copartSm} alt="" />
                </div>
                <div className="total-count">{data.auctions && formatValue(data.auctions.total_Copart)}</div>
                <div className="total-per-days"><b>{data.auctions && formatValue(data.auctions.last_3_days_Copart)}</b> / {i18n.t("3_days")}</div>
              </div>
            </div>
          </div>

          <div className="middle-section">
            <div className="header-section">{i18n.t("finances")}</div>
            <div className="block-content">
              <div className="data-section">
                <div className={setClass(data.finances?.total_balance)}>{data.finances && formatValue(data.finances.total_balance, true)}</div>
                <div className="data-text">{i18n.t("total_balance")}</div>
              </div>
              <div className="data-section">
                <div className="data-value">{data.finances && formatValue(data.finances.del_cars_num)}</div>
                <div className="data-text">{i18n.t("cars_total_on_the_way")}</div>
              </div>
              <div className="data-section">
                <div className="data-value">{data.finances && formatValue(data.finances.logistics_price, true)}</div>
                <div className="data-text">{i18n.t("postponed_logistics_payments")}</div>
              </div>
              <div className="data-section">
                <div className={setClass(data.finances?.actual_balance)}>{data.finances && formatValue(data.finances.actual_balance, true)}</div>
                <div className="data-text">{i18n.t("actual_balance")}</div>
              </div>
            </div>
          </div>

          <div className="bottom-section">
            {/* <div className="block-section">
              <div className="header-section">{i18n.t("expeditor")}</div>
              <div className="block-content">
                <div className="data-section">
                  <div className="data-value">{data.expeditors && formatValue(data.expeditors.exped_total, true)}</div>
                  <div className="data-text">{i18n.t("invoice_amount")}</div>
                </div>
                <div className="data-section">
                  <div className="data-value">{data.expeditors && formatValue(data.expeditors.exped_paid, true)}</div>
                  <div className="data-text">{i18n.t("invoice_paid")}</div>
                </div>
                <div className="data-section">
                  <div className={setClass(data.expeditors?.exped_balance)}>{data.expeditors && formatValue(data.expeditors.exped_balance, true)}</div>
                  <div className="data-text">{i18n.t("balance")}</div>
                </div>
              </div>
            </div> */}
            <div className="block-section">
              <div className="header-section">{i18n.t("payments")}</div>
              <div className="block-content sm">
                <div className="data-section">
                  <div className="data-value">{data.payments && formatValue(data.payments.invoices_cargoloop, true)}</div>
                  <div className="data-text">{i18n.t("invoice_amount_cargoloop")}</div>
                </div>
                <div className="data-section">
                  <div className="data-value">{data.payments && formatValue(data.payments.invoices_auctions, true)}</div>
                  <div className="data-text">{i18n.t("invoice_amount_auction")}</div>
                </div>
                <div className="data-section">
                  <div className="data-value">{data.payments && formatValue(data.payments.cargoloop_payments, true)}</div>
                  <div className="data-text">{i18n.t("payment_amount_cargoloop")}</div>
                </div>
                <div className="data-section">
                  <div className="data-value">{data.payments && formatValue(data.payments.auction_payment, true)}</div>
                  <div className="data-text">{i18n.t("payment_amount_auction")}</div>
                </div>
              </div>
            </div>
            {
              role === 0 &&
              <div className="buttons-block">
                <Button className="btn-primary violet" type="primary" href="viber://chat?number=+380672389914">
                  <img className="btn-icon" src={Images.viberBg} alt="" />
                </Button>
                <div style={{ height: '24px' }} />
                <Button className="btn-primary blue" type="primary" href="tg://resolve?domain=daniilcolumb">
                  <img className="btn-icon" src={Images.telegramBg} alt="" />
                </Button>
              </div>
            }
          </div>
        </>
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    data: state.dashboard.result,
    isDesktop: state.nav.isDesktop,
    isAdmin: state.auth.isAdmin,
    role: state.auth.role,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getInfo: () => dispatch(DashboardActions.dashboardRequest()),
  dashboardReset: () => dispatch(DashboardActions.dashboardReset()),
  navSetProp: (key, value) => dispatch(NavActions.navSetProp(key, value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage)
