import { takeLatest, all, fork } from 'redux-saga/effects'
import AuthAPI from '../Services/AuthApi'
import API from '../Services/Api'
//import UsersAPI from '../Services/UsersApi'

/* ------------- Types ------------- */

import { StartupTypes } from '../Redux/StartupRedux'

import { AuthTypes } from '../Redux/AuthRedux'

import { CarsTypes } from '../Redux/CarsRedux'

import { InvoicesTypes } from '../Redux/InvoicesRedux'

import { UserTypes } from '../Redux/UserRedux'

import { LangTypes } from '../Redux/LangRedux'

import { PaymentsTypes } from '../Redux/PaymentsRedux'

import { DealersTypes } from '../Redux/DealersRedux'

import { CalcTypes } from '../Redux/CalcRedux'

import { TitlesTypes } from '../Redux/TitlesRedux'

import { DashboardTypes } from '../Redux/DashboardRedux'

import { BrokerTypes } from '../Redux/BrokerRedux'

import {RatesTypes } from '../Redux/RatesRedux'

import {FeesTypes } from '../Redux/FeesRedux'

/* ------------- Sagas ------------- */

import { startup } from './StartupSagas'

import {
  signIn
} from './AuthSagas'

import {
  getCars,
  sendCarFile,
  getCarPorts,
  getCarInfo,
  updateCarInfo,
  getCarImages,
  getCarPhotoFile,
  getCarsFile,
  getCarBySearch,
  getCarFinancesInfo,
  getCarChoices
} from './CarsSagas'

import {
  getInvoices,
  saveInvoice,
  deleteInvoice,
  getInvoiceChoices
} from './InvoicesSagas'

import {
  getUsers,
  getUser,
  saveUser,
  deleteUser,
  getSubdealers,
  getAccounts,
  saveAccount,
  deleteAccount,
  changeProfilePassword,
  changeUserPassword
} from './UserSagas'

import {
  changeLang
} from './LangSagas'

import {
  getPayments,
  sendPaymentFile
} from './PaymentsSagas'

import {
  getUserColumbs
} from './DealersSagas'

import {
  getCalcEngines,
  getCalcAuctions,
  getCalcDelivery,
  calcDelivery,
  calcComission,
  calcCustom
} from './CalcSagas'

import {
  getTitles,
  getTitleColors,
  getTitleStates,
  editTitleComment
} from './TitlesSagas'

import {
  getDashboardInfo
} from './DashboardSagas'

import {
  getBrokerData,
  sendBrokerFile
} from './BrokerSagas'

import {
  getLocalRates,
  getOceanRates,
  getLocalRateChoices,
  getOceanRateChoices
} from './RatesSagas'

import {
  getFees,
  deleteFee,
  saveFee,
  getFeeNames,
  getCustomFee,
  saveCustomFee,
  deleteCustomFee
} from './FeesSagas'

//import { signIn, resetPassword, logout } from './AuthSagas'


/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const authApi = AuthAPI;
const api = API;
//const usersApi = UsersAPI;

/* ------------- Connect Types To Sagas ------------- */

export default function * root () {
  yield all([
    // some sagas only receive an action
    takeLatest(StartupTypes.STARTUP, startup),
    takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn, authApi),

    takeLatest(CarsTypes.CARS_REQUEST, getCars, api, authApi),
    takeLatest(CarsTypes.CAR_SEND_FILE_REQUEST, sendCarFile, api, authApi),
    takeLatest(CarsTypes.CAR_PORTS_REQUEST, getCarPorts, api, authApi),
    takeLatest(CarsTypes.CAR_INFO_REQUEST, getCarInfo, api, authApi),
    takeLatest(CarsTypes.CAR_INFO_SAVE_REQUEST, updateCarInfo, api, authApi),
    takeLatest(CarsTypes.CAR_IMAGES_REQUEST, getCarImages, api, authApi),
    takeLatest(CarsTypes.CAR_PHOTO_FILE_REQUEST, getCarPhotoFile, api, authApi),
    takeLatest(CarsTypes.CARS_FILE_REQUEST, getCarsFile, api, authApi),
    takeLatest(CarsTypes.CAR_SEARCH_REQUEST, getCarBySearch, api, authApi),
    takeLatest(CarsTypes.CAR_FINANCES_REQUEST, getCarFinancesInfo, api, authApi),
    takeLatest(CarsTypes.CAR_CHOICES_REQUEST, getCarChoices, api, authApi),

    takeLatest(InvoicesTypes.INVOICES_REQUEST, getInvoices, api, authApi),
    takeLatest(InvoicesTypes.INVOICE_SAVE_REQUEST, saveInvoice, api, authApi),
    takeLatest(InvoicesTypes.INVOICE_DELETE_REQUEST, deleteInvoice, api, authApi),
    takeLatest(InvoicesTypes.CHOICES_REQUEST, getInvoiceChoices, api, authApi),

    takeLatest(UserTypes.USERS_REQUEST, getUsers, api, authApi),
    takeLatest(UserTypes.USER_REQUEST, getUser, api, authApi),
    takeLatest(UserTypes.USER_SAVE_REQUEST, saveUser, api, authApi),
    takeLatest(UserTypes.USER_DELETE_REQUEST, deleteUser, api, authApi),
    takeLatest(UserTypes.SUBDEALERS_REQUEST, getSubdealers, api, authApi),
    takeLatest(UserTypes.ACCOUNTS_REQUEST, getAccounts, api, authApi),
    takeLatest(UserTypes.ACCOUNT_SAVE_REQUEST, saveAccount, api, authApi),
    takeLatest(UserTypes.ACCOUNT_DELETE_REQUEST, deleteAccount, api, authApi),
    takeLatest(UserTypes.PROFILE_CHANGE_PASSWORD_REQUEST, changeProfilePassword, api, authApi),
    takeLatest(UserTypes.USER_CHANGE_PASSWORD_REQUEST, changeUserPassword, api, authApi),

    takeLatest(LangTypes.LANG_CHANGE, changeLang, api),

    takeLatest(PaymentsTypes.PAYMENTS_REQUEST, getPayments, api, authApi),
    takeLatest(PaymentsTypes.PAYMENT_SEND_FILE_REQUEST, sendPaymentFile, api, authApi),

    takeLatest(DealersTypes.USER_COLUMBS_REQUEST, getUserColumbs, api, authApi),

    takeLatest(CalcTypes.ENGINE_REQUEST, getCalcEngines, api, authApi),
    takeLatest(CalcTypes.AUCTION_REQUEST, getCalcAuctions, api, authApi),
    takeLatest(CalcTypes.DELIVERY_REQUEST, getCalcDelivery, api, authApi),
    takeLatest(CalcTypes.DELIVERY_CALC_REQUEST, calcDelivery, api, authApi),
    takeLatest(CalcTypes.COMISSION_CALC_REQUEST, calcComission, api, authApi),
    takeLatest(CalcTypes.CUSTOM_CALC_REQUEST, calcCustom, api, authApi),

    takeLatest(TitlesTypes.TITLES_REQUEST, getTitles, api, authApi),
    takeLatest(TitlesTypes.TITLE_COLORS_REQUEST, getTitleColors, api, authApi),
    takeLatest(TitlesTypes.TITLE_STATES_REQUEST, getTitleStates, api, authApi),
    takeLatest(TitlesTypes.TITLE_COMMENT_CHANGE_REQUEST, editTitleComment, api, authApi),

    takeLatest(DashboardTypes.DASHBOARD_REQUEST, getDashboardInfo, api, authApi),

    takeLatest(BrokerTypes.BROKER_REQUEST, getBrokerData, api, authApi),
    takeLatest(BrokerTypes.BROKER_SEND_FILE_REQUEST, sendBrokerFile, api, authApi),

    takeLatest(RatesTypes.RATES_LOCAL_REQUEST, getLocalRates, api, authApi),
    takeLatest(RatesTypes.RATES_OCEAN_REQUEST, getOceanRates, api, authApi),
    takeLatest(RatesTypes.RATE_LOCAL_CHOICES_REQUEST, getLocalRateChoices, api, authApi),
    takeLatest(RatesTypes.RATE_OCEAN_CHOICES_REQUEST, getOceanRateChoices, api, authApi),

    takeLatest(FeesTypes.FEES_REQUEST, getFees, api, authApi),
    takeLatest(FeesTypes.FEE_DELETE_REQUEST, deleteFee, api, authApi),
    takeLatest(FeesTypes.FEE_SAVE_REQUEST, saveFee, api, authApi),
    takeLatest(FeesTypes.FEE_NAMES_REQUEST, getFeeNames, api, authApi),
    takeLatest(FeesTypes.FEE_CUSTOM_REQUEST, getCustomFee, api, authApi),
    takeLatest(FeesTypes.FEE_CUSTOM_DELETE_REQUEST, deleteCustomFee, api, authApi),
    takeLatest(FeesTypes.FEE_CUSTOM_SAVE_REQUEST, saveCustomFee, api, authApi),
  ])
}
