import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import BrokerActions from '../Redux/BrokerRedux'
import BrokerTable from '../Components/Tables/BrokerTable'

function BrokerPage(props) {
  const { broker, settings } = props;

  const [start, setStart] = useState(false);

  useEffect(() => {
    props.resetFilter();
    props.resetSearch();
    props.getBrokerData(1, settings.page_size);
    setStart(true);

    return function cleanup() {
      props.brokerReset();
      setStart(false);
    }
  }, [0]);

  useEffect(() => {
    if (start) {
      props.getBrokerData(1, settings.page_size, broker.sort_field, broker.sort_order, broker.search, broker.filter);
    }
  }, [settings.page_size])

  function handleChangePage(page, page_size, sort_field, sort_order){
    props.getBrokerData(page, page_size, sort_field, sort_order, broker.search, broker.filter);
  }

  return (
    <div className="content-page">
      <BrokerTable
        data={broker.list}
        page={broker.page}
        fetching={broker.fetching}
        pageSize={settings.page_size}
        scale={settings.tableScale}
        isHide={settings.isHide}
        onChangePage={handleChangePage}
      />
    </div>

  )
}

const mapStateToProps = (state) => {
  return {
    broker: state.broker,
    settings: state.settings
  }
}

const mapDispatchToProps = (dispatch) => ({
  getBrokerData: (page, page_size, sort_field, sort_order, search, filter) => dispatch(BrokerActions.brokerRequest(page, page_size, sort_field, sort_order, search, filter)),
  brokerReset: () => dispatch(BrokerActions.brokerReset()),
  resetFilter: () => dispatch(BrokerActions.brokerFilterReset()),
  resetSearch: () => dispatch(BrokerActions.brokerSearchReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(BrokerPage)
