import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  invoicesRequest: ['page', 'page_size', 'sort_field', 'sort_order', 'search'],
  invoicesSuccess: ['list'],

  invoiceSaveRequest: ['data'],
  invoiceSaveSuccess: ['saveResult'],
  invoiceSaveReset: null,

  invoiceDeleteRequest: ['id'],
  invoiceDeleteSuccess: ['deleteResult'],
  invoiceDeleteReset: null,

  invoiceSetFocusRow: ['focusRow'],

  invoicesReset: null,
  invoiceSearchReset: null,

  choicesRequest: null,
  choicesSuccess: ['data'],
  choicesReset: null,

  invoiceFetchingReset: null
})

export const InvoicesTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: null,
  fetching: false,

  saveResult: null,
  saveFetching: false,

  deleteResult: null,
  deleteFetching: false,

  focusRow: null,

  page: 1,
  sort_field: '',
  sort_order: 1,
  search: '',

  auctions: null,
  banks: null,
  companies: null

})

/* ------------- Selectors ------------- */
export const InvoicesSelectors = {
  getState: (state) => state.invoices
}

/* ------------- Reducers ------------- */

export const invoicesRequest = (state: Object, {page, page_size, sort_field, sort_order, search}: Object) => {
  const newState = {
    fetching: true,
  };
  page && (newState.page = page);
  page_size && (newState.page_size = page_size);
  sort_field && (newState.sort_field = sort_field);
  sort_order && (newState.sort_order = sort_order);
  search != null && (newState.search = search);
  return state.merge(newState)
}

export const invoicesSuccess = (state: Object, { list }: Object) => {
  return state.merge({ fetching: false, list })
}

export const invoiceSaveRequest = (state: Object, { data }: Object) => {
  return state.merge({ saveFetching: true })
}

export const invoiceSaveSuccess = (state: Object, { saveResult }: Object) => {
  return state.merge({ saveFetching: false, saveResult })
}

export const invoiceSaveReset = (state: Object) =>{
  return state.merge({ saveFetching: false,  saveResult:null })
}

export const invoiceDeleteRequest = (state: Object, { id }: Object) => {
  return state.merge({ deleteFetching: true })
}

export const invoiceDeleteSuccess = (state: Object, { deleteResult }: Object) => {
  return state.merge({ deleteFetching: false, deleteResult })
}

export const invoiceDeleteReset = (state: Object) =>{
  return state.merge({ deleteFetching: false,  deleteResult:null })
}

export const invoiceSetFocusRow = (state: Object, {focusRow }) => {
  return state.merge({ focusRow })
}

export const invoicesReset = (state: Object) =>{
  return INITIAL_STATE
}

export const invoiceSearchReset = (state: Object) => {
  return state.merge({ search: '' })
}

export const choicesRequest = (state: Object) => {
  return state
}

export const choicesSuccess = (state: Object, { data }: Object) => {
  return state.merge({ auctions: data.auctions, banks: data.banks, companies: data.companies })
}

export const choicesReset = (state: Object) => {
  return state.merge({ auctions: null, banks: null, companies: null })
}

export const invoiceFetchingReset = (state: Object) => {
  return state.merge({ fetching:false, saveFetching: false, deleteFetching: false })
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.INVOICES_REQUEST]: invoicesRequest,
  [Types.INVOICES_SUCCESS]: invoicesSuccess,

  [Types.INVOICE_SAVE_REQUEST]: invoiceSaveRequest,
  [Types.INVOICE_SAVE_SUCCESS]: invoiceSaveSuccess,
  [Types.INVOICE_SAVE_RESET]: invoiceSaveReset,

  [Types.INVOICE_DELETE_REQUEST]: invoiceDeleteRequest,
  [Types.INVOICE_DELETE_SUCCESS]: invoiceDeleteSuccess,
  [Types.INVOICE_DELETE_RESET]: invoiceDeleteReset,

  [Types.INVOICE_SET_FOCUS_ROW]: invoiceSetFocusRow,

  [Types.INVOICES_RESET]: invoicesReset,
  [Types.INVOICE_SEARCH_RESET]: invoiceSearchReset,

  [Types.CHOICES_REQUEST]: choicesRequest,
  [Types.CHOICES_SUCCESS]: choicesSuccess,
  [Types.CHOICES_RESET]: choicesReset,

  [Types.INVOICE_FETCHING_RESET]: invoiceFetchingReset,
})
