import React from 'react'
import {
  Router,
  Switch,
  Route
} from "react-router-dom";
import { connect } from 'react-redux'
import { history } from './Redux'
import { Layout } from 'antd'

import AuthActions from './Redux/AuthRedux'
import StartupPage from './Containers/StartupPage'
import LoginPage from './Containers/LoginPage'
import HomePage from './Containers/HomePage'

function Routes(props) {

  let isStartup = true;
  if (props.startup.success === false) {
    return (
      <Layout>
        <Route path="*" component={StartupPage} />
      </Layout>
    )
  } else {
    isStartup = true;
  }

  return (
    <Router history={history}>
      <Layout className="main-layout">
        <Switch>
          <Route exact path="/" component={StartupPage}/>
          <Route path="/login" component={LoginPage}/>
          {isStartup ? <Route path="/home/*" component={HomePage} /> : null }
        </Switch>
      </Layout>
    </Router>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    startup: state.startup
  }
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(AuthActions.logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
