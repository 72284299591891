import React, { useState, useEffect } from 'react'
import { Layout, Button, Input, Upload, message, Tooltip } from 'antd'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import CarsActions from '../../Redux/CarsRedux'
import NavActions from '../../Redux/NavRedux'
import i18n from '../../i18n'
import Images from '../../Images'
import utils from '../../Utils/utils'

const { Header, Footer } = Layout;
const { Search } = Input;

function CarsHeader(props) {
  const {cars, settings, isAdmin, role, uploadFile, nav} = props;

  const [fileUid, setFileUid] = useState(null);

  let history = useHistory();
  function handleNavigate(path) {
    history.push(history.location.pathname + path);
  }

  function logoNavigate(path) {
    if (!isAdmin) {
      history.push(path);
    }
  }

  function handleSearch(val) {
    if (cars.search === val) {
      return;
    }
    props.getCars(1, settings.page_size, null, null, val, cars.filter);
  }

  function onChange({ file, fileList }) {
    if (fileUid && fileUid === file.uid) {
      return;
    }
    setFileUid(file.uid);

    let formData = new FormData();

    if (fileList && fileList.length) {
      formData.set('file', fileList[0].originFileObj, fileList[0].originFileObj.name);
    }
    props.sendUploadFile(formData);
  }

  function handleOpenMobileMenu(e) {
    props.navSetProp('isShowMobileMenu', true)
  }

  function handleExport() {
    props.exportFile(cars.sort_field, cars.sort_order, cars.filter);
  }

  useEffect(() => {
    if (nav.isDesktop) {
      props.getCarFinancesInfo();
    }
  }, [0]);

  useEffect(() => {
    if (uploadFile) {
      message.success(i18n.t("successful_data_upload"), 3);
      props.resetUploadData();
      props.getCars(cars.page, settings.page_size, cars.sort_field, cars.sort_order, cars.search, cars.filter);
    }
  }, [uploadFile])

  return (
    <Header>
      {
        nav.isDesktop ?
          <>
            <div className="header-title-container">{i18n.t("auto")}</div>
            <div className="header-buttons-container auto">
              {
                role === 0 &&
                  <div className="amount-block">
                    {
                      cars?.carFinances?.total_balance &&
                        <Tooltip
                        placement="bottom"
                        title={i18n.t("total_balance")}>
                          <div className="flex-center">
                            <img className="btn-icon" src={Images.chartLine} alt="" />
                            <span>{`${utils.formatValue(cars?.carFinances?.total_balance)}$`}</span>
                          </div>
                        </Tooltip>
                    }
                    {
                      cars?.carFinances?.del_cars_num &&
                        <Tooltip
                        placement="bottom"
                        title={i18n.t("cars_total_on_the_way")}>
                          <div className="flex-center">
                            <img className="btn-icon" src={Images.chartLine} alt="" />
                            <span>{`${cars?.carFinances?.del_cars_num}`}</span>
                          </div>
                        </Tooltip>
                    }
                    {
                      cars?.carFinances?.logistics_price &&
                        <Tooltip
                        placement="bottom"
                        title={i18n.t("postponed_logistics_payments")}>
                          <div className="flex-center">
                            <img className="btn-icon" src={Images.chartLine} alt="" />
                            <span>{`${utils.formatValue(cars?.carFinances?.logistics_price)}$`}</span>
                          </div>
                        </Tooltip>
                    }
                    {
                      cars?.carFinances?.actual_balance &&
                        <Tooltip
                        placement="bottom"
                        title={i18n.t("actual_balance")}>
                          <div className="flex-center">
                            <img className="btn-icon" src={Images.chartLine} alt="" />
                            <span>{`${utils.formatValue(cars?.carFinances?.actual_balance)}$`}</span>
                          </div>
                        </Tooltip>
                    }
                  </div>
              }
              <Search
                placeholder={i18n.t("search")}
                allowClear
                className="content-search"
                onSearch={handleSearch}
              />
            {
              isAdmin &&
              <>
                <Upload accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" method="get" onChange={onChange}>
                  <Button className="btn-primary" type="primary" style={{ width: 'auto' }}>
                    <img className="btn-icon icon-space" src={Images.document} alt="" />
                    {i18n.t("upload_deals")}
                  </Button>
                </Upload>
                <div style={{ width: '8px' }} />
              </>
            }
            <Button className="btn-primary" type="primary" style={{ width: 'auto' }} onClick={handleExport}>
              <img className="btn-icon icon-space" src={Images.exportAuto} alt="" />
              {i18n.t("export")}
            </Button>
            <div style={{ width: '8px' }} />
            <Button className="btn-primary" type="primary blue" style={{ width: 'auto' }}onClick={() => handleNavigate('/settings')}>
              <img className="btn-icon" src={Images.settings} alt="" />
            </Button>
            <div style={{ width: '8px' }} />
            <Button className="btn-primary" type="primary blue" style={{ width: 'auto' }} onClick={() => handleNavigate('/filters')}>
              <img className="btn-icon" src={Images.filters} alt="" />
            </Button>
          </div>
        </>
      :
        <div className="menu-btn">
          <img className="btn-icon" src={Images.logo} alt="" onClick={() => logoNavigate('/home/dashboard')}/>
          <div className="header-title-mobile">{i18n.t("auto")}</div>
          <div className="mobile-btn-wraper" onClick={handleOpenMobileMenu}>
            <img className="btn-icon" src={Images.menu} alt="" />
          </div>
        </div>
      }
    </Header>
  )
}

function CarsFooterComp(props) {
  const {cars, settings, isAdmin, uploadFile} = props;

  const [fileUid, setFileUid] = useState(null);
  const [showSearch, setShowSearch] = useState(false);

  let history = useHistory();
  function handleNavigate(path) {
    history.push(history.location.pathname + path);
  }

  function handleSearch(val) {
    if (cars.search === val) {
      return;
    }
    props.getCars(1, settings.page_size, null, null, val, cars.filter);
  }

  function onChange({ file, fileList }) {
    if (fileUid && fileUid === file.uid) {
      return;
    }
    setFileUid(file.uid);

    let formData = new FormData();

    if (fileList && fileList.length) {
      formData.set('file', fileList[0].originFileObj, fileList[0].originFileObj.name);
    }
    props.sendUploadFile(formData);
  }

  function handleExport() {
    props.exportFile(cars.sort_field, cars.sort_order, cars.filter);
  }

  useEffect(() => {
    if (uploadFile) {
      message.success(i18n.t("successful_data_upload"), 3);
      props.resetUploadData();
      props.getCars(cars.page, settings.page_size, cars.sort_field, cars.sort_order, cars.search, cars.filter);
    }
  }, [uploadFile])

  return (
    <Footer>
      <div className="footer-buttons-container">
        <div className="mobile-btn-wraper" onClick={() => setShowSearch(!showSearch)}>
          <img className="btn-icon" src={Images.search} alt="" />
        </div>
        {
          showSearch &&
            <div className="mobile-search">
              <Search
                placeholder={i18n.t("search")}
                allowClear
                className="content-search"
                onSearch={handleSearch}
              />
            </div>
        }
        {
          isAdmin &&
          <>
            <Upload accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" method="get" onChange={onChange}>
              <Button className="btn-primary" type="primary" style={{ width: '40px' }}>
                <img className="btn-icon" src={Images.download} alt="" />
              </Button>
            </Upload>
            <div style={{ width: '8px' }} />
          </>
        }
        <Button className="btn-primary" type="primary blue" style={{ width: '40px' }}onClick={() => handleNavigate('/settings')}>
          <img className="btn-icon" src={Images.settings} alt="" />
        </Button>
        <div style={{ width: '8px' }} />
        <Button className="btn-primary" type="primary blue" style={{ width: '40px' }} onClick={() => handleNavigate('/filters')}>
          <img className="btn-icon" src={Images.filters} alt="" />
        </Button>
        <div style={{ width: '8px' }} />
        <Button className="btn-primary" type="primary" style={{ width: '40px' }} onClick={handleExport}>
          <img className="btn-icon" src={Images.exportAuto} alt="" />
        </Button>
      </div>
    </Footer>
  )
}

const mapStateToProps = (state) => {
  return {
    cars: state.cars,
    uploadFile: state.cars.uploadFile,
    settings: state.settings,
    isAdmin: state.auth.isAdmin,
    role: state.auth.role,
    nav: state.nav,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getCars: (page, page_size, sort_field, sort_order, search, filter) => dispatch(CarsActions.carsRequest(page, page_size, sort_field, sort_order, search, filter)),
  sendUploadFile: (data) => dispatch(CarsActions.carSendFileRequest(data)),
  resetUploadData: () => dispatch(CarsActions.carSendFileReset()),
  exportFile: (sort_field, sort_order, filter) => dispatch(CarsActions.carsFileRequest(sort_field, sort_order, filter)),
  navSetProp: (key, value) => dispatch(NavActions.navSetProp(key, value)),

  getCarFinancesInfo: () => dispatch(CarsActions.carFinancesRequest()),
})

export const CarsFooter = connect(mapStateToProps, mapDispatchToProps)(CarsFooterComp);
export default connect(mapStateToProps, mapDispatchToProps)(CarsHeader)
