import React from 'react'
import Images from '../Images'

export const titleWithSortFilters = (title, sort, filter, stateData, key) => {

  let sortImage = Images.tableSortDescend;
  let textColor = 'rgba(57,56,64,1)';
  if(stateData.sortColumn && stateData.sortColumn.dataIndex === key){
    if(stateData.sortOrder==='descend'){
      sortImage = Images.tableSortDescendActive;
      textColor = '#689DD2'
    }else if(stateData.sortOrder==='ascend'){
      sortImage = Images.tableSortAscendActive;
      textColor = '#689DD2'
    }else{
      sortImage = Images.tableSortDescend;
    }
  }else{
    sortImage = Images.tableSortDescend;
  }

  return(
    <div className="table-column-title">
    <span style={{color:textColor}}>{title}</span>
    {sort ? <img src={sortImage} alt="" /> : null}
    {filter ? <img src={Images.tableFilterIcon} alt="" /> : null}
    </div>
  )
}
